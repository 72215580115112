import React, { useState, useEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Tooltip,
  Popconfirm,
  Tree,
  Button,
  Alert,
  Input,
  Row,
  Col,
  Typography,
  Skeleton,
  Card,
  Drawer,
  message,
} from 'antd';
import {
  PlusCircleOutlined,
  HighlightOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { axiosClient } from '../../apiClient';
import { useNavigate } from 'react-router-dom';

const { Paragraph } = Typography;

export default function SellerListCategory() {
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [parentId, setParentId] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [renaming, setRenaming] = useState(false);

  const renameCategory = (item, newTitle) => {
    setRenaming(item.id);
    axiosClient
      .post('/products/categories/rename', { id: item.id, newTitle }, { withCredentials: true })
      .then(() => {
        getTreeData();
      });
  };

  const getTreeData = () => {
    axiosClient
      .get('/products/categories/list', { withCredentials: true })
      .then((response) => {
        setTreeData(response.data);
        setLoading(false);
        setRenaming(false);
      });
  };

  const deleteNode = (id) => {
    axiosClient
      .post('/products/categories/delete', { id }, { withCredentials: true })
      .then(() => {
        getTreeData();
      });
  };

  useEffect(() => {
    getTreeData();
  }, []);

  const createCategory = () => {
    setLoading(true);
    axiosClient
      .post('/products/categories/create', { categoryName: inputValue, parentId }, { withCredentials: true })
      .then(() => {
        setInputValue('');
        getTreeData();
        setDrawerVisible(false);
      });
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  const onExpand = (keys) => {
    setExpandedKeys(keys);
    setAutoExpandParent(false);
  };

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };

    const data = [...treeData];
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        item.children.push(dragObj);
      });
    } else {
      let ar;
      let i;
      loop(data, dropKey, (item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setTreeData(data);

    const updatedCategories = [];
    const buildUpdatePayload = (nodes, parentId = null) => {
      nodes.forEach((node, index) => {
        updatedCategories.push({
          id: node.key,
          parent_id: parentId,
          position: index + 1,
        });
        if (node.children) {
          buildUpdatePayload(node.children, node.key);
        }
      });
    };
    buildUpdatePayload(data);

    axiosClient
      .post('/products/categories/ordenize', { categories: updatedCategories }, { withCredentials: true })
      .then(() => {
        message.success('Categorias atualizadas com sucesso');
      })
      .catch(() => {
        message.error('Falha ao atualizar categorias.');
      });
  };

  return (
    <>
      <PageHeader
        title="Categoria de Produtos"
        onBack={() => navigate('/admin')}
        extra={(
          <Button
            onClick={() => {
              setParentId(null);
              setDrawerVisible(true);
            }}
            type="primary"
          >
            Adicionar Nova Categoria Base
          </Button>
        )}
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <Card>
          {treeData.length === 0 && (
            <Alert
              message="Você ainda não tem categorias cadastradas."
              type="info"
              showIcon
            />
          )}
          <Tree
            style={{ top: '10px' }}
            treeData={treeData}
            onExpand={onExpand}
            showLine={{ showLeafIcon: false }}
            blockNode
            draggable
            onDrop={onDrop}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            titleRender={(item) => (
              <Row type="flex" align="middle">
                <Col span={18}>
                  {renaming === item.id ? (
                    <Skeleton.Input size="small" style={{ width: 150 }} active />
                  ) : (
                    <Paragraph
                      editable={{
                        icon: <HighlightOutlined />,
                        tooltip: 'Clique para renomear a categoria',
                        onChange: (newTitle) => renameCategory(item, newTitle),
                      }}
                    >
                      {item.title}
                    </Paragraph>
                  )}
                </Col>
                <Col span={3}>
                  <Tooltip title="Adicionar sub-categoria">
                    <PlusCircleOutlined
                      onClick={() => {
                        setInputValue('');
                        setParentId(item.key);
                        setDrawerVisible(true);
                      }}
                      style={{
                        display: 'block',
                        fontSize: '16px',
                        color: '#08c',
                      }}
                    />
                  </Tooltip>
                </Col>
                <Col span={3}>
                  <Popconfirm
                    title="Excluir essa categoria?"
                    onConfirm={() => deleteNode(item.key)}
                    okText="Sim"
                    cancelText="Não"
                  >
                    <DeleteOutlined
                      style={{
                        display: 'block',
                        fontSize: '16px',
                        color: '#08c',
                      }}
                    />
                  </Popconfirm>
                </Col>
              </Row>
            )}
          />
        </Card>
      )}
      <Drawer
        title="Título da categoria"
        placement="bottom"
        height={200}
        onClose={onClose}
        open={drawerVisible}
        extra={(
          <Button
            type="primary"
            onClick={() => createCategory()}
            loading={loading}
          >
            Salvar
          </Button>
        )}
      >
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Exemplo: Maquiagem"
          onKeyDown={(e) => {
            (e.code === 'Enter' || e.code === 'NumpadEnter') && createCategory();
          }}
        />
      </Drawer>
    </>
  );
}
