import store from '../store';

export {
  changePageQty, clearPageQty, addToCart, removeFromCart,
};

function changePageQty(product, qtyInPage, qtyInCart, variation) {
  const state = store.getState();
  const orderOutOfStock = state.sellerData.configuration?.order_out_of_stock || false;
  const stock = orderOutOfStock ? 10000 : product.product_stock;
  if (
    variation > 0
    && qtyInPage + qtyInCart + variation <= stock
  ) {
    return { type: 'INCREASE_QUANTITY', productId: product.id };
  } if (variation < 0) {
    return { type: 'DECREASE_QUANTITY', productId: product.id };
  }
  return { type: 'DEFAULT' };
}

function clearPageQty(product) {
  return { type: 'CLEAR_QUANTITY', productId: product.id };
}

function addToCart(product, qtyInPage, qtyInCart, variation) {
  const state = store.getState();
  const orderOutOfStock = state.sellerData.configuration?.order_out_of_stock || false;
  const stock = orderOutOfStock ? 10000 : product.product_stock;
  if (
    qtyInCart + variation > 0
    && qtyInCart + variation <= stock
  ) {
    variation > 0
      && store.dispatch({
        type: 'SET_MESSAGE',
        messageText: `${variation} produtos adicionados ao carrinho`,
        messageType: 'success',
      });
    variation == 1
      && store.dispatch({
        type: 'SET_MESSAGE',
        messageText: `${variation} produto adicionados ao carrinho`,
        messageType: 'success',
      });

    variation < -1
      && store.dispatch({
        type: 'SET_MESSAGE',
        messageText: `${Math.abs(variation)} produtos removidos do carrinho`,
        messageType: 'success',
      });
    variation == -1
      && store.dispatch({
        type: 'SET_MESSAGE',
        messageText: `${Math.abs(variation)} produto removidos do carrinho`,
        messageType: 'success',
      });
    return { type: 'ADD_CART', product, variation };
  } else if (qtyInCart + variation > product.product_stock) {
    if (product.product_stock - qtyInCart > 0) {
      store.dispatch({
        type: 'SET_MESSAGE',
        messageText: `A quantidade solicitada é maior que o saldo em estoque, ${product.product_stock - qtyInCart
          } produtos foram adicionados ao carrinho`,
        messageType: 'warning',
      });
      return {
        type: 'ADD_CART',
        product,
        variation: product.product_stock - qtyInCart,
      };
    }
    if (product.product_stock - qtyInCart <= 0) {
      store.dispatch({
        type: 'SET_MESSAGE',
        messageText: 'Todo estoque disponível já está no carrinho',
        messageType: 'warning',
      });
      return { type: 'DEFAULT' };
    }
  }
  else if (variation == 0) {
    store.dispatch({
      type: 'SET_MESSAGE',
      messageText: 'A quantidade não foi informada',
      messageType: 'warning',
    });
    return { type: 'DEFAULT' };
  }
}

function removeFromCart(product) {
  return { type: 'REMOVE_CART', product };
}
