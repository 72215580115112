import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { App, Typography, Dropdown, Menu, Space, theme } from 'antd';
import {
  DownOutlined, KeyOutlined, LogoutOutlined
} from '@ant-design/icons';
import logout from '../../actions/logout';
import styles from './navbar.module.css';
import BuyerChangePass from '../BuyerChangePass';
import { axiosClient } from '../../apiClient';

const { Link } = Typography;
const { useToken } = theme;

function Navbar(props) {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useToken();
  const [failMessage, setFailMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const sellerData = useSelector((state) => state.sellerData);
  const minOrderValue = useSelector((state) => state.cart.minOrderValue);
  const cartProducts = useSelector((state) => state.cart.products);
  const cart = useSelector((state) => state.cart);
  const logged = useSelector((state) => state.authentication.logged);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const orderRebuyId = useSelector((state) => state.orderRebuy.orderRebuyId);

  useEffect(() => {
    if (cartProducts.length == 0) {
      setFailMessage('Adicione produtos no carrinho para finalizar o pedido');
    } else if (minOrderValue > total) {
      setFailMessage(
        `Adicione mais R$${parseFloat(minOrderValue - total).toFixed(
          2,
        )} no carrinho para atingir o pedido mínimo de R$${parseFloat(
          minOrderValue,
        ).toFixed(2)}`,
        5,
      );
    } else {
      setFailMessage(false);
    }
  }, [cartProducts]);

  function amount(item) {
    return item.product_price_sale
      ? parseFloat(item.product_price_sale * item.quantity).toFixed(2)
      : parseFloat(item.product_price * item.quantity).toFixed(2);
  }

  function sum(prev, next) {
    return parseFloat(prev) + parseFloat(next);
  }

  let total = 0;
  if (cartProducts.length) {
    total = cartProducts.map(amount).reduce(sum);
  }

  const menu = (
    <Menu>
      <Menu.Item
        icon={<KeyOutlined />}
        onClick={() => {
          setDrawerVisible(true);
        }}
        key="2"
      >
        Trocar Senha
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<LogoutOutlined />}
        onClick={() => {
          logout();
        }}
      >
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <header
        style={{ top: logged === 'seller' || logged === 'sellerUser' ? 50 : 0, backgroundColor: token.colorBgContainer }}
        className={styles.header}
      >
        <div className={styles['div-navbar']}>
          {sellerData.logotipoUrl && (
            <div className={styles['img-container']}>
              <img
                alt="Logotipo"
                src={sellerData.logotipoUrl}
                onClick={() => navigate('/')}
                className={styles['img-logo']}
              />
            </div>
          )}
        </div>
        <input
          className={styles['menu-btn']}
          onClick={() => setChecked(!checked)}
          checked={checked}
          type="checkbox"
          id="menu-btn"
        />
        <label className={styles['menu-icon']} htmlFor="menu-btn">
          <span className={styles.navicon} />
        </label>
        <ul className={styles.menu} style={{ backgroundColor: token.colorBgContainer }}>
          <li
            className={styles['li-class']}
            style={{
              display: logged === 'buyer' ? 'none' : 'block',
            }}
          >
            {!orderRebuyId && <Link
              onClick={() => navigate('/admin')}
            >
              Painel Administrativo
            </Link>}
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'block' : 'none',
            }}
          >
            <Link
              onClick={() => navigate('/')}
            >
              Retornar aos Produtos
            </Link>
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'none' : 'block',
            }}
          >
            {!orderRebuyId &&
              <Link
                onClick={() => {
                  props.handleToggle();
                  setChecked(false);
                }}
              >
                Histórico do Cliente
              </Link>}
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'none' : 'block',
            }}
          >
            <Link
              onClick={() => {
                if (!orderRebuyId) {
                  failMessage
                    ? message.warning(failMessage)
                    : dispatch({ type: 'GO_CHECKOUT' });
                } else {
                  axiosClient
                    .post(
                      '/cart/order/update',
                      { orderId: orderRebuyId, cart },
                      { withCredentials: true },
                    )
                    .then((response) => {
                      if (response.data.status === 'Success') {
                        dispatch({ type: 'RESET_REBUY' });
                        navigate(`/checkout/${orderRebuyId}`);
                      }
                    });
                }
              }}
            >
              Finalizar Pedido
            </Link>
          </li>
          <li
            className={styles['li-class']}
            style={{
              display: location.pathname.includes('checkout') ? 'none' : 'block',
            }}
          >
            <Dropdown
              overlay={menu}
              trigger={['click']}
              className="ant-dropdown-link"
            >
              <Link onClick={(e) => e.preventDefault()}>
                <Space>
                  Opções
                  <DownOutlined />
                </Space>
              </Link>
            </Dropdown>
          </li>
        </ul>
      </header>
      {drawerVisible === true && (
        <BuyerChangePass
          drawer={drawerVisible}
          drawerSetter={setDrawerVisible}
        />
      )}
    </>
  );
}

export default Navbar;
