import React, { useEffect, useState } from 'react';
import {
  Form,
  Card,
  Space,
  Input,
  Button,
  Checkbox,
  Result,
  Typography,
  Row,
  Col,
  theme
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import login from '../../actions/login';
import { axiosClient } from '../../apiClient';
import LoginFooter from '../LoginFooter';

const { Title } = Typography;
const { useToken } = theme;


export default function SellerLogin(props) {
  const { Text, Link } = Typography;
  const { token } = useToken();
  const [form] = Form.useForm();
  const [form_token] = Form.useForm();
  const [form_new_pass] = Form.useForm();
  const [screen, setScreen] = useState('login');
  const [sending, setSending] = useState(false);
  const [reenviado, setReenviado] = useState(false);
  const [emailTo, setEmailTo] = useState('');
  const [loginData, setLoginData] = useState({
    username: false,
    password: false,
    submitted: false,
  });

  const authentication = useSelector((state) => state.authentication);
  const themeConfig = useSelector((state) => state.themeConfig);

  function onFinish(values) {
    setSending(true);
    setLoginData({
      ...loginData,
      submitted: true,
      username: values.username,
      password: values.password,
    });
  }

  function sendEmail() {
    const username = form.getFieldValue('username');
    const resend = true;
    axiosClient
      .post(
        '/auth/seller/reset-password-request',
        { username, resend },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.msg == 'Invalid username input') {
          form.setFields([
            {
              name: 'username',
              errors: ['Usuário não cadastrado'],
            },
          ]);
        } else if (response.data.status == 'Success') {
          setEmailTo(response.data.email);
          setScreen('forgetCode');
        } else {
          setScreen('login');
        }
      });
  }

  function verifyCode() {
    const username = form.getFieldValue('username');
    const token = form_token.getFieldValue('token');
    axiosClient
      .post(
        '/auth/seller/check-reset-token',
        { username, token },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.msg == 'Token invalid') {
          form_token.setFields([
            {
              name: 'token',
              errors: ['Token inválido'],
            },
          ]);
        } else if (response.data.status == 'Success') {
          setScreen('forgetNewPass');
        } else {
          setScreen('forgetCode');
        }
      });
  }

  function sendNewPass() {
    const pass1 = form_new_pass.getFieldValue('pass1');
    const pass2 = form_new_pass.getFieldValue('pass2');
    if (pass1 == pass2) {
      const username = form.getFieldValue('username');
      const token = form_token.getFieldValue('token');
      axiosClient
        .post(
          '/auth/seller/reset-password',
          { username, token, new_password: pass1 },
          { withCredentials: true },
        )
        .then((response) => {
          if (response.data.status == 'Success') {
            setScreen('successNewPass');
          } else {
            setScreen('errorNewPass');
          }
        });
    } else {
      form_new_pass.setFields([
        {
          name: 'pass1',
          errors: [''],
        },
        {
          name: 'pass2',
          errors: ['Senhas divergentes'],
        },
      ]);
    }
  }

  useEffect(() => {
    if (loginData.username && loginData.password) {
      login(
        'seller',
        loginData.password,
        loginData.username,
        null,
        null,
        props.domainName,
        null,
        props.dataBase,
      );
    }
  }, [loginData]);

  useEffect(() => {
    if (authentication.wrongPass) {
      setSending(false);
      form.setFields([
        {
          name: 'password',
          errors: ['Senha incorreta'],
        },
      ]);
    }

    if (authentication.wrongUser) {
      setSending(false);
      form.setFields([
        {
          name: 'username',
          errors: ['Usuário não encontrado'],
        },
      ]);
    }
  }, [authentication]);

  function getPassFinish() {
    sendEmail();
  }

  function setTokenFinish() {
    verifyCode();
  }

  function setPassFinish() {
    sendNewPass();
  }

  return (
    <>
      <Card style={{ boxShadow: token.boxShadow, top: '6vh', width: '95%', maxWidth: '500px', margin: 'auto auto', textAlign: 'center' }}>
        <Space size='small' style={{ width: '100%' }} direction='vertical'>
          <img
            style={{ maxHeight: '100px', maxWidth: '100%' }}
            src={themeConfig.isDark ? "/logo_dark.webp" : "/logo.webp"}
            alt="Logo Nupedido"
          />
          <Form
            form={form}
            size='large'
            scrollToFirstError
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {screen == 'login' && (
              <>
                <Title level={4} style={{ marginBottom: '20px' }}>Faça o login para continuar</Title>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Nome de usuário obrigatório',
                    },
                  ]}
                >
                  <Input
                    type="string"
                    prefix={
                      <UserOutlined />
                    }
                    placeholder="Nome de usuário"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Senha obrigatória',
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Senha"
                    prefix={
                      <LockOutlined />
                    }
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox> Continuar logado</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                          setScreen('getPassword');
                        }}
                      >
                        Recuperar senha
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    loading={sending}
                  >
                    Entrar
                  </Button>
                  {/* Ou <a href="https://nupedido.com.br/registrar">Crie sua conta!</a> */}
                </Form.Item>
              </>
            )}
          </Form>
          {screen == 'getPassword' && (
            <>
              <Title level={4}>Esqueceu a senha?</Title>
              <Form
                form={form}
                name="get_pass"
                initialValues={{
                  remember: true,
                }}
                onFinish={getPassFinish}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Nome de usuário obrigatório',
                    },
                  ]}
                >
                  <Input
                    type="string"
                    prefix={
                      <UserOutlined />
                    }
                    placeholder="Nome de usuário"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    Recuperar Senha
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ width: '100%' }}
                    onClick={() => setScreen('login')}
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          {screen == 'forgetCode' && (
            <>
              <Title level={4}>Enviamos o código para o email: {emailTo}</Title>
              Digite o código de 6 dígitos que enviamos para
              {' '}
              <b>{form.getFieldValue('email')}</b>
              {reenviado == false ? (
                <Button
                  type="link"
                  onClick={() => {
                    sendEmail();
                    setReenviado(true);
                  }}
                >
                  Reenviar código
                </Button>
              ) : (
                <Text type="secondary">Email Reenviado</Text>
              )}
              <br />
              <Form
                form={form_token}
                name="set_token"
                onFinish={setTokenFinish}
              >
                <Form.Item
                  name="token"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="string" placeholder="Código de 6 dígitos" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    Enviar
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ width: '100%' }}
                    onClick={() => setScreen('login')}
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          {screen == 'forgetNewPass' && (
            <>
              <Title level={4}>Escolha uma nova senha</Title>
              <br />
              Escolha uma senha com pelo menos 8 caracteres
              <br />
              <Form
                form={form_new_pass}
                name="set_pass"
                initialValues={{
                  remember: true,
                }}
                onFinish={setPassFinish}
              >
                <Form.Item
                  name="pass1"
                  rules={[
                    {
                      required: true,
                      message: 'Senha obrigatória',
                    },
                  ]}
                >
                  <Input.Password type="string" placeholder="Nova senha" />
                </Form.Item>
                <Form.Item
                  name="pass2"
                  rules={[
                    {
                      required: true,
                      message: 'Senha obrigatória',
                    },
                  ]}
                >
                  <Input.Password
                    type="string"
                    placeholder="Repita a senha escolhida"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    Enviar
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ width: '100%' }}
                    onClick={() => setScreen('login')}
                  >
                    Cancelar
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          {screen == 'successNewPass' && (
            <Result
              status="success"
              subTitle="Senha alterada com sucesso!"
              extra={[
                <Button
                  type="primary"
                  onClick={() => {
                    setScreen('login');
                  }}
                >
                  Fazer Login
                </Button>,
              ]}
            />
          )}
          {screen == 'errorNewPass' && (
            <Result
              status="error"
              subTitle="Ocorreu um erro ao recuperar sua senha."
              extra={[
                <Button
                  type="primary"
                  onClick={() => {
                    setScreen('login');
                  }}
                >
                  Voltar
                </Button>,
              ]}
            />
          )}
        </Space>
      </Card>
      <LoginFooter />
    </>
  );
}
