import React, { useEffect, useState } from 'react';
import {
  App,
  Divider,
  Input,
  Row,
  Col,
  Result,
  Select,
  Button,
  Form,
  Skeleton,
  Space,
  Alert,
  Modal,
  Layout,
  Flex,
  Grid,
  theme
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { openDB } from 'idb';
import Navbar from '../../components/Navbar';
import { axiosClient } from '../../apiClient';
import BuyerListShipping from '../../components/BuyerListShipping';
import BuyerListPayment from '../../components/BuyerListPayment';
import BuyerShippingAddress from '../../components/BuyerShippingAddress';
import BuyerOrderSummary from '../../components/BuyerOrderSummary';
import SellerBuyerView from '../../components/SellerBuyerView';
import OrderEditMessage from '../../components/SellerEditOrderView';
import PagesFooter from '../../components/PagesFooter';

const { Content } = Layout;
const { TextArea } = Input;
const { useToken } = theme;
const { useBreakpoint } = Grid;


export default function Checkout() {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useToken();
  const [formShipping] = Form.useForm();
  const [formPayment] = Form.useForm();
  const screens = useBreakpoint();

  const [orderStatus, setOrderStatus] = useState('notFinished');
  const [sending, setSending] = useState(false);
  const [sendOrderSignal, setSendOrderSignal] = useState(false);
  const [failMessage, setFailMessage] = useState(false);
  const [orderNumber, setOrderNumber] = useState(false);
  const [shippingSelected, setShippingSelected] = useState(false);
  const [useFinalizarPedidoButton, setUseFinalizarPedidoButton] = useState(true);

  const [orderToSellers, setOrderToSellers] = useState(false);
  const [loadingPagSeguro, setLoadingPagSeguro] = useState(false);
  const [showSellerModal, setShowSellerModal] = useState(false);
  const [pagseguroPublicKey, setPagSeguroPublicKey] = useState(null);
  const [sellerUsers, setSellerUsers] = useState(null);
  const [selectedSellerUser, setSelectedSellerUser] = useState({
    value: null,
  });

  const [oldSellerUser, setOldSellerUser] = useState({
    value: null,
  });

  const [selectedOrderType, setSelectedOrderType] = useState("order");
  const [orderTypeTitle, setOrderTypeTitle] = useState('Pedido');

  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const shippingMethod = useSelector((state) => state.order.shippingMethod);
  const observations = useSelector((state) => state.order.observations);
  const sellerData = useSelector((state) => state.sellerData);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const authentication = useSelector((state) => state.authentication);
  const cart = useSelector((state) => state.cart);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);
  const orderDiscrepancies = useSelector((state) => state.sync.orderDiscrepancies);
  const orderRebuy = useSelector((state) => state.orderRebuy);
  const logged = useSelector((state) => state.authentication.logged);

  async function updateData(data, dBStoreName) {
    const db = await openDB('nupedido', 1);
    return db.put(dBStoreName, data);
  }

  const { orderRebuyId } = useParams();

  useEffect(() => {
    if (orderRebuy.orderType) {
      setSelectedOrderType(orderRebuy.orderType);
      setOrderTypeTitle(orderRebuy.orderType === 'quote' ? 'Orçamento' : 'Pedido');
    }
  }, [orderRebuy.orderType]);

  useEffect(() => setOrderTypeTitle(selectedOrderType === 'quote' ? 'Orçamento' : 'Pedido'), [selectedOrderType]);


  useEffect(() => {
    orderRebuyId && dispatch({ type: 'SET_ORDER_REBUY', orderRebuyId });
  }, []);

  function getFormattedDate() {
    const now = new Date();
    const padZero = (num) => (num < 10 ? '0' : '') + num;
    const formattedDate = `${padZero(now.getDate())}/${padZero(now.getMonth() + 1)}/${now.getFullYear()} `
      + `${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}`;

    return formattedDate;
  }

  useEffect(() => {
    if (cart.retrieved && cart.products.length === 0) {
      setFailMessage({
        title: 'Seu carrinho está vazio',
        subTitle:
          'Volte para o formulário de pedidos e adicione mais produtos.',
      });
    } else if (cart.retrieved && (cart.minOrderValue > cart.productsTotal)) {
      setFailMessage({
        title: `Você ainda não atingiu o pedido mínimo de R$${parseFloat(
          cart.minOrderValue,
        ).toFixed(2)}`,
        subTitle: `Volte para o formulário de pedidos e adicione mais R$${parseFloat(
          cart.minOrderValue - cart.productsTotal,
        ).toFixed(2)} no carrinho para atingir o pedido mínimo.`,
      });
    }
    else {
      setFailMessage(false);
    }

  }, [cart.retrieved, cart.productsTotal]);

  useEffect(() => {
    if (authentication.logged === 'seller' || (authentication.logged === 'sellerUser' && sellerData.permissions
      && sellerData.permissions.configuration
      && sellerData.permissions.configuration.order_to_sellers)) {
      setOrderToSellers(true);
    } else if (authentication.logged === 'sellerUser' && buyerView && buyerView.selleruser == null) {
      setSelectedSellerUser({
        value: authentication.userId,
      });
    }
  }, [sellerData, buyerView]);

  useEffect(() => {
    if (buyerView && buyerView.selleruser && buyerView.selleruser.id) {
      setOldSellerUser({
        value: buyerView.selleruser.id,
        label: buyerView.selleruser.nome,
      });
      setSelectedSellerUser({
        value: buyerView.selleruser.id,
        label: buyerView.selleruser.nome,
      });
    }
  }, [buyerView]);

  useEffect(() => {
    (authentication.logged === 'seller' || authentication.logged === 'sellerUser')
      && axiosClient
        .get('/auth/list/seller-users', { withCredentials: true })
        .then((response) => {
          setSellerUsers(
            response.data.map((value) => ({
              value: value.id,
              label: value.nome,
            })),
          );
        });
  }, []);

  // PAGSEGURO SETUP
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js';
    script.async = true;
    document.body.appendChild(script);

    axiosClient
      .get('/integrations/pagseguro/get-public-key', { withCredentials: true })
      .then((response) => {
        response.data.status == 'Success'
          && setPagSeguroPublicKey(response.data.public_key);
      });
    setLoadingPagSeguro(false);
    return () => {
      document.body.removeChild(script);
    };
  }, []);


  const onCheck = async () => {
    if ('cardExpiry' in formPayment.getFieldsValue()) {
      if (formPayment.getFieldsValue().cardExpiry.length != 5) {
        formPayment.setFields([
          {
            name: 'cardExpiry',
            errors: ['Por favor, insira a Validade'],
          },
        ]);
      }
    }
    await formShipping
      .validateFields()
      .then(
        formPayment.validateFields().catch((result) => {
          setSendOrderSignal(false);
          formShipping.scrollToField(result.errorFields[0].name, {
            behavior: (actions) => {
              actions.forEach(({ el, top, left }) => {
                el.scrollTop = top;
                el.scrollLeft = left;
              });
            },
          });
        }),
      )
      .then((result) => sendOrderSignal && sendOrder())
      .catch((result) => {
        setSendOrderSignal(false);
        formShipping.scrollToField(result.errorFields[0].name, {
          behavior: (actions) => {
            actions.forEach(({ el, top, left }) => {
              el.scrollTop = top - 150;
              el.scrollLeft = left;
            });
          },
        });
      });
  };

  useEffect(() => {
    (cart.returned || orderRebuyId) && sendOrderSignal && onCheck();
  }, [cart.returned, sendOrderSignal]);

  function sendOrder(paymentData) {
    const buyerAddress = formShipping.getFieldsValue();
    if (paymentData === undefined) {
      paymentData = formPayment.getFieldsValue();
    }

    if (shippingMethod === null) {
      setSendOrderSignal(false);
      message.warning('Selecione um método de entrega para finalizar o pedido');
    }

    if (paymentMethod === null) {
      setSendOrderSignal(false);
      message.warning(
        'Selecione um método de pagamento para finalizar o pedido',
      );
    }

    if (selectedSellerUser.value === null && authentication.logged != 'buyer') {
      setSendOrderSignal(false);
      message.warning('Selecione um vendedor para finalizar o pedido');
      window.scrollTo(0, 0);
    }

    if (
      paymentMethod
      && shippingMethod
      && (selectedSellerUser.value || authentication.logged === 'buyer')
    ) {
      if (paymentMethod.metodo === 'pagseguro_credit_card') {
        let card_encrypted = null;
        if (loadingPagSeguro == false) {
          card_encrypted = window.PagSeguro.encryptCard({
            publicKey: pagseguroPublicKey,
            holder: paymentData.cardName,
            number: paymentData.cardNumber.replaceAll(/\s/g, ''),
            expMonth: paymentData.cardExpiry.substring(0, 2),
            expYear: parseInt(paymentData.cardExpiry.substring(3, 5)) + 2000,
            securityCode: paymentData.securityCode,
          });

          if (card_encrypted.hasErrors == true) {
            paymentData = null;
            setSendOrderSignal(false);
            message.warning('Erro no preenchimento do cartão de crédito');
          } else {
            paymentData = {
              ...card_encrypted,
              securityCode: paymentData.securityCode,
              holder: paymentData.cardName,
            };
          }
        }
      }

      if (paymentData) {
        setSending(true);
        setSending(true);
        const orderData = {
          paymentMethod: {
            ...paymentMethod,
            total_value: paymentData.transaction_amount,
          },
          shippingMethod,
          buyerAddress,
          paymentData,
          observations,
          selectedSellerUser: selectedSellerUser.value,
          buyerId: buyerView ? buyerView.id : null,
        };

        if (onlineStatus && !orderRebuyId) {
          const orderUrl = selectedOrderType === "quote" ? '/cart/quote' : '/cart/order';
          axiosClient
            .post(orderUrl, orderData, { withCredentials: true })
            .then((response) => {
              if (response.data.status === 'Success') {
                setOrderStatus('success');
                setSending(false);
                setOrderNumber(response.data.order_number);
                dispatch({
                  type: 'SET_CART',
                  products: [],
                  productsTotal: 0,
                  shippingValue: 0,
                  paymentDiscountValue: 0,
                  cartTotal: 0,
                  productsTotalAfterDiscount: 0,
                });
              } else if (response.data.status === 'Error') {
                if ((response.data.message = 'pagseguro error')) {
                  setFailMessage({
                    title: 'Ocorreu um erro durante o pagamento via PagSeguro',
                    subTitle: 'Tente novamente ou escolha outra forma de pagamento. Se o erro persistir, contate seu vendedor.',
                  });
                  setOrderStatus('notFinished');
                  setSending(false);
                }
                if ((response.data.message = 'mercadopago error')) {
                  setFailMessage({
                    title: 'Ocorreu um erro durante o pagamento via Mercado Pago',
                    subTitle: 'Tente novamente ou escolha outra forma de pagamento. Se o erro persistir, contate seu vendedor.',
                  });
                  setOrderStatus('notFinished');
                  setSending(false);
                }
              }
            });
        } else if (onlineStatus && orderRebuyId) {
          axiosClient
            .post('/cart/order/update', { orderId: orderRebuyId, selectedOrderType, cart, ...orderData }, { withCredentials: true })
            .then((response) => { response.data.status == 'Success' && navigate(`/admin/vendas/${selectedOrderType === 'quote' ? 'orcamentos' : 'pedidos'}/${orderRebuyId}`); });
        } else if (syncStatus === 'synced') {
          updateData({ ...orderData, products: cart.products }, 'orders').then((orderId) => dispatch({
            type: 'SET_SYNC',
            orderDiscrepancies: {
              ...orderDiscrepancies,
              idsNeedSubmit: [...orderDiscrepancies.idsNeedSubmit, orderId],
            },
          })).then(() => {
            const currentDate = getFormattedDate();
            const cartData = {
              id: buyerView.cart_id,
              buyer_id: buyerView.id,
              seller_id: buyerView.seller_id,
              last_modification: currentDate,
              needSubmit: true,
              products_ids: [],
              quantities: [],
              prices_modified: [],
            };
            updateData(cartData, 'carts');
          });
          setOrderStatus('successOffline');
          setSending(false);
        }
      }
    }
  }

  const zeroPad = (num, places) => String(num).padStart(places, '0');

  function FinishButton() {
    return <Button
      size="large"
      type="primary"
      loading={sending}
      onClick={() => {
        setSendOrderSignal(true);
      }}
      style={{ width: 180 }}
    >
      Finalizar {selectedOrderType === 'quote' ? 'Orçamento' : 'Pedido'}
    </Button>;
  }



  return (
    <>
      <Layout style={{
        backgroundColor: token.customColor2 ?? token.colorBgLayout,
        position: 'absolute', width: '100%', left: '0px', top: '0px'
      }}
      >
        {(authentication.logged === 'seller' || authentication.logged === 'sellerUser') && (orderRebuyId ? <OrderEditMessage /> : <SellerBuyerView />)}
        <Navbar />
        <Content>
          <div style={{
            // backgroundColor: token.customColor1 ?? token.colorBgContainer,
            padding: token.padding,
            backgroundColor: token.colorBgContainer,
            borderRadius: token.borderRadius,
            marginLeft: screens.md ? token.marginSM : 0,
            marginRight: screens.md ? token.marginSM : 0,
            marginBottom: screens.md ? token.marginSM : 0,
            marginTop: (logged === 'seller' || logged === 'sellerUser')
              ? (screens.md ? 110 + token.marginSM : 110)
              : (screens.md ? 60 + token.marginSM : 60)
          }}>
            {!cart.retrieved && <Skeleton active />}
            {orderStatus === 'notFinished' && cart.retrieved && (
              <>
                {cart.retrieved && failMessage && <Result
                  status="warning"
                  title={failMessage.title}
                  subTitle={failMessage.subTitle}
                  extra={(
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => {
                        window.location.replace('/');
                      }}
                    >
                      Formulário de Pedidos
                    </Button>
                  )}
                />}

                <div
                  style={{ display: failMessage || !cart.retrieved ? 'none' : 'block' }}
                >
                  <Row
                    style={{ paddingBottom: '100px' }}
                    gutter={{
                      xs: 8, sm: 16, md: 24, lg: 32,
                    }}
                  >
                    <Col xs={24} xl={12}>
                      {orderToSellers && (
                        <>
                          <Divider orientation="left">Vendedor</Divider>
                          <Space direction="vertical" style={{ width: '100%' }}>
                            {oldSellerUser.value
                              && selectedSellerUser.value
                              && oldSellerUser.value
                              != selectedSellerUser.value && (
                                <Alert
                                  message={(
                                    <>
                                      O vendedor padrão deste cliente será
                                      modificado de
                                      {' '}
                                      <b>{oldSellerUser.label}</b>
                                      {' '}
                                      para
                                      {' '}
                                      <b>{selectedSellerUser.label}</b>
                                    </>
                                  )}
                                  type="warning"
                                  showIcon
                                  closable
                                />
                              )}
                            {selectedSellerUser.value == null && (
                              <Alert
                                message="Selecione um vendedor."
                                type="error"
                                showIcon
                              />
                            )}
                            <Select
                              value={selectedSellerUser}
                              style={{ width: '100%' }}
                              onChange={(value, data) => {
                                setSelectedSellerUser(data);
                                oldSellerUser.value
                                  && oldSellerUser.value != value
                                  && setShowSellerModal(true);
                              }}
                              options={sellerUsers}
                            />
                          </Space>
                        </>
                      )}
                      <BuyerShippingAddress form={formShipping} />
                      <Divider orientation="left">Observações</Divider>
                      <TextArea
                        onChange={(e) => dispatch({ type: 'SET_ORDER', observations: e.target.value })}
                        rows={4}
                        maxLength={1000}
                        value={observations}
                      />
                    </Col>
                    <Col xs={24} xl={12}>
                      <BuyerOrderSummary />
                      <BuyerListShipping
                        shippingSelected={shippingSelected}
                        setShippingSelected={setShippingSelected}
                      />
                      {shippingSelected && (
                        <BuyerListPayment
                          form={formPayment}
                          sendOrderFunc={sendOrder}
                          useFinalizarPedidoButton={useFinalizarPedidoButton}
                          setUseFinalizarPedidoButton={
                            setUseFinalizarPedidoButton
                          }
                        />
                      )}
                    </Col>
                  </Row>
                  {useFinalizarPedidoButton === true && (
                    <Flex style={{ padding: '16px' }} justify="center">
                      {(authentication.logged === 'seller' || authentication.logged === 'sellerUser') &&
                        <Row
                          gutter={[24, 24]} // Define o espaçamento entre os elementos
                          justify="space-around"
                          align="middle"
                        >
                          <Col xs={18} sm={12}>
                            <Select
                              value={selectedOrderType}
                              onChange={(value) => setSelectedOrderType(value)}
                              size="large"
                              style={{ width: 180 }}
                              placement="topLeft"
                            >
                              <Option value="order">Pedido</Option>
                              <Option value="quote">Orçamento</Option>
                            </Select>
                          </Col>
                          <Col xs={18} sm={12} >
                            <FinishButton />
                          </Col>
                        </Row>}
                      {authentication.logged === 'buyer' && <FinishButton />}
                    </Flex>
                  )}
                </div>
              </>
            )}
            {orderStatus === 'success' && cart.retrieved && (
              <Result
                status="success"
                title={`Seu ${orderTypeTitle} foi concluido!`}
                subTitle={`${orderTypeTitle} número ${zeroPad(
                  orderNumber,
                  5,
                )} foi concluido com sucesso. Uma cópia foi enviada para seu e-mail.`}
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      window.location.replace(
                        `/?meus-pedidos`,
                      );
                    }}
                  >
                    Meus Pedidos
                  </Button>,
                  <Button
                    onClick={() => {
                      window.location.replace('/');
                    }}
                    key="buy"
                  >
                    Novo Pedido
                  </Button>,
                ]}
              />
            )}
            {orderStatus === 'successOffline' && cart.retrieved && (
              <Result
                status="success"
                title={`Seu ${orderTypeTitle}foi salvo!`}
                subTitle={`Seu ${orderTypeTitle} foi concluido e está salvo no seu dispositivo, fique online para sincronizar os dados.`}
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      window.location.replace(
                        `/meus-pedidos`,
                      );
                    }}
                  >
                    Meus Pedidos
                  </Button>,
                  <Button
                    onClick={() => {
                      window.location.replace('/');
                    }}
                    key="buy"
                  >
                    Novo Pedido
                  </Button>,
                ]}
              />
            )}
          </div>
        </Content>
        <PagesFooter />
      </Layout>
      {showSellerModal && (
        <Modal
          title="Selecionar vendedor"
          open={showSellerModal}
          onOk={() => {
            setShowSellerModal(false);
          }}
          onCancel={() => {
            setShowSellerModal(false);
            setSelectedSellerUser({
              label: oldSellerUser.label,
              value: oldSellerUser.value,
            });
          }}
          okText="Trocar Vendedor"
        >
          <p>
            <b>{oldSellerUser.label}</b>
            {' '}
            é o atual vendedor associado a este
            cliente, deseja trocar para {' '}
            <b>{selectedSellerUser.label}</b>
            ?
          </p>
        </Modal>
      )}
    </>
  );
}
