import React, { useState, useEffect, useRef } from 'react';
import { App, Skeleton, Modal, Alert, Table, Form, Select, Button, Card, Input, Space, Typography, TreeSelect } from 'antd';

import { axiosClient } from '../../apiClient';
import { useNavigate } from 'react-router-dom';
import { HighlightOutlined, CheckOutlined } from '@ant-design/icons';
import ComponentBuyerCondition from '../ComponentBuyerCondition'
import { formatBuyer } from '../../functions/validation';

const { Link } = Typography;

export default function SellerMinimumOrder() {
  const { message } = App.useApp();
  const inputRef = useRef();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rules, setRules] = useState([]);
  const [editMinOrder, setEditMinOrder] = useState(false);
  const [newMinOrderValue, setNewMinOrderValue] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [minOrderValue, setMinOrderValue] = useState(0);


  function formatCurrency(val) {
    if (val == null || isNaN(val)) {
      return 'R$ 0,00';
    }
    return val.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  useEffect(() => {
    setLoading(true);
    axiosClient
      .get('/config/default-values', { withCredentials: true })
      .then((response) => {
        const val = response.data?.minimum_order_default
        setMinOrderValue(val)
        setNewMinOrderValue(formatCurrency(val))
        setLoading(false)
      });
  }, []);

  useEffect(() => {
    updateRules()
  }, []);


  const renderBuyers = (record) => (
    <Space direction="vertical">
      {record.buyer_condition === 'all' && 'Todos Clientes'}
      {record.buyer_condition !== 'all' && (
        (record.buyers_tags?.length > 0 && 'Por Tags') ||
        (record.buyers?.length > 0 && 'Clientes Específicos') ||
        (
          record.ceps_data &&
          (record.ceps_data.ceps_full?.length > 0 ||
            record.ceps_data.ceps_range?.length > 0 ||
            record.ceps_data.ceps_start?.length > 0) &&
          'Por CEP'
        )
      )}
    </Space>
  );

  const columns = [
    {
      title: 'Nome da Regra',
      ellipsis: true,
      responsive: ['sm'],
      render: (record) => (
        <Link>
          {' '}
          {record.name}
          {' '}
        </Link>
      ),
    },
    {
      title: 'A quem se aplica',
      ellipsis: true,
      responsive: ['sm'],
      render: renderBuyers
    },
    {
      title: 'Valor',
      responsive: ['sm'],
      render: (record) => formatCurrency(record?.min_order_personalized)
      ,
    },
    {
      title: 'Regras',
      render: (record) => (
        <>
          <Link>
            {' '}
            {record.name}
            {' '}
          </Link>
          <br />
          <b>Clientes:</b>
          {' '}
          {renderBuyers(record)}
          <br />
          <b>Valor: {formatCurrency(record?.min_order_personalized)}</b>
        </>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  useEffect(() => {
    if (editMinOrder) {
      inputRef.current?.focus();
    }
  }, [editMinOrder]);

  const sendMinOrder = () => {
    setLoading(true)
    setEditMinOrder(false)
    const val = parseInt(newMinOrderValue.replace(/\D/g, '')) / 100;
    axiosClient
      .post('/config/default-values', { minimum_order_default: val }, { withCredentials: true })
      .then((response) => {
        setMinOrderValue(val)
        setLoading(false)
        message.success('Valor do Pedido Mínimo Atualizado')
      })
  }

  const handleDelete = () => {
    if (!selectedRecordId) return;
    axiosClient
      .post('/rules/delete', { ruleId: selectedRecordId }, { withCredentials: true })
      .then(() => {
        message.success('Configuração excluída com sucesso', 4)
        setModalOpen(false);
        setSelectedRecordId(null);
        updateRules()
      })
  };

  const handleSave = (values) => {
    const request = selectedRecordId
      ? axiosClient.post('/rules/update', { action: 'minOrder', ruleId: selectedRecordId, ...values }, { withCredentials: true })
      : axiosClient.post('/rules/create', { action: 'minOrder', ...values }, { withCredentials: true });

    request
      .then(() => {
        message.success('Operação realizada com sucesso', 4)
        setModalOpen(false);
        form.resetFields();
        setSelectedRecordId(null);
        updateRules()
      })
  };

  const updateRules = () => {
    setLoading(true)
    axiosClient
      .post('/rules/list', { actions: ['minOrder'] }, { withCredentials: true })
      .then((response) => {
        setLoading(false)
        setRules(response.data.rules);
      })
  }

  return (
    (
      <Space style={{ width: '100%' }} direction='vertical'>
        <Alert
          message="Configure um valor mínimo para pedidos, com opção de personalizar por tipo de cliente."
          type="info"
          showIcon
        />
        <Form.Item
          name="minimum_order_default"
          label="Pedido Mínimo Padrão"
          rules={[
            {
              required: true,
            },
          ]}
          onChange={(e) => {
            const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
            form.setFields([
              {
                name: 'minimum_order_default',
                value: formatCurrency(val),
              },
            ]);
          }}
        >{loading ? (
          <Skeleton.Input active />
        ) : (
          editMinOrder ? (
            <Space.Compact>
              <Input
                ref={inputRef}
                value={newMinOrderValue}
                onPressEnter={sendMinOrder}
                onChange={(e) => {
                  const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                  setNewMinOrderValue(formatCurrency(val));
                }}
                onBlur={() => {
                  setTimeout(() => {
                    setNewMinOrderValue(formatCurrency(minOrderValue));
                    setEditMinOrder(false);
                  }, 500);
                }}
                placeholder="Valor do pedido mínimo"
              />
              <Button onClick={sendMinOrder} icon={<CheckOutlined />} type="primary" />
            </Space.Compact>
          ) : (
            <Button onClick={() => setEditMinOrder(true)} variant="filled">
              {formatCurrency(minOrderValue)}
              <Link>
                <HighlightOutlined />
              </Link>
            </Button>
          )
        )}

        </Form.Item>
        <Card title='Pedido Mínimo Personalizado' size='small'><Space style={{ width: '100%' }} direction='vertical'>

          <Form.Item
            name="progressiveTable"
          >
            <Space direction="vertical" style={{ width: '100%' }}>

              {rules?.length > 0 && <Table
                size='small'
                style={{ cursor: !loading ? 'pointer' : 'auto' }}
                columns={columns}
                dataSource={rules}
                pagination={false}
                onRow={(record, rowIndex) => ({
                  onClick: (event) => {
                    setSelectedRecordId(record.id);
                    setModalOpen(true);

                    let ceps_data = record.ceps_data;
                    let ceps_start = '';
                    if (ceps_data && ceps_data.ceps_start.length > 0) {
                      ceps_start = `${ceps_data.ceps_start.join('*\r\n')}*`;
                    }
                    let ceps_full = '';
                    if (ceps_data && ceps_data.ceps_full.length > 0) {
                      ceps_full = ceps_data.ceps_full.join('\r\n');
                    }
                    let ceps_range = '';
                    if (ceps_data && ceps_data.ceps_range.length > 0) {
                      ceps_range = ceps_data.ceps_range
                        .map((item) => item.join('...'))
                        .join('\r\n');
                    }
                    ceps_data = [ceps_full, ceps_start, ceps_range]
                      .filter(Boolean)
                      .join('\r\n')

                    form.setFieldsValue({
                      name: record.name,
                      ['buyerForm']: {
                        'condition': record.buyer_condition,
                        'specific': record.buyers?.map((buyer) => ({ value: buyer.id, label: formatBuyer(buyer) })),
                        'tags': record.buyers_tags?.map((tag) => ({ value: tag.id, label: tag.title })),
                        'ceps': ceps_data
                      },
                      min_order_personalized: record?.min_order_personalized
                    })
                  },
                })}
              />}
              <Button type='dashed' onClick={() => {
                setSelectedRecordId(null);
                form.resetFields();
                setModalOpen(true)
              }} block>
                Adicionar Pedido Mínimo Personalizado
              </Button>
            </Space>
          </Form.Item>
        </Space>
        </Card >
        <Modal
          open={modalOpen}
          destroyOnClose
          width='90%'
          centered
          title={selectedRecordId ? 'Editar Pedido Mínimo Personalizado' : 'Cadastrar Pedido Mínimo Personalizado'}
          okText={selectedRecordId ? 'Salvar' : 'Cadastrar'}
          okButtonProps={{
            autoFocus: true,
            htmlType: 'submit',
          }}
          cancelText="Cancelar"
          onCancel={() => {
            form.resetFields()
            setModalOpen(false);
          }}
          footer={
            <>
              <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
              {selectedRecordId && (
                <Button danger onClick={handleDelete}>
                  Excluir
                </Button>
              )}
              <Button type="primary" onClick={() => form.submit()}>
                {selectedRecordId ? 'Salvar' : 'Cadastrar'}
              </Button>
            </>
          }
          modalRender={(dom) => (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSave}
            >
              {dom}
            </Form>
          )}
        >
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            {/* Nome da Regra */}
            <Card size='small'>
              <Form.Item
                name="name"
                layout='horizontal'
                label="Nome da Regra"
                rules={[{ required: true, message: 'Por favor, insira o nome da regra.' }]}
              >
                <Input placeholder="Ex.: Pedido mínimo para supermercados" />
              </Form.Item>
              <ComponentBuyerCondition
                form={form}
                label="Escolha os clientes para aplicar a regra"
                showAllOption={false}
              />
              <Form.Item
                name="min_order_personalized"
                label="Pedido Mínimo Personalizado"
                rules={[
                  {
                    required: true,
                  },
                ]}
                onChange={(e) => {
                  const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                  form.setFields([
                    {
                      name: 'min_order_personalized',
                      value: formatCurrency(val),
                    },
                  ]);
                }}
              >
                <Input placeholder="Valor do pedido mínimo" />
              </Form.Item>
            </Card>
          </Space>
        </Modal>
      </Space >)
  );
}
