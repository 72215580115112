import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table, Button, Skeleton, Empty, Typography, Space
} from 'antd';
import { axiosClient } from '../../apiClient';

const { Link } = Typography;

export default function SellerListPayment() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .post('/payment/get', { paymentId: false }, { withCredentials: true })
      .then((response) => {
        setData(response.data.payments);
        setLoading(false);
      });
  }, []);


  const renderBuyers = (record) => (
    <Space direction="vertical">
      {record.buyer_condition === 'all' && 'Todos Clientes'}
      {record.buyer_condition !== 'all' && (
        (record.buyers_tags?.length > 0 && 'Por Tags') ||
        (record.buyers?.length > 0 && 'Clientes Específicos') ||
        (
          record.ceps_data &&
          (record.ceps_data.ceps_full?.length > 0 ||
            record.ceps_data.ceps_range?.length > 0 ||
            record.ceps_data.ceps_start?.length > 0) &&
          'Por CEP'
        )
      )}
    </Space>
  );

  const columns = [
    {
      title: 'Descrição',
      dataIndex: 'descricao',
      render: (text) => (
        <Link>
          {' '}
          {text}
          {' '}
        </Link>
      ),
      ellipsis: true,
      responsive: ['sm'],
    },
    {
      title: 'Métodos',
      render: (record) => (
        <Link>
          {' '}
          {record.descricao}
          {' '}
        </Link>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
    {
      title: 'A quem se aplica',
      render: renderBuyers,
      responsive: ['sm'],
    },
    {
      title: 'Métodos',
      render: (record) => (
        <>
          <Link>{record.nome}</Link>
          <br />
          {' '}
          <Space split="e">
            {renderBuyers(record)}
          </Space>
          <br />
        </>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  return (
    <>
      <PageHeader
        title="Métodos de Pagamento"
        onBack={() => navigate('/admin')}
        extra={(
          <Button
            onClick={() => {
              navigate('/admin/pagamento/cadastro');
            }}
            type="primary"
          >
            Adicionar Método de Pagamento
          </Button>
        )}
      />

      <Table
        columns={columns}
        dataSource={loading ? [] : data}
        locale={{
          emptyText: loading ? <Skeleton active /> : <Empty />,
        }}
        style={{ cursor: !loading ? 'pointer' : 'auto' }}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            navigate(`/admin/pagamento/cadastro/${record.id}`);
          },
        })}
      />
    </>
  );
}
