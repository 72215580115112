function initialState() {
  return ({ list: [], loading: true });
}

export function productList(state = initialState(), action) {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return {
        ...state,
        ...('list' in action && { list: action.list }),
        ...('loading' in action && { loading: action.loading }),
      };
    default:
      return state;
  }
}
