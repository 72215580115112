import React from 'react';
import {
  Layout,
  Typography,
  theme
} from 'antd';
import { useSelector } from 'react-redux';

const { Footer } = Layout;
const { Text, Link } = Typography;
const { useToken } = theme;

export default function PagesFooter() {
  const { token } = useToken();

  const buildHash = useSelector((state) => state.buildHash);
  const themeConfig = useSelector((state) => state.themeConfig);

  return (
    <Footer
      style={{
        textAlign: 'center',
        backgroundColor: themeConfig.isDark ? token.colorBgContainer : token.colorBgLayout,
      }}
    >
      <Text>
        Criado por{' '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://nupedido.com.br/?source=admin"
        >
          Nupedido
        </Link>©{' '} 2024 - Versão {buildHash ? buildHash.buildHash : 'N/A'}
      </Text>
    </Footer>
  );
}
