import { Col, Row } from 'antd';
import React from 'react';

import SellerIntegrationsWoo from '../SellerIntegrationsWoo';
import SellerIntegrationsOmie from '../SellerIntegrationsOmie';
import SellerIntegrationsPagseguro from '../SellerIntegrationsPagseguro';
import SellerIntegrationsMercadoPago from '../SellerIntegrationsMercadoPago';
import SellerIntegrationsMelhorEnvio from '../SellerIntegrationsMelhorEnvio';

export default function IntegrationsPage() {

  return (
    <>
        <Row gutter={[16, 16]}>
          <Col>
            <SellerIntegrationsWoo />
          </Col>
          <Col>
            <SellerIntegrationsOmie />
          </Col>
          <Col>
            <SellerIntegrationsPagseguro />
          </Col>
          <Col>
            <SellerIntegrationsMercadoPago />
          </Col>
          <Col>
            <SellerIntegrationsMelhorEnvio />
          </Col>
        </Row>
    </>
  );
}
