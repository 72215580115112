import React from 'react';
import {
  Layout,
  Typography,
  theme
} from 'antd';
import { useSelector } from 'react-redux';


const { Footer } = Layout;
const { Text, Link } = Typography;
const { useToken } = theme;

export default function PagesFooter() {
  const buildHash = useSelector((state) => state.buildHash);
  const { token } = useToken();

  return (
    <div
      style={{
        paddingTop: '12vh',
        // backgroundColor: token.colorBgBase,
        textAlign: 'center',
      }}
    >
      <Text>
        Criado por{' '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://nupedido.com.br/?source=admin"
        >
          Nupedido
        </Link>©{' '} 2024 - Versão {buildHash ? buildHash.buildHash : 'N/A'}
      </Text>
    </div>
  );
}
