import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { App, Table, Popover, Divider, Button, Alert, List, Typography, Empty, Input, Select, Space, Flex } from 'antd';
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styles from './cart.module.css';
import QtyChangerCart from '../QtyChangerCart';
import { addToCart, removeFromCart } from '../../actions/cart';
import { axiosClient } from '../../apiClient';

const { Text, Link, Title } = Typography;

export default function Cart() {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef();
  const cartProducts = useSelector((state) => state.cart.products);
  const productsTotal = useSelector((state) => state.cart.productsTotal);
  const productsTotalAfterDiscount = useSelector((state) => state.cart.productsTotalAfterDiscount);
  const minOrderValue = useSelector((state) => state.cart.minOrderValue);
  const logged = useSelector((state) => state.authentication.logged);
  const orderRebuy = useSelector((state) => state.orderRebuy);
  const cart = useSelector((state) => state.cart);
  const sellerData = useSelector((state) => state.sellerData);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);
  const cartAuxInfo = useSelector((state) => state.cart.cartAuxInfo);

  const [failMessage, setFailMessage] = useState(false);
  const [editPrice, setEditPrice] = useState(null);
  const [newPrice, setNewPrice] = useState(null);

  const [inputValue, setInputValue] = useState('0');
  const [editDiscount, setEditDiscount] = useState(false);

  const getProductPrice = (product, qtyInCart) => {
    const priceBulk = product?.product_price_bulk;
    const bulkPrice = priceBulk && Object.entries(priceBulk).reduce((acc, [minQty, price], index, entries) => {
      const nextMinQty = entries[index + 1]?.[0];
      return qtyInCart >= minQty && (!nextMinQty || qtyInCart < nextMinQty) ? price : acc;
    }, null);
    return product.price_modified || Math.min(
      ...[bulkPrice, product.product_price_sale, product.product_price].filter(price => price != null)
    );
  };

  function dealWithSubmit(product) {
    setEditPrice(null);
    const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price;
    (parseFloat(newPrice) === actualPrice || newPrice === null) ? dispatch({ type: 'REMOVE_MODIFY_PRICE', product }) : dispatch({ type: 'MODIFY_PRICE', product, price_modified: newPrice });
    dispatch({ type: 'CART_UPDATED', updated: true });
    dispatch({ type: 'CART_RETURNED', returned: false });
  }

  useEffect(() => {
    if (productsTotalAfterDiscount == 0) {
      setFailMessage('Adicione produtos no carrinho para finalizar o pedido');
    } else if (minOrderValue > productsTotalAfterDiscount) {
      setFailMessage(
        `Adicione mais R$${parseFloat(minOrderValue - productsTotalAfterDiscount).toFixed(
          2,
        )} no carrinho para atingir o pedido mínimo de R$${parseFloat(
          minOrderValue,
        ).toFixed(2)}`,
        5,
      );
    } else {
      setFailMessage(false);
    }
  }, [productsTotalAfterDiscount]);

  useEffect(() => {
    if (myRef && myRef.current) {
      const { input } = myRef.current;
      input.focus();
    }
  });

  function CartRow(props) {
    const { product } = props;

    let imgSrc = null;

    if (onlineStatus) {
      if (props.product.product_image && props.product.product_image[0]) {
        imgSrc = props.product.product_image[0].thumbnail;
      }
    } else if (onlineStatus === false && syncStatus === 'synced') {
      imgSrc = localStorage.getItem('products' + `_${props.product.id}`);
    }

    function calculateDiscountedPrice(product, discountPercentage) {
      const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price;
      const discount = actualPrice * (discountPercentage / 100);
      return (actualPrice - discount).toFixed(2); // Formata o valor com 2 casas decimais
    }

    return (
      <List.Item>
        <div
          key={`card${product.product_sku}`}
          className={styles['card-item']}
        >
          <div className={styles['img-container']}>
            <img
              className={styles['product-img']}
              src={imgSrc || '/fallback.png'}
              alt=""
            />
          </div>
          <div className={styles.product_info}>
            <div className={styles.product_name}>
              {product.product_name}
            </div>
            <div className={styles['product-calc']}>
              <div>
                <span>
                  {logged === 'seller' ||
                    (logged === 'sellerUser' && sellerData.permissions.configuration.modify_price_in_cart)
                    ? (
                      editPrice === product.id ? (
                        <Space.Compact>
                          <Input
                            onBlur={() => setEditPrice(null)}
                            type="number"
                            pattern="\d*"
                            size="small"
                            prefix="R$"
                            value={newPrice}
                            style={{ width: '60%' }}
                            onChange={(e) => {
                              const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                              setNewPrice(val.toFixed(2));
                            }}
                            ref={myRef}
                            onPressEnter={() => dealWithSubmit(product)}
                          />
                          <Button onMouseDown={() => dealWithSubmit(product)} size="small" type="primary">
                            <CheckOutlined />
                          </Button>
                        </Space.Compact>
                      ) : (
                        <div style={{ lineHeight: '110%' }}>
                          <Popover
                            content={
                              <>
                                <Table
                                  size="small"
                                  showHeader={false}
                                  columns={[
                                    { title: 'Tabela', dataIndex: 'discount', key: 'discount', width: '50%' },
                                    {
                                      title: 'Preço',
                                      dataIndex: 'price',
                                      key: 'price',
                                      width: '50%',
                                      render: (text, record) => (
                                        <Link
                                          onClick={() => {
                                            if (record.key === 'original') {
                                              dispatch({ type: 'REMOVE_MODIFY_PRICE', product });
                                            } else {
                                              const modifiedPrice = parseFloat(record.price.replace('R$', '').replace(',', '.'));
                                              dispatch({ type: 'MODIFY_PRICE', product, price_modified: modifiedPrice });
                                            }
                                          }}
                                        >
                                          {text}
                                        </Link>
                                      ),
                                    },
                                  ]}
                                  dataSource={[
                                    {
                                      key: 'original',
                                      discount: 'Original',
                                      price: `R$ ${parseFloat(product.product_price_sale ?? product.product_price).toFixed(2)}`,
                                    },
                                    {
                                      key: '5%',
                                      discount: '-5%',
                                      price: `R$ ${calculateDiscountedPrice(product, 5)}`,
                                    },
                                    {
                                      key: '10%',
                                      discount: '-10%',
                                      price: `R$ ${calculateDiscountedPrice(product, 10)}`,
                                    },
                                    {
                                      key: '15%',
                                      discount: '-15%',
                                      price: `R$ ${calculateDiscountedPrice(product, 15)}`,
                                    },
                                  ]}
                                  pagination={false}
                                />
                                <Link
                                  onClick={() => setEditPrice(product.id)}
                                  style={{ display: 'block', marginTop: 8 }}
                                >
                                  Outro Valor
                                </Link>
                              </>
                            }
                            trigger="click"
                            placement="topLeft"
                            onVisibleChange={(visible) => {
                              // Garantir que o Popover permaneça aberto para manipulação dos preços
                              if (!visible) {
                                setEditPrice(null);
                              }
                            }}
                          >
                            {'price_modified' in product ? (
                              <>
                                <Text delete>
                                  R$ {(product.product_price_sale || product.product_price).toFixed(2)}
                                </Text>
                                <br />
                                <Link>
                                  {`R$${parseFloat(product.price_modified).toFixed(2)} `}
                                </Link>
                              </>
                            ) : (
                              <Link>
                                R$ {parseFloat(getProductPrice(product, product.quantity)).toFixed(2)}
                              </Link>
                            )}
                          </Popover>
                          x {product.quantity} = R$ {parseFloat(product.quantity * getProductPrice(product, product.quantity)).toFixed(2)}
                        </div>
                      )
                    ) : (
                      <Text>
                        R$ {parseFloat(getProductPrice(product, product.quantity)).toFixed(2)} x {product.quantity} =
                        R$ {parseFloat(product.quantity * getProductPrice(product, product.quantity)).toFixed(2)}
                      </Text>
                    )}
                </span>
              </div>
              <QtyChangerCart
                addToCart={addToCart}
                product={product}
                quantity={product.quantity}
              />
            </div>
          </div>
          <DeleteOutlined
            onClick={() => {
              dispatch(removeFromCart(product));
              dispatch({ type: 'CART_UPDATED', updated: true });
              dispatch({ type: 'CART_RETURNED', returned: false });
            }}
            style={{ color: 'red' }}
          />
        </div>
      </List.Item >
    );
  }

  const updateDiscount = () => {
    const maxAllowedDiscount = logged === 'seller' ? 100 : sellerData.permissions.configuration?.max_discount_percentage;
    const discount = parseFloat(inputValue);

    if (isNaN(discount) || discount < 0) {
      message.error('Desconto não pode ser negativo ou inválido');
    } else if (discount <= maxAllowedDiscount) {
      dispatch({ type: 'SET_CART', cartAuxInfo: { sellerDiscountPercentage: discount } });
      dispatch({ type: 'CART_UPDATED', updated: true });
      dispatch({ type: 'CART_RETURNED', returned: false });
      setEditDiscount(false);
    } else {
      message.error(`Seu desconto máximo permitido é ${maxAllowedDiscount}%`);
    }
  };

  return (
    <div className={styles['order-summary']}>
      <div className={styles['summary-card']}>
        <div className={styles['product-list']}>
          <List
            itemLayout="vertical"
            dataSource={cartProducts}
            renderItem={(product) => (
              <CartRow product={product} />
            )}
            locale={{
              emptyText: <Empty description={
                <Text>
                  Seu carrinho está vazio
                </Text>
              } />,
            }}
          />
        </div>
        <Divider />
        {cartAuxInfo.sellerDiscountPercentage ?
          <div className={styles.order_total}>
            <div>
              Total dos produtos
            </div>
            <div>
              <Title style={{ margin: 0, padding: 0 }} level={5}>
                R$
                {parseFloat(productsTotal).toFixed(2)}
              </Title>
            </div>
          </div> : <></>}
        {(logged === 'seller' || (logged === 'sellerUser' && sellerData.permissions.configuration?.max_discount_percentage > 0)) && <>
          {!editDiscount &&
            <div className={styles.order_total}>
              <p>
                <Link onClick={() => setEditDiscount(true)}>
                  {cartAuxInfo.sellerDiscountPercentage ? <>Desconto (
                    <>-{cartAuxInfo.sellerDiscountPercentage}%</>
                    )</> : <>Adicionar desconto</>}
                </Link>
              </p>
              <div>
                <h4>
                  {cartAuxInfo.sellerDiscountPercentage ? <>- R$ {(cartAuxInfo.sellerDiscountPercentage / 100 * productsTotal).toFixed(2)}</> : <></>}
                </h4>
              </div>
            </div>}

          {editDiscount && <div className={styles.order_total}>
            <p><Input.Group style={{ display: "flex", justifyContent: "end" }} compact>
              <Select disabled style={{ width: "110px" }} size="small" defaultValue="Desconto" options={[
                {
                  value: 'discount',
                  label: 'Desconto',
                },
                // {
                //   value: 'add',
                //   label: 'Acréscimo',
                // },
              ]} />
              <Input
                size="small"
                type="number"
                pattern="\d*"
                style={{ width: '80px' }}
                suffix="%"
                value={inputValue}
                onChange={(e) => {
                  let value = e.target.value;
                  if (/^\d*\.?\d*$/.test(value)) {
                    value = value.replace(/^0+(?![\.|$])/, '');
                    setInputValue(value === '' ? '0' : value);
                  }
                }}
                onKeyDown={(e) => e.key === 'Enter' && updateDiscount()}
              />
              <Button size="small" type="primary" onClick={updateDiscount}><CheckOutlined /></Button>
            </Input.Group></p>
          </div>
          }
        </>}
        <Space direction="vertical">
          <Flex justify="space-between">
            <Title style={{ margin: 0, padding: 0 }} level={4}>Total</Title>
            <Title style={{ margin: 0, padding: 0 }} level={4}>
              R$
              {parseFloat(productsTotalAfterDiscount).toFixed(2)}
            </Title>
          </Flex>

          <Space style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }} direction="vertical">
            {failMessage && <Alert message={failMessage} type="info" />}
            <Button
              size="large"
              type="primary"
              disabled={!!failMessage}
              onClick={() => {
                if (!orderRebuy.orderRebuyId) {
                  failMessage == false && dispatch({ type: 'GO_CHECKOUT' });
                } else {
                  axiosClient
                    .post(
                      '/cart/order/update',
                      { orderId: orderRebuy.orderRebuyId, cart },
                      { withCredentials: true },
                    )
                    .then((response) => {
                      if (response.data.status === 'Success') {
                        dispatch({ type: 'RESET_REBUY' });
                        navigate(`/checkout/${orderRebuy.orderRebuyId}`);
                      }
                    });
                }
              }}
            >
              Finalizar Pedido
            </Button>
          </Space>
        </Space>
      </div>

      <div />
    </div>
  );
}
