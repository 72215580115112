function initialState() {
  return { searchTerm: '', categories: [], tags: [], filterType: 'all', onFocus: false };
}

export function searchState(state = initialState(), action) {
  switch (action.type) {
    case 'SET_PRODUCT_SEARCH':
      return {
        ...state,
        ...('searchTerm' in action && { searchTerm: action.searchTerm }),
        ...('categories' in action && { categories: action.categories }),
        ...('tags' in action && { tags: action.tags }),
        ...('filterType' in action && { filterType: action.filterType }),
      };
    case 'SEARCH_TERM':
      return { ...state, searchTerm: action.searchTerm };
    case 'SET_SEARCH_FOCUS':
      return { ...state, onFocus: action.onFocus };
    case 'CATEGORIES_VALUE':
      return { ...state, categories: action.categories };
    case 'TAGS_VALUE':
      return { ...state, tags: action.tags };
    case 'SET_FILTER_TYPE':
      return { ...state, filterType: action.filterType };
    default:
      return state;
  }
}
