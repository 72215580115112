import React, { useEffect, useRef, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Tag, Input, Button, Typography, Card, Alert, Skeleton, Drawer,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  PlusOutlined,
  HighlightOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { axiosClient } from '../../apiClient';

const { Title } = Typography;

export default function SellerListCustomerTag() {
  const navigate = useNavigate();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const inputRef = useRef(null);

  const renameTagGroup = (item, newTitle) => {
    setRenaming(true);
    axiosClient
      .post(
        '/customer/tag_group/rename',
        { groupId: item.id, newTitle },
        { withCredentials: true },
      )
      .then((response) => {
        setState(response.data.tags);
        setRenaming(false);
      });
  };

  useEffect(() => {
    axiosClient
      .get('/customer/tag/list', { withCredentials: true })
      .then((response) => {
        setState(response.data.tags);
        setLoading(false);
      });
  }, []);

  useEffect(() => { inputRef && inputRef.current?.focus(); }, [inputRef]);

  const forMap = (tag) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag.title}
      </Tag>
    );
    return (
      <span key={tag.id} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  };

  const handleClose = (removedTag) => {
    axiosClient
      .post(
        '/customer/tag/delete',
        { tagId: removedTag.id },
        { withCredentials: true },
      )
      .then((response) => {
        setState(response.data.tags);
      });
  };

  const showInput = (item) => {
    setState((state) =>
      state.map((group) =>
        group.id === item.id ? { ...group, inputVisible: true } : group
      )
    );
  };

  const handleInputChange = (item, e) => {
    const newItem = item;
    newItem.inputValue = e.target.value;
    const filteredGroups = state.filter((group) => group.id !== item.id);
    filteredGroups.push(newItem);
    setState(filteredGroups);
  };

  const handleInputConfirm = (item) => {
    if (item.inputValue) {
      axiosClient
        .post(
          '/customer/tag/create',
          { groupId: item.id, title: item.inputValue },
          { withCredentials: true },
        )
        .then((response) => {
          setState(response.data.tags);
        });
    } else {
      const newItem = item;
      newItem.inputVisible = false;
      const filteredGroups = state.filter((group) => group.id !== item.id);
      filteredGroups.push(newItem);
      setState(filteredGroups);
    }
  };

  const createTagGroup = () => {
    setLoading(true);
    const value = inputValue;
    setInputValue('');

    axiosClient
      .post(
        '/customer/tag_group/create',
        { title: value },
        { withCredentials: true },
      )
      .then((response) => {
        setState(response.data.tags);
        setDrawerVisible(false);
        setLoading(false);
      });
  };

  const deleteTagGroup = (item) => {
    setDeleting(true);
    axiosClient
      .post(
        '/customer/tag_group/delete',
        { groupId: item.id },
        { withCredentials: true },
      )
      .then((response) => {
        setState(response.data.tags);
        setDeleting(false);
      });
  };

  const onClose = () => {
    setDrawerVisible(false);
  };

  return (
    <>
      <PageHeader
        title="Tags de Clientes"
        onBack={() => navigate('/admin')}
        extra={(
          <Button onClick={() => setDrawerVisible(true)} type="primary">
            Adicionar Grupo de Tags
          </Button>
        )}
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {state.length === 0 && (
            <Card>
              <Alert
                message="Você ainda não tem tags cadastradas."
                type="info"
                showIcon
              />
            </Card>
          )}
          {state.map((item, i) => {
            const tagChild = item.tags.map(forMap);
            return (
              <div key={item.id}>
                <Card
                  size="small"
                  title={
                    renaming ? (
                      <Skeleton.Input style={{ width: 150 }} active />
                    ) : (
                      <Title
                        editable={{
                          icon: <HighlightOutlined />,
                          tooltip: 'Clique para renomear tag',
                          onChange: (newTitle) => renameTagGroup(item, newTitle),
                        }}
                        level={5}
                      >
                        {item.title}
                      </Title>
                    )
                  }
                  extra={
                    deleting ? (
                      <LoadingOutlined
                        style={{
                          display: 'block',
                          fontSize: '16px',
                          color: '#08c',
                        }}
                      />
                    ) : (
                      <DeleteOutlined
                        item={item}
                        onClick={(e) => deleteTagGroup(item)}
                        style={{
                          display: 'block',
                          fontSize: '16px',
                          color: '#08c',
                        }}
                      />
                    )
                  }
                >
                    {tagChild}
                  <br />
                  {item.inputVisible && (
                    <Input
                      ref={inputRef}
                      type="text"
                      size="small"
                      style={{ width: 78 }}
                      value={item.inputValue}
                      onChange={(e) => handleInputChange(item, e)}
                      onBlur={() => handleInputConfirm(item)}
                      onPressEnter={() => handleInputConfirm(item)}
                    />
                  )}
                  {!item.inputVisible && (
                    <Tag
                      onClick={() => showInput(item)}
                      style={{
                        background: '#fff',
                        borderStyle: 'dashed',
                      }}
                    >
                      <PlusOutlined />
                      {' '}
                      Nova Tag
                    </Tag>
                  )}
                </Card>
                <br />
              </div>
            );
          })}
        </>
      )}
      <Drawer
        title="Título do Grupo de Tags"
        placement="bottom"
        height={200}
        onClose={onClose}
        open={drawerVisible}
        extra={(
          <Button
            type="primary"
            onClick={() => createTagGroup()}
            loading={loading}
          >
            Salvar
          </Button>
        )}
      >
        <Input
          value={inputValue}
          onChange={(value) => setInputValue(value.target.value)}
          placeholder="Exemplo: Canais de venda"
          loading={loading}
          onKeyDown={(e) => {
            (e.code === 'Enter' || e.code === 'NumpadEnter')
              && createTagGroup();
          }}
        />
      </Drawer>
    </>
  );
}
