import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import styles from './qtychanger.module.css';

export default function QtyChangerCart({ addToCart, product }) {
  const dispatch = useDispatch();
  const cartProducts = useSelector((state) => state.cart.products);
  const pageQty = useSelector((state) => state.pageQty);

  const qtyInPage = pageQty[product.id] || 0;
  const qtyInCart = cartProducts.find((item) => item.id === product.id)?.quantity || 0;

  const updateCartQuantity = (delta) => {
    dispatch(addToCart(product, qtyInPage, qtyInCart, delta));
    dispatch({ type: 'CART_UPDATED', updated: true });
    dispatch({ type: 'CART_RETURNED', returned: false });
  };

  return (
    <div className={styles['pqt-geral']}>
      <Button
        size="small"
        onClick={() => updateCartQuantity(-1)}
        icon={<MinusOutlined />}
        disabled={qtyInCart <= 0}
      />
      <span className={styles.pqt}>{qtyInCart}</span>
      <Button
        size="small"
        onClick={() => updateCartQuantity(1)}
        icon={<PlusOutlined />}
        disabled={qtyInCart >= product.product_stock}
      />
    </div>
  );
}
