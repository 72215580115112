import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import NuApp from './App';
import store from './store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { App, ConfigProvider, theme } from 'antd';
import pt_BR from 'antd/lib/locale/pt_BR';

const ThemeProvider = ({ children }) => {
  const themeConfig = useSelector((state) => state.themeConfig);
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.lightAlgorithm,
        token: {
          ...(themeConfig.colorPrimary && { colorPrimary: themeConfig.colorPrimary }),
          ...(themeConfig.colorBgBase && { colorBgBase: themeConfig.colorBgBase }),
          ...(themeConfig.colorTextBase && { colorTextBase: themeConfig.colorTextBase }),
          ...(themeConfig.colorInfo && { colorInfo: themeConfig.colorInfo }),
        },
      }}
      locale={pt_BR}
    >
      {children}
    </ConfigProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <ThemeProvider>
          <App message={{
            duration: 4,
            maxCount: 2,
          }}>
            <NuApp />
          </App>
        </ThemeProvider>
      </Router>
    </React.StrictMode>
  </Provider>,
);

serviceWorkerRegistration.register();
reportWebVitals();
