function initialState() {
  return ({ shippingMethod: null, paymentMethod: null, observations: '' });
}

export function order(state = initialState(), action) {
  switch (action.type) {
    case 'SET_ORDER':
      return {
        ...state,
        ...('shippingMethod' in action && { shippingMethod: action.shippingMethod }),
        ...('paymentMethod' in action && { paymentMethod: action.paymentMethod }),
        ...('observations' in action && { observations: action.observations }),
      };
    case 'ADD_SHIPPING':
      return { ...state, shippingMethod: action.shippingMethod };
    case 'ADD_PAYMENT':
      return { ...state, paymentMethod: action.paymentMethod };
    default:
      return state;
  }
}
