import React, { useEffect, useState } from 'react';
import { App, Dropdown, DatePicker, Checkbox, Row, Col, Alert, Table, Radio, Form, Select, Button, Card, Input, InputNumber, notification, Space, Collapse, Typography, Skeleton } from 'antd';
import { ArrowLeftOutlined, QuestionCircleOutlined, DownOutlined } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';
import { useNavigate, useParams } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import ComponentBuyerCondition from '../ComponentBuyerCondition'
import ComponentProductCondition from '../ComponentProductCondition'
import { formatBuyer } from '../../functions/validation';


const { Option } = Select;
const { Text, Title, Link } = Typography;


const SellerCreateCampaign = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState([{ 'key': 1, 'quantidade': '', 'porcentagem': '' }]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const { campaignId } = useParams();

  const action = Form.useWatch('action', form);
  const promotionType = Form.useWatch('promotion_type', form);
  const valueOrPercentage = Form.useWatch('value_or_percentage', form);
  const cartOrProducts = Form.useWatch('cart_or_products', form);
  const firstPurchase = Form.useWatch('first_purchase', form);
  const setEndDate = Form.useWatch('set_end_date', form);
  const ordersAboveValue = Form.useWatch('orders_above_value', form);
  const qtyUniqueSkus = Form.useWatch('qty_unique_skus', form);

  useEffect(() => { !campaignId && setLoading(false) }, [campaignId])

  function formatCurrency(val) {
    if (val == null || isNaN(val)) {
      return 'R$ 0,00';
    }
    return val.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  useEffect(() => {
    campaignId && axiosClient
      .post('/rules/get', { ruleId: campaignId }, { withCredentials: true })
      .then((response) => {
        const rule = response.data.rule
        rule?.progressive_table && setDataSource(rule?.progressive_table);

        let ceps_data = rule.ceps_data;
        let ceps_start = '';
        if (ceps_data && ceps_data.ceps_start.length > 0) {
          ceps_start = `${ceps_data.ceps_start.join('*\r\n')}*`;
        }
        let ceps_full = '';
        if (ceps_data && ceps_data.ceps_full.length > 0) {
          ceps_full = ceps_data.ceps_full.join('\r\n');
        }
        let ceps_range = '';
        if (ceps_data && ceps_data.ceps_range.length > 0) {
          ceps_range = ceps_data.ceps_range
            .map((item) => item.join('...'))
            .join('\r\n');
        }
        ceps_data = [ceps_full, ceps_start, ceps_range]
          .filter(Boolean)
          .join('\r\n')

        form.setFieldsValue({
          ['buyerForm']: {
            'condition': rule.buyer_condition,
            'specific': rule.buyers?.map((buyer) => ({ value: buyer.id, label: formatBuyer(buyer) })),
            'tags': rule.buyers_tags?.map((tag) => ({ value: tag.id, label: tag.title })),
            'ceps': ceps_data,
          },
          ['productForm']: {
            'condition': rule.product_condition,
            'specific': rule.products?.map((product) => ({ value: product.id, label: product.product_name })),
            'tags': rule.products_tags?.map((tag) => ({ value: tag.id, label: tag.title })),
            'categories': rule.products_categories?.map((category) => ({ value: category.id, label: category.title })),
          },
          ...rule
        })
        setLoading(false)
      })
  }, [campaignId])

  const actionConfig = {
    "freeShipping": {
      conditions: [
        "orderValue",
        "cartItemCount",
        "firstPurchase"
      ]
    },
    "discount": {
      conditions: [
        "orderValue",
        "cartItemCount",
        "firstPurchase"
      ],
    },
    "progressiveDiscount": {
      conditions: [
      ],
    },
  };


  const handleAdd = () => {
    setDataSource([...dataSource, { key: dataSource.length + 1, quantidade: '', porcentagem: '' }]);
    setCount(count + 1);
  };

  const handleInputChange = (key, dataIndex, value) => {
    if (/^\d*$/.test(value) && (!value || parseInt(value, 10) <= 1000)) {
      setDataSource((prev) =>
        prev.map((item) => (item.key === key ? { ...item, [dataIndex]: value } : item))
      );
    }
  };

  const handleDeleteRow = (key) => {
    setDataSource(dataSource.filter(item => item.key !== key));
  };

  const columns = [
    {
      title: 'A partir de X Itens',
      dataIndex: 'quantidade',
      render: (_, record) => (
        <Input
          value={record.quantidade}
          onChange={(e) => handleInputChange(record.key, 'quantidade', e.target.value)}
        />
      ),
    },
    {
      title: 'Desconto',
      dataIndex: 'porcentagem',
      render: (_, record) => (
        <Input
          value={record.porcentagem}
          onChange={(e) => handleInputChange(record.key, 'porcentagem', e.target.value)}
          addonAfter="%"
        />
      ),
    },
    {
      title: 'Ação',
      render: (_, record) => (
        <Button onClick={() => handleDeleteRow(record.key)} type="link">
          Deletar
        </Button>
      ),
    },
  ];

  const handleSave = (values) => {

    if (action === 'progressiveDiscount' && (dataSource.length === 0 ||
      dataSource.some(
        (item) =>
          !(Number(item.quantidade) > 0) || !(Number(item.porcentagem) > 0)
      ))) {
      form.setFields([
        {
          name: 'progressive_table',
          errors: ['Adicione ao menos uma linha com valores.'],
        },
      ]);
      return;
    }

    const request = campaignId ? axiosClient.post('/rules/update', { ruleId: campaignId, ...values, progressive_table: dataSource }, { withCredentials: true })
      : axiosClient.post('/rules/create', { ...values, progressive_table: dataSource }, { withCredentials: true });

    request.then((response) => {
      response.data.status == 'Success' && message.success('Campanha salva com sucesso', 4)
      navigate('/admin/marketing/campanhas');
    })
  };

  const handleDelete = () => {
    campaignId && axiosClient.post('/rules/delete', { ruleId: campaignId }, { withCredentials: true })
      .then((response) => {
        response.data.status == 'Success' && message.success('Campanha excluída com sucesso', 4)
        navigate('/admin/marketing/campanhas');
      })
  };


  return (
    <Card
      styles={{ header: { paddingLeft: 8, paddingRight: 8 } }}
      title={
        <Space size={8} style={{ height: '100%' }}>
          <Button onClick={() => navigate('/admin/marketing/campanhas')} size='large' type="text" icon={<ArrowLeftOutlined />} />
          <Title level={4} style={{ margin: 0 }}>Cadastrar Campanha</Title>
        </Space>
      }
      extra={<Dropdown
        menu={{
          items: [{ key: '1', label: 'Excluir Campanha', onClick: handleDelete }],
        }}
        trigger={['click']}
      >
        <Button icon={<DownOutlined />} type="primary">Ações</Button>
      </Dropdown>}
    >
      {loading ? <Skeleton active /> :
        <Form form={form} scrollToFirstError layout="vertical" onFinish={handleSave}>
          <Space direction='vertical' style={{ width: '100%' }}>
            <Card>

              <Form.Item
                name='name'
                label="Nome da Campanha"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    max: 100,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item initialValue='automatic' name='promotion_type' label={<Space><Text>Forma da Campanha</Text><Link
                onClick={() => notification.open({
                  duration: 20,
                  placement: 'bottomRight',
                  message: <Title level={4}>Ajuda</Title>,
                  description:
                    'O modo Cupom requer um código do cliente, enquanto o Automático aplica o benefício automaticamente quando as condições são atendidas.',
                })}
              >
                <QuestionCircleOutlined />
              </Link></Space>}>
                <Radio.Group
                  options={[
                    {
                      label: 'Automático',
                      value: 'automatic',
                    },
                    {
                      label: 'Cupom',
                      value: 'coupon',
                    },
                  ]}
                  defaultValue="Apple"
                  optionType="button"
                  buttonStyle="solid"
                  disabled
                />
              </Form.Item>
              {promotionType === 'coupon' && (
                <Form.Item
                  name="coupon_code"
                  label="Código do Cupom"
                  onChange={(e) => {
                    const val = e.target.value
                      .replace(/[^a-zA-Z0-9]/g, "")
                      .toUpperCase()
                    form.setFields([
                      {
                        name: 'codigo',
                        value: val,
                      },
                    ])
                  }}
                  rules={[
                    {
                      type: "string",
                      required: true,
                      max: 20,
                      min: 3,
                      message: "Insira um código de 3 a 20 caracteres",
                    },
                  ]}
                >
                  <Input
                    placeholder="BLACKFRIDAY20"
                  />
                </Form.Item>
              )}

              <Form.Item name='action' label="Tipo de Campanha">
                <Select
                  placeholder="Escolha uma Ação"
                >
                  <Option value="freeShipping">Frete Grátis</Option>
                  <Option value="discount">Desconto</Option>
                  <Option value="progressiveDiscount">Desconto Progressivo</Option>
                </Select>
              </Form.Item>
              {action === 'fixedPrice' &&
                <>
                  <ComponentProductCondition
                    form={form}
                    label="Selecione os produtos"
                    showAllOption={false}
                  />
                  <Form.Item
                    name="new_product_price"
                    label="Novo Preço do Produto"
                    rules={[
                      {
                        required: true,
                      },
                    ]}

                  >
                    <Input />
                  </Form.Item>
                </>
              }
              {action == 'discount' &&
                <>
                  <Form.Item
                    name="discount_value"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input
                      prefix={valueOrPercentage === 'value' ? 'R$' : false}
                      suffix={valueOrPercentage === 'percent' ? '%' : false}
                      addonBefore={
                        <Form.Item name="value_or_percentage" initialValue='percent' noStyle>
                          <Select
                            style={{
                              width: 140,
                            }}
                          >
                            <Option value="value">Valor</Option>
                            <Option value="percent">Porcentagem</Option>
                          </Select>
                        </Form.Item>}
                      style={{
                        width: 280,
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="cart_or_products" label="Onde será aplicado">
                    <Select
                      style={{
                        width: 140,
                      }}
                    >
                      <Option value="cart">Carrinho</Option>
                      <Option value="products">Produtos</Option>
                    </Select>
                  </Form.Item>
                  {cartOrProducts == 'products' &&
                    <ComponentProductCondition
                      form={form}
                      label="Selecione os produtos"
                      showAllOption={false}
                    />
                  }
                </>

              }
              {action == 'progressiveDiscount' &&
                <Space style={{ width: '100%' }} direction='vertical'>
                  <Alert
                    closable
                    message="O que é Desconto Progressivo?"
                    description="Desconto progressivo é uma estratégia onde o cliente recebe maiores descontos ao atingir volumes de compra ou valores específicos, incentivando compras maiores."
                    type="info"
                    showIcon
                  />
                  <>
                    <ComponentProductCondition
                      form={form}
                      label="Selecione os produtos"
                      showAllOption={false}
                    />
                    <Form.Item name='progressive_table'>
                      <Space direction="vertical" style={{ width: '100%' }}>
                        {dataSource?.length > 0 && <Table
                          bordered
                          dataSource={dataSource}
                          columns={columns}
                          pagination={false}
                          rowKey="key"
                        />}
                        <Button onClick={handleAdd} type="primary" block>
                          Adicionar Quantidade e Valor
                        </Button>
                      </Space>
                    </Form.Item>
                  </>
                </Space>

              }
            </Card>

            {action && (
              <>
                <Card title="Para quais clientes">
                  <ComponentBuyerCondition
                    form={form}
                    label="Escolha os clientes para aplicar a regra"
                  />
                </Card>
                {actionConfig[action]?.conditions?.length > 0 && <Card title="Condições">
                  <Form.Item name="orders_above_value" valuePropName="checked">
                    <Checkbox >Compras Acima de um Valor Específico</Checkbox>
                  </Form.Item>
                  {ordersAboveValue && (<>
                    <Form.Item
                      name="order_value"
                      label="Valor do Pedido"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                      onChange={(e) => {
                        const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                        form.setFields([
                          {
                            name: 'order_value',
                            value: formatCurrency(val),
                          },
                        ]);
                      }}
                    >
                      <Input placeholder="Valor do pedido mínimo" />
                    </Form.Item>
                  </>
                  )}
                  <Form.Item name="qty_unique_skus" valuePropName="checked">
                    <Checkbox disabled>Quantidade de Itens Únicos no Carrinho (SKUs)</Checkbox>
                  </Form.Item>
                  {qtyUniqueSkus && (<>
                    <Form.Item
                      name="cart_item_count"
                      label="Quantidade"
                      rules={[
                        {
                          type: 'number',
                          required: true,
                          min: 1,
                        },
                      ]}

                    >
                      <InputNumber />
                    </Form.Item>
                  </>

                  )}
                  <Form.Item name="first_purchase" valuePropName="checked">
                    <Checkbox disabled>Primeira Compra do Cliente</Checkbox>
                  </Form.Item>
                  {firstPurchase && 'Apenas clientes que nunca compraram serão elegíveis.'}
                </Card>}
              </>
            )}
            <Card title="Período Ativo">
              <Form.Item
                name='start_date'
                label="Data de Início"
                layout='horizontal'
              >
                <DatePicker disabled placeholder='Data de Início' showTime />
              </Form.Item>
              <Form.Item name="set_end_date" valuePropName="checked">
                <Checkbox disabled>Definir data de término</Checkbox>
              </Form.Item>
              {setEndDate && <Form.Item
                name='end_date'
                label="Data de Término"
                layout='horizontal'
              >
                <DatePicker disabled placeholder='Data de Término' showTime />
              </Form.Item>}
            </Card>
            <Card title="Número máximo de usos do desconto">
              <Space direction='vertical' style={{ width: '100%' }}>
                <Form.Item name="set_max_utilization_limit" valuePropName="checked">
                  <Checkbox disabled>Limitar o número total de vezes que este desconto pode ser usado</Checkbox>
                </Form.Item>
                <Form.Item name="limit_one_per_user" valuePropName="checked">
                  <Checkbox disabled>Limitar para um uso por cliente</Checkbox>
                </Form.Item>
              </Space>
            </Card>
            <Form.Item>
              <div style={{ marginTop: 30 }}>
                <Row justify="center" gutter={[30, 12]}>
                  <Col xs={18} sm={8}>
                    <Button
                      block
                      onClick={() => {
                        navigate('/admin/marketing/campanhas');
                      }}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col xs={18} sm={8}>
                    <Button block type="primary" htmlType="submit">
                      {campaignId ? 'Salvar' : 'Cadastrar'}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </Space>
        </Form>
      }
    </Card >
  );
};

export default SellerCreateCampaign;
