import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'antd';
import { debounce } from 'lodash';
import { axiosClient } from '../../apiClient';

export default function SearchCustomer() {
  const dispatch = useDispatch();
  const buyerPagination = useSelector((state) => state.buyerPagination);
  const searchTermValue = useSelector(
    (state) => state.buyerSearchState.searchTerm,
  );

  const updateSearch = async (page, perPage, searchTerm, column, order, resetPage) => {
    try {
      dispatch({ type: 'SET_BUYERS', loading: true });
      const results = await axiosClient.post('/auth/search/buyer', {
        search: searchTerm,
        page: resetPage ? 1 : page,
        perPage,
        column,
        order,
      }, { withCredentials: true });
      dispatch({ type: 'SET_BUYERS', list: results.data.buyers, loading: false });
      dispatch({
        type: 'SET_BUYER_PAGINATION',
        totalPages: results.data.total_pages,
        actualPage: results.data.actual_page,
        column: results.data.column,
        order: results.data.order,
        pageUpdated: true,
      });
    } catch (error) { }
  };

  const updateSearchHandler = useCallback(debounce(updateSearch, 500), []);

  useEffect(() => {
    updateSearchHandler(
      buyerPagination.page,
      buyerPagination.perPage,
      searchTermValue,
      buyerPagination.column,
      buyerPagination.order,
      true,
    );
  }, [searchTermValue]);

  useEffect(() => {
    !buyerPagination.pageUpdated
      && updateSearchHandler(
        buyerPagination.page,
        buyerPagination.perPage,
        searchTermValue,
        buyerPagination.column,
        buyerPagination.order,
        false,
      );
  }, [buyerPagination.pageUpdated]);

  return (
    <Input
      defaultValue={searchTermValue}
      allowClear
      size="large"
      type="text"
      placeholder="Nome, documento, cidade, e-mail..."
      onChange={(e) => dispatch({ type: 'SET_BUYER_SEARCH_TERM', searchTerm: e.target.value })}
      onFocus={(e) => dispatch({ type: 'SET_BUYER_SEARCH_FOCUS', onFocus: true })}
      onBlur={(e) => dispatch({ type: 'SET_BUYER_SEARCH_FOCUS', onFocus: false })}
    />
  );
}
