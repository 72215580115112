import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  App, Form, Input, Radio, InputNumber, Modal, Table, Button, Skeleton, Empty, Space, notification,
  Typography,
} from 'antd';
import { axiosClient } from '../../apiClient';

const { Link } = Typography;

const SellerListPriceList = () => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [priceLists, setPriceLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const markupType = Form.useWatch('markupType', form);

  useEffect(() => {
    axiosClient
      .get('/config/price-list/list', { withCredentials: true })
      .then(({ data }) => {
        setPriceLists(data.price_lists);
        setLoading(false);
      });
  }, []);

  const handleSave = (values) => {
    const request = selectedRecordId
      ? axiosClient.post('/config/price-list/update', { priceListId: selectedRecordId, ...values }, { withCredentials: true })
      : axiosClient.post('/config/price-list/create', values, { withCredentials: true });

    request
      .then(() => {
        message.success('Operação realizada com sucesso', 4)
        setModalOpen(false);
        form.resetFields();
        setSelectedRecordId(null);
        return axiosClient.get('/config/price-list/list', { withCredentials: true });
      })
      .then(({ data }) => setPriceLists(data.price_lists));
  };

  const handleDelete = () => {
    if (!selectedRecordId) return;
    axiosClient
      .post('/config/price-list/delete', { priceListId: selectedRecordId }, { withCredentials: true })
      .then(() => {
        message.success('Lista de Preço excluida com sucesso', 4)
        setModalOpen(false);
        setSelectedRecordId(null);
        return axiosClient.get('/config/price-list/list', { withCredentials: true });
      })
      .then(({ data }) => setPriceLists(data.price_lists));
  };

  const columns = [
    {
      title: 'Nome',
      ellipsis: true,
      responsive: ['sm'],
      render: (record) => <Link>
        {' '}
        {record.name}
        {' '}
      </Link>,
    },
    {
      title: 'Tipo',
      responsive: ['sm'],
      render: (record) => (record.markup_type === 'decrescimo' ? 'Decrescimo' : 'Acrescimo'),
    },
    {
      title: 'Porcentagem',
      responsive: ['sm'],
      render: (record) => `${record.markup_type === 'decrescimo' ? '-' : '+'}${record.percentage}%`,
    },
    {
      title: 'Listas de Preço',
      render: (record) => (
        <>
          <Link>
            {record.name}
          </Link>
          <br />
          Tipo: {record.markup_type === 'decrescimo' ? 'Decrescimo' : 'Acrescimo'}<br />
          Porcentagem: {`${record.markup_type === 'decrescimo' ? '-' : '+'}${record.percentage}%`}
        </>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  return (
    <>
      <PageHeader
        onBack={() => navigate('/admin')}
        title="Lista de Preços"
        extra={
          <Button onClick={() => {
            setSelectedRecordId(null);
            form.resetFields();
            setModalOpen(true);
          }} type="primary">
            Criar Lista de Preço
          </Button>
        }
      />
      <Table
        style={{ cursor: !loading ? 'pointer' : 'auto' }}
        columns={columns}
        dataSource={priceLists}
        locale={{ emptyText: loading ? <Skeleton active /> : <Empty /> }}
        onRow={(record) => ({
          onClick: () => {
            setSelectedRecordId(record.id);
            setModalOpen(true);
            form.setFieldsValue({
              ...record,
              markupType: record.markup_type, // Confirme que markup_type existe
            });
          },
        })}
      />
      <Modal
        open={modalOpen}
        title={selectedRecordId ? 'Editar Lista de Preços' : 'Cadastrar Lista de Preços'}
        okText={selectedRecordId ? 'Salvar' : 'Cadastrar'}
        cancelText="Cancelar"
        onCancel={() => {
          setModalOpen(false);
          form.resetFields();
          setSelectedRecordId(null);
        }}
        onOk={() => form.submit()}
        footer={
          <>
            <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
            {selectedRecordId && (
              <Button danger onClick={handleDelete}>
                Excluir
              </Button>
            )}
            <Button type="primary" onClick={() => form.submit()}>
              {selectedRecordId ? 'Salvar' : 'Cadastrar'}
            </Button>
          </>
        }
      >
        <Form
          layout="vertical"
          form={form}
          name="form_in_modal"
          onFinish={handleSave}
        >
          <Form.Item name="name" label="Nome da Lista" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="markupType"
            label="Tipo de Alteração"
            rules={[{ required: true }]}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="acrescimo">Acréscimo</Radio.Button>
              <Radio.Button value="decrescimo">Decrescimo</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="percentage" label="Porcentagem" rules={[{ required: true }]}>
            <InputNumber
              min={0}
              max={form.getFieldValue('markupType') === 'decrescimo' ? 100 : 1000}
              prefix={form.getFieldValue('markupType') === 'decrescimo' ? '-' : '+'}
              style={{ width: '120px' }}
              addonAfter="%"
            />
          </Form.Item>
        </Form>
      </Modal >
    </>
  );
};

export default SellerListPriceList;
