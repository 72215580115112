import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Form, Select, Typography } from 'antd';
import { axiosClient } from '../../apiClient';
import { debounce } from 'lodash';
import { formatBuyer } from '../../functions/validation';

const { Option, OptGroup } = Select;
const { Text } = Typography;
const { TextArea } = Input;

export default function ComponentBuyerCondition({
  form,
  label,
  defaultOption = false,
  showAllOption = true }) {
  const dispatch = useDispatch();
  const [availableTags, setAvailableTags] = useState([]);

  const [buyerList, setBuyerList] = useState({
    loading: false,
    list: [],
  });

  const condition = Form.useWatch(['buyerForm', 'condition'], form);

  const fetchBuyers = (searchValue = '') => {
    setBuyerList({ loading: true, list: [] });
    axiosClient
      .post('/auth/search/buyer', { search: searchValue }, { withCredentials: true })
      .then((response) => setBuyerList({ loading: false, list: response.data.buyers || [] }))
      .catch(() => setBuyerList({ loading: false, list: [] }));
  };

  const debouncedSearch = useCallback(debounce(fetchBuyers, 500), []);

  useEffect(() => {
    fetchBuyers();
  }, []);

  useEffect(() => {
    axiosClient
      .get('/customer/tag/list', { withCredentials: true })
      .then((response) => {
        setAvailableTags(response.data.tags)
      });
  }, []);

  return (
    <>
      <Form.Item
        name={['buyerForm', 'condition']}
        rules={[{ required: true }]}
        label={label}
        initialValue={defaultOption}
      >
        <Select
          placeholder="Selecione os clientes"
          allowClear
        >
          {showAllOption && <Option value="all">Todos clientes</Option>}
          <Option value="specific">Clientes específicos</Option>
          <Option value="tags">Clientes que contém tag</Option>
          <Option value="ceps">Clientes com CEP específico</Option>
        </Select>
      </Form.Item>

      {condition === 'ceps' && (
        <>
          <Text>
            Defina um CEP por linha. CEPs que contenham curingas (exemplo: 170*) ou
            intervalos numéricos (exemplo: 17000000...17099999) também são suportados.
          </Text>
          <Form.Item
            name={['buyerForm', 'ceps']}
            rules={[{ required: true }]}
          >
            <TextArea rows={4} />
          </Form.Item>
        </>
      )}

      {condition === 'tags' && (
        <Form.Item
          name={['buyerForm', 'tags']}
          rules={[{ required: true }]}
        >
          <Select placeholder="Selecione as tags" mode="multiple" allowClear>
            {Object.values(availableTags).map((group) => (
              <OptGroup key={group.id} label={group.title}>
                {group.tags.map((tag) => (
                  <Option key={tag.id} value={tag.id}>
                    {tag.title}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
      )}

      {condition === 'specific' && (
        <Form.Item
          name={['buyerForm', 'specific']}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            mode="multiple"
            autoClearSearchValue={false}
            style={{ width: '100%' }}
            placeholder="Selecione os clientes"
            allowClear
            onSearch={debouncedSearch}
            loading={buyerList.loading}
            filterOption={false}
            notFoundContent={buyerList.loading ? <Spin size="small" /> : null}
          >
            {buyerList.loading
              ? null
              : buyerList.list.map((buyer) => (
                <Option key={buyer.id} value={buyer.id}>
                  {formatBuyer(buyer)}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};