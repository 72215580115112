export function applyBulkDiscount(rules, products) {
    rules.forEach(rule => {
        const productIds = rule?.products_ids || []
        const categoryIds = rule?.products_categories_ids || []
        const ruleTags = rule?.products_tags_ids || []
        products.forEach(product => {
            if (rule.action === "progressiveDiscount") {
                const appliesToProduct = rule.product_condition == 'all' || productIds.includes(product.id) || categoryIds.some(categoryId => product.categories_ids.includes(categoryId)) || ruleTags.some(tag => product.tags_ids.includes(tag))
                if (appliesToProduct) {
                    const progressiveTable = rule?.progressive_table || [];
                    const bulkPrices = {};

                    progressiveTable.forEach(entry => {
                        const quantity = parseInt(entry.quantidade, 10);
                        const discountPercentage = parseFloat(entry.porcentagem) / 100;

                        if (!isNaN(quantity) && !isNaN(discountPercentage)) {
                            const discountedPrice = parseFloat((product.product_price * (1 - discountPercentage)).toFixed(2));
                            bulkPrices[quantity] = Math.min(
                                ...[discountedPrice, product.product_price_sale].filter(price => price != null)
                            );;
                        }
                    });

                    if (Object.keys(bulkPrices).length > 0) {
                        product.product_price_bulk = bulkPrices;
                    }
                }
            }
        });
    });

    return products;
}