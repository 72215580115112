const INITIAL_STATE = {};

export function pageQty(state = INITIAL_STATE, action) {
  const { productId, quantity } = action;

  switch (action.type) {
    case 'CLEAR_QUANTITY':
      const { [productId]: _, ...rest } = state;
      return rest;

    case 'SET_QUANTITY':
      return {
        ...state,
        [productId]: quantity,
      };

    case 'INCREASE_QUANTITY':
      return {
        ...state,
        [productId]: (state[productId] || 0) + 1,
      };

    case 'DECREASE_QUANTITY':
      const newQty = (state[productId] || 0) - 1;
      if (newQty <= 0) {
        const { [productId]: __, ...remaining } = state;
        return remaining;
      }
      return {
        ...state,
        [productId]: newQty,
      };

    default:
      return state;
  }
}