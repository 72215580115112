import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Form } from 'antd';

import SellerIntegrations from '../SellerIntegrations';
import SellerSubscription from '../SellerSubscription';
import SellerProfile from '../SellerProfile';
import SellerListUser from '../SellerListUser';
import SellerCreateUser from '../SellerCreateUser';

const { TabPane } = Tabs;

export default function SellerConfiguration() {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('1');
  const [userPage, setUserPage] = useState('listUser');
  const [userType, setUserType] = useState('representante_simples');
  const [userForm] = Form.useForm();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has('code') && setTab('4');
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.has('listusers') && setTab('2');
  }, []);

  return (
    <>
      <Tabs
        onChange={(tabValue) => {
          setTab(tabValue);
          dispatch({ type: 'USER_EDIT', userId: null });
          setUserPage('listUser');
          userForm.resetFields();
        }}
        activeKey={tab}
        size="large"
        centered
      >
        <TabPane tab="Dados Gerais" key="1">
          <SellerProfile />
        </TabPane>
        <TabPane tab="Representantes" key="2">
          {userPage === 'listUser' && (
            <SellerListUser setUserPage={setUserPage} setUserType={setUserType} />
          )}
          {userPage === 'createUser' && (
            <SellerCreateUser setUserPage={setUserPage} form={userForm} userType={userType} />
          )}
        </TabPane>
        <TabPane tab="Assinatura" key="3">
          <SellerSubscription />
        </TabPane>
        <TabPane tab="Integrações" key="4">
          <SellerIntegrations />
        </TabPane>
      </Tabs>
    </>
  );
}
