import React, { useState } from 'react';
import {
  App, Button, Drawer, Form, Input, Row, Col,
} from 'antd';
import { axiosClient } from '../../apiClient';

export default function SellerChangePass(props) {
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    axiosClient
      .post(
        '/auth/seller/change-password',
        {
          old_password: values.senha_atual,
          new_password: values.nova_senha,
        },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.msg.includes('Wrong password')) {
          form.setFields([
            {
              name: 'senha_atual',
              errors: ['Senha Incorreta'],
            },
          ]);
        } else if (response.data.status == 'Success') {
          message.success('Senha alterada com sucesso');
          props.drawerSetter(false);
        }
        setLoading(false);
      });
  };

  return (
    <Drawer
      title="Trocar senha"
      placement="bottom"
      height={window.innerWidth > 575 ? '250px' : '350px'}
      open={props.drawer}
      onClose={() => props.drawerSetter(false)}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['senha_atual']}
              label="Senha Atual"
              rules={[
                {
                  required: true,
                  message: 'Insira a senha atual',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['nova_senha']}
              label="Nova Senha"
              rules={[
                {
                  required: true,
                  message: 'Insira a nova senha',
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" gutter={[30, 12]}>
          <Col xs={18} sm={8}>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Trocar Senha
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </Drawer>
  );
}
