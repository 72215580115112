import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  App,
  Steps,
  Button,
  Layout,
  Row,
  Space,
  Form,
  Upload,
  Modal,
  Col,
  Input,
  Typography,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';
import styles from './onboarding.module.css';
import PagesFooter from '../PagesFooter';

const { Content } = Layout;
const { Step } = Steps;
const { Title } = Typography;
const { Search } = Input;

export default function Onboarding() {
  const { message } = App.useApp();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const sellerData = useSelector((state) => state.sellerData);
  const [loading, setLoading] = useState(false);

  const [uploadData, setUploadData] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  });

  const steps = [
    {
      title: 'Seu Logotipo',
    },
    {
      title: 'Endereço de Envio',
    },
    {
      title: 'Link do Catálogo',
    },
  ];

  function getAddress(cep) {
    axiosClient
      .post(
        '/shipping/address-from-cep',
        { func: 'consultarCep', data: cep },
        { withCredentials: true },
      )

      .then((response) => {
        const data = response.data.response;
        data.municipio = data.localidade;
        data.endereco = data.logradouro;
        form.setFieldsValue(data);
      });
  }

  const handleCancel = () => setUploadData({ ...uploadData, previewVisible: false });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onFinish = async () => {
    const values = form.getFieldsValue();
    setLoading(true);
    const { fileList } = uploadData;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].url === undefined) {
        fileList[i].base64 = await getBase64(fileList[i].originFileObj);
      }
    }

    if (values.username) {
      axiosClient
        .post(
          '/auth/update/seller',
          { ...values, fileList },
          { withCredentials: true },
        )
        .then((response) => {
          if (response.data.in_use) {
            if (response.data.in_use.username) {
              form.setFields([
                {
                  name: 'username',
                  errors: [
                    'Este username já está sendo utilizado, escolha outro.',
                  ],
                },
              ]);
            }
            if (response.data.in_use.email) {
              form.setFields([
                {
                  name: 'email',
                  errors: [
                    'Este email já está sendo utilizado, escolha outro.',
                  ],
                },
              ]);
            }
          } else {
            message.success('Dados salvos com sucesso', 4);
            window.location.reload();
          }
          setLoading(false);
        });
    } else {
      form.setFields([
        {
          name: 'username',
          errors: ['Username obrigatório.'],
        },
      ]);
      setLoading(false);
    }
  };

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const handleChange = ({ fileList }) => {
    setUploadData({ ...uploadData, fileList });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setUploadData({
      ...uploadData,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    (
      <Layout className={styles.layout}>
        {/* <Navbar /> */}
        <Content style={{ marginTop: '70px', padding: '0 10px' }}>
          <div className={styles['site-layout-content']}>
            <Title
              style={{ padding: '20px', textAlign: 'center' }}
              type="default"
            >
              Vamos configurar seu catálogo...
            </Title>
            <Steps responsive current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div className={styles['steps-content']}>
              <Form
                form={form}
                scrollToFirstError
                {...layout}
                name="nest-messages"
                onFinish={onFinish}
                initialValues={sellerData}
              >
                <div style={{ display: current === 0 ? 'block' : 'none' }}>
                  <Title
                    style={{ padding: '20px', textAlign: 'center' }}
                    level={4}
                  >
                    Selecione o logotipo da sua empresa
                  </Title>
                  <Form.Item
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                  >
                    <Upload
                      className={styles['upload-list-inline']}
                      name="logo"
                      listType="picture"
                      maxCount={1}
                      fileList={uploadData.fileList}
                      onPreview={handlePreview}
                      onChange={handleChange}
                      beforeUpload={() => false}
                    >
                      <Button icon={<UploadOutlined />}>
                        Procurar arquivo
                      </Button>
                    </Upload>
                    <Modal
                      open={uploadData.previewVisible}
                      title={uploadData.previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={uploadData.previewImage}
                      />
                    </Modal>
                  </Form.Item>
                </div>
                <div style={{ display: current === 1 ? 'block' : 'none' }}>
                  <Title
                    style={{ padding: '20px', textAlign: 'center' }}
                    level={4}
                  >
                    Endereço de onde seus produtos serão enviados
                  </Title>
                  <Row gutter={16}>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        name={['cep']}
                        label="CEP"
                        // onChange={() => }

                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Search onSearch={(cep) => getAddress(cep)} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Form.Item
                        name={['municipio']}
                        label="Município"
                        // onChange={() => }

                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        name={['uf']}
                        label="UF"
                        // onChange={() => }

                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name={['endereco']}
                        label="Endereço"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} sm={8}>
                      <Form.Item
                        name={['bairro']}
                        label="Bairro"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        name={['numero']}
                        label="Número"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={10}>
                      <Form.Item
                        name={['complemento']}
                        label="Complemento"
                        // onChange={() => }

                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div style={{ display: current === 2 ? 'block' : 'none' }}>
                  <Title
                    style={{ padding: '20px', textAlign: 'center' }}
                    level={4}
                  >
                    Selecione o endereço do seu catálogo
                  </Title>
                  <Form.Item
                    name={['username']}
                    rules={[
                      {
                        required: true,
                        message: 'Escolha um username',
                      },
                      {
                        message: 'Máximo de 20 letras',
                        max: 20,
                      },
                    ]}
                  >
                    <Input
                      style={{ width: '300px' }}
                      addonBefore="www.nupedido.com.br/"
                      suffix=""
                      onChange={(e) => {
                        form.setFieldsValue({
                          username: e.target.value
                            .normalize('NFD')
                            .replace(/\p{Diacritic}/gu, '')
                            .replace(/[^A-Z0-9]+/gi, '_')
                            .toLowerCase(),
                        });
                      }}
                    />
                  </Form.Item>
                </div>
              </Form>
            </div>
            <Row align="middle" justify="center">
              <Space>
                {current > 0 && <Button onClick={() => prev()}>Anterior</Button>}
                {current < steps.length - 1 && (
                  <Button type="primary" onClick={() => next()}>
                    Próximo
                  </Button>
                )}
                {current === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      onFinish();
                    }}
                    loading={loading}
                  >
                    Finalizar
                  </Button>
                )}
              </Space>
            </Row>
          </div>
        </Content>
        <PagesFooter />
      </Layout>
    )
  );
}
