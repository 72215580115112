function initialState() {
  return { quantity: 0 };
}

export function accessRequests(state = initialState(), action) {
  switch (action.type) {
    case 'SET_ACCESS_REQUEST':
      return { ...state, quantity: action.quantity };
    default:
      return state;
  }
}
