import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  UserOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  KeyOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  HomeOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  App,
  Form,
  Button,
  Select,
  Alert,
  Typography,
  Drawer,
  Row,
  Col,
  Tooltip,
  Divider,
  Input,
  Switch,
  Space,
  Skeleton,
  Progress,
  Result,
  Flex,
  Tag,
  theme
} from 'antd';
import { useNavigate } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
import { openDB } from 'idb';
import { axiosClient } from '../../apiClient';
import {
  mascaraPhone,
  mascaraCnpj,
  mascaraCpf,
  validaCpfCnpj,
} from '../../functions/validation';
import SearchCustomerView from '../SearchCustomerView';

const { Option, OptGroup } = Select;
const { Text, Link } = Typography;
const { useToken } = theme;


function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function SellerBuyerView() {
  const { message } = App.useApp();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { token } = useToken();
  const navigate = useNavigate();

  const [applyLoading, setApplyLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [createCustomer, setCreateCustomer] = useState(false);
  const [sending, setSending] = useState(false);
  const [tipoPessoa, setTipoPessoa] = useState('pj');
  const [changePass, setChangePass] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [searching, setSearching] = useState(false);
  const [width, height] = useWindowSize();
  const [selectedSellerUser, setSelectedSellerUser] = useState({
    value: null,
  });
  const [sellerUsers, setSellerUsers] = useState(null);

  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);
  const syncData = useSelector((state) => state.sync);
  const bills = useSelector((state) => state.bills.bills);


  const states = [
    { nome: 'Acre', sigla: 'AC' },
    { nome: 'Alagoas', sigla: 'AL' },
    { nome: 'Amapá', sigla: 'AP' },
    { nome: 'Amazonas', sigla: 'AM' },
    { nome: 'Bahia', sigla: 'BA' },
    { nome: 'Ceará', sigla: 'CE' },
    { nome: 'Distrito Federal', sigla: 'DF' },
    { nome: 'Espírito Santo', sigla: 'ES' },
    { nome: 'Goiás', sigla: 'GO' },
    { nome: 'Maranhão', sigla: 'MA' },
    { nome: 'Mato Grosso', sigla: 'MT' },
    { nome: 'Mato Grosso do Sul', sigla: 'MS' },
    { nome: 'Minas Gerais', sigla: 'MG' },
    { nome: 'Pará', sigla: 'PA' },
    { nome: 'Paraíba', sigla: 'PB' },
    { nome: 'Paraná', sigla: 'PR' },
    { nome: 'Pernambuco', sigla: 'PE' },
    { nome: 'Piauí', sigla: 'PI' },
    { nome: 'Rio de Janeiro', sigla: 'RJ' },
    { nome: 'Rio Grande do Norte', sigla: 'RN' },
    { nome: 'Rio Grande do Sul', sigla: 'RS' },
    { nome: 'Rondônia', sigla: 'RO' },
    { nome: 'Roraima', sigla: 'RR' },
    { nome: 'Santa Catarina', sigla: 'SC' },
    { nome: 'São Paulo', sigla: 'SP' },
    { nome: 'Sergipe', sigla: 'SE' },
    { nome: 'Tocantins', sigla: 'TO' },
  ];

  useEffect(() => {
    axiosClient
      .get('/customer/tag/list', { withCredentials: true })
      .then((out) => {
        setAvailableTags(out.data.tags);
      });
  }, []);

  function getAddress() {
    setSearching(true);
    axiosClient
      .post(
        '/shipping/address-from-cep',
        { func: 'consultarCep', data: form.getFieldValue('cep') },
        { withCredentials: true },
      )
      .then((response) => {
        const data = response.data.response;
        data.municipio = data.localidade;
        data.endereco = data.logradouro;
        data.cep = data.cep.replace(/\D/g, '');
        delete data.complemento;
        form.setFieldsValue(data);
        dispatch({ type: 'ACTIVE_CEP', value: data.cep, uf: data.uf });
        setSearching(false);
      })
      .catch((error) => {
        const data = {};
        data.municipio = '';
        data.endereco = '';
        data.cep = '';
        delete data.complemento;
        form.setFieldsValue(data);
        dispatch({ type: 'ACTIVE_CEP', value: '', uf: '' });
        message.warning(
          'CEP Inválido',
        );
        setSearching(false);
      });
  }

  const onClose = () => {
    setDrawerVisible(false);
    setCreateCustomer(false);
    form.resetFields();
  };

  const handleSelectChange = (value) => {
    setSelectedUserId(value);
    form.setFieldsValue({ buyer: value });
  };

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  function handleChange(values) {
    if (values.length > 1) {
      const [groupId, tagId] = values[values.length - 1].split('_');

      for (let i = 0; i < values.length - 1; i++) {
        if (values[i].startsWith(groupId)) {
          values.splice(i, 1);
        }
      }
    }
    form.setFieldsValue({ tags: values });
  }

  const validarCPF = (e) => {
    const cpf = mascaraCpf(e.target.value);
    if (validaCpfCnpj(cpf) === false) {
      form.setFields([
        {
          name: 'cpf',
          errors: ['CPF Inválido'],
        },
      ]);
    }
  };

  const validarCNPJ = (e) => {
    const cnpj = mascaraCnpj(e.target.value);
    if (validaCpfCnpj(cnpj) === false) {
      form.setFields([
        {
          name: 'cnpj',
          errors: ['CNPJ Inválido'],
        },
      ]);
    } else {
      axiosClient
        .post('/consult-cnpj',
          { cnpj },
          { withCredentials: true })
        .then(({ data }) => {
          form.setFieldsValue({
            nome_fantasia: data.fantasia,
            razao_social: data.nome,
            cnpj: data.cnpj,
            email: data.email,
            phone: data.telefone,
            cep: data.cep.replace(/\D/g, ''),
            municipio: data.municipio,
            uf: data.uf,
            endereco: data.logradouro,
            bairro: data.bairro,
            numero: data.numero,
            complemento: data.complemento,
          });
        })
    }
  }

  useEffect(() => {
    (logged === 'seller' || logged === 'sellerUser')
      && axiosClient
        .get('/auth/list/seller-users', { withCredentials: true })
        .then((response) => {
          setSellerUsers(
            response.data.map((value) => ({
              value: value.id,
              label: value.nome,
            })),
          );
        });
  }, []);

  const onFinish = (values) => {
    setSending(true);
    axiosClient
      .post('/auth/register/buyer',
        { ...values, selectedSellerUser: selectedSellerUser.value },
        { withCredentials: true })
      .then((response) => {
        if (response.data.status === 'Success') {
          sendBuyerView(response.data.buyer.id);
          message.success('Cliente adicionado com sucesso', 4);
          setCreateCustomer(false);
          form.resetFields();
        } else {
          response.data.msg === 'User already exists'
            ? message.error('Usuário já cadastrado', 4)
            : message.error('Erro ao salvar dados', 4);
        }
        setSending(false);
      });
  };

  const sendBuyerView = async (buyerId) => {
    const db = await openDB('nupedido', 1);
    if (buyerId) {
      setApplyLoading(true);
      if (syncData.onlineStatus) {
        axiosClient
          .post(
            '/cart/set-buyer-view',
            {
              buyerViewId: buyerId,
            },
            {
              withCredentials: true,
            },
          )
          .then((response) => {
            dispatch({
              type: 'SET_BUYER_VIEW',
              buyerView: response.data.buyer_view,
            });
            setDrawerVisible(false);
            setApplyLoading(false);
          });
      } else {
        if (syncData.syncStatus === 'synced') {
          const buyer = await db.get('buyers', buyerId);
          db.put('config', { id: 2, buyerView: buyer });
          dispatch({
            type: 'SET_BUYER_VIEW',
            buyerView: buyer,
          });
        }

        setDrawerVisible(false);
        setApplyLoading(false);
      }
    }
  };

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={() => dispatch({ type: 'SET_SYNC', syncDrawer: true })}>
        {syncData.syncStatus === 'syncing' ? (
          <Tag
            style={{
              position: 'fixed', zIndex: '100', left: '3px', bottom: '3px',
            }}
            icon={<SyncOutlined spin />}
            color="processing"
          >
            Sincronizando
          </Tag>
        )
          : syncData.onlineStatus
            ? (
              <Tag
                style={{
                  position: 'fixed', zIndex: '100', left: '3px', bottom: '3px',
                }}
                icon={<CheckCircleOutlined />}
                color="success"
              >
                Online
              </Tag>
            )
            : (
              <Tag
                style={{
                  position: 'fixed', zIndex: '100', left: '3px', bottom: '3px',
                }}
                icon={<ExclamationCircleOutlined />}
                color="warning"
              >
                Offline
              </Tag>
            )}
      </div>
      <div
        style={{
          left: '0px',
          top: '0px',
          height: '50px',
          backgroundColor: token.colorBgBase,
          padding: '5px',
          position: 'fixed',
          width: '100%',
          zIndex: 10,
        }}
      >
        <Alert
          style={{
            height: '100%',
            padding: '5px',
          }}
          message={
            buyerView && (
              <Marquee speed={40} gradient={false}>
                Comprando como &nbsp;
                <Link style={{ maxWidth: 500 }} ellipsis>
                  {buyerView.tipo_pessoa === 'pf'
                    ? buyerView.nome_fantasia || buyerView.nome
                    : buyerView.nome_fantasia || buyerView.razao_social}
                </Link>
                &nbsp; (
                {buyerView.tipo_pessoa === 'pf'
                  ? mascaraCpf(buyerView.cpf)
                  : mascaraCnpj(buyerView.cnpj)}
                ). &nbsp;
                <Tag
                  color={
                    bills === null
                      ? 'default'
                      : bills.some(bill => bill.situacao === 'atrasado')
                        ? 'red'
                        : bills.some(bill => bill.situacao === 'aberto')
                          ? 'orange'
                          : 'green'
                  }
                >
                  {bills === null
                    ? 'Não verificado'
                    : bills.some(bill => bill.situacao === 'atrasado')
                      ? 'Contas atrasadas'
                      : bills.some(bill => bill.situacao === 'aberto')
                        ? 'Contas em aberto'
                        : 'Em dia'}
                </Tag>
              </Marquee>
            )
          }
          type="info"
          action={(
            <>
              <Button onClick={() => setDrawerVisible(true)} icon={<UserOutlined />} type="dashed">
                Cliente
              </Button>
              {/* <Button onClick={() => dispatch({ type: 'SET_SYNC', syncDrawer: true })} style={{ marginLeft: '5px' }} icon={syncData.syncStatus === 'synced' ? <Text type="success"><CheckOutlined /></Text> : syncData.syncStatus === 'syncing' ? <Link><LoadingOutlined /></Link> : <Text type="danger"><ApiOutlined /></Text>} /> */}
              <Button color='primary' variant='outlined' onClick={() => navigate('/admin')} style={{ marginLeft: '5px' }} icon={<HomeOutlined />} />
            </>
          )}
        />

      </div>

      <Drawer
        title="Sincronizar Dados"
        placement="right"
        width={window.innerWidth > 375 ? 375 : '100%'}
        onClose={() => dispatch({ type: 'SET_SYNC', syncDrawer: false })}
        open={syncData.syncDrawer}
      >
        {syncData.syncStatus === 'disabled' && (
          <Result
            status="warning"
            title="Ative a sincronização para realizar pedidos offline."
            extra={(
              <Button disabled type="primary" key="console" onClick={() => dispatch({ type: 'SET_SYNC', syncStatus: 'checking' })}>
                Ativar Sincronização
              </Button>
            )}
          />
        )}
        {syncData.syncStatus === 'syncing' && (
          <Flex align="center" justify="space-between" vertical>
            <Divider orientation="left">
              Produtos
              {(syncData.syncStep === 'idsNotInFrontend' && syncData.syncStore === 'products') ? '- Baixando novos dados' : syncData.syncStep === 'idsOutdated' ? '- Atualizando existentes' : syncData.syncStep === 'delete' && '- Excluindo antigos'}
            </Divider>
            <Progress percent={syncData.productSynced ? 100 : (syncData.syncStore === 'products' && (syncData.syncIterationTotal) ? ((syncData.syncIterationStep / syncData.syncIterationTotal) * 100).toFixed(2) : 0)} />
            <Divider orientation="left">
              Clientes
              {(syncData.syncStep === 'idsNotInFrontend' && syncData.syncStore === 'buyers') ? '- Baixando novos dados' : syncData.syncStep === 'idsOutdated' ? '- Atualizando existentes' : syncData.syncStep === 'delete' && '- Excluindo antigos'}
            </Divider>
            <Progress percent={syncData.buyerSynced ? 100 : (syncData.syncStore === 'buyers' && (syncData.syncIterationTotal) ? ((syncData.syncIterationStep / syncData.syncIterationTotal) * 100).toFixed(2) : 0)} />
            <Divider orientation="left">
              Carrinhos
              {(syncData.syncStep === 'idsNotInFrontend' && syncData.syncStore === 'carts') ? '- Baixando novos dados' : syncData.syncStep === 'idsOutdated' ? '- Atualizando existentes' : syncData.syncStep === 'delete' ? '- Excluindo antigos' : syncData.syncStep === 'idsNeedSubmit' && '- Enviando'}
            </Divider>
            <Progress percent={syncData.cartSynced ? 100 : (syncData.syncStore === 'carts' && (syncData.syncIterationTotal) ? ((syncData.syncIterationStep / syncData.syncIterationTotal) * 100).toFixed(2) : 0)} />
            <Button
              danger
              style={{ marginTop: '50px' }}
              key="console"
              onClick={() => {
                dispatch({ type: 'SET_SYNC_RESET' });
              }}
            >
              Parar Sincronização
            </Button>
          </Flex>
        )}

        {syncData.syncStatus === 'checking' && (
          <Result
            icon={<LoadingOutlined />}
            title="Checando por atualizações..."
          />
        )}
        {syncData.syncStatus === 'synced' && (
          <Result
            status="success"
            title="Seu aplicativo está sincronizado e pronto para ser utilizado offline."
            extra={(
              <Button danger key="console" onClick={() => dispatch({ type: 'SET_SYNC_RESET' })}>
                Parar Sincronização
              </Button>
            )}
          />
        )}

      </Drawer>
      <Drawer
        title={createCustomer ? 'Cadastrar cliente' : 'Selecionar Cliente'}
        placement="right"
        width={width > 991 ? '50%' : '100%'}
        onClose={onClose}
        open={drawerVisible}
      >
        {createCustomer ? (
          <Form
            form={form}
            scrollToFirstError
            {...layout}
            name="nest-messages"
            onFinish={onFinish}
            initialValues={{
              tipo_pessoa: 'pj',
            }}
          >
            <Divider orientation="left">Vendedor</Divider>
            <Space direction="vertical" style={{ width: '100%' }}>
              {selectedSellerUser.value === null && (
                <Alert message="Selecione um vendedor." type="error" showIcon />
              )}
              <Select
                value={selectedSellerUser}
                style={{ width: '330px' }}
                onChange={(value, data) => {
                  setSelectedSellerUser(data);
                }}
                options={sellerUsers}
              />
            </Space>
            <Divider orientation="left">Dados gerais</Divider>
            <Form.Item
              name="tipo_pessoa"
              label="Tipo de Pessoa"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Selecione o tipo de pessoa"
                onChange={(value) => setTipoPessoa(value)}
                allowClear
              >
                <Option value="pj">Pessoa Jurídica</Option>
                <Option value="pf">Pessoa Física</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name={['nome_fantasia']}
              label="Nome Fantasia"
              rules={[
                {
                  type: 'string',
                  required: tipoPessoa === 'pj' ? true : false,
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>

            {tipoPessoa === 'pf' && (
              <>
                <Form.Item
                  name={['nome']}
                  label="Nome do Responsável"
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name={['cpf']}
                      label="CPF"
                      rules={[
                        {
                          type: 'string',
                          max: 100,
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        onBlur={validarCPF}
                        maxLength={14}
                        onChange={(e) => {
                          form.setFieldsValue({
                            cpf: mascaraCpf(e.target.value),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name={['rg']}
                      label="RG"
                      rules={[
                        {
                          type: 'string',
                          max: 100,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {tipoPessoa === 'pj' && (
              <>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name={['cnpj']}
                      label="CNPJ"
                      rules={[
                        {
                          required: true,
                          type: 'string',
                          max: 100,
                        },
                      ]}
                    >
                      <Input
                        onBlur={validarCNPJ}
                        maxLength={18}
                        onChange={(e) => {
                          form.setFieldsValue({
                            cnpj: mascaraCnpj(e.target.value),
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name={['razao_social']}
                      label="Razão Social"
                      rules={[
                        {
                          type: 'string',
                          required: true,
                          max: 100,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      name={['ie']}
                      label="Inscrição Estadual"
                      rules={[
                        {
                          type: 'string',
                          max: 100,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row gutter={6}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['email']}
                  label="Email"
                  rules={[
                    {
                      type: 'email',
                      // required: true,
                      max: 100,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={['phone']}
                  label="Telefone"
                  rules={[
                    {
                      type: 'string',
                      // required: true,
                      max: 100,
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        phone: mascaraPhone(e),
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">Tags</Divider>
            <Form.Item name={['tags']}>
              <Select placeholder="Selecione as tags" mode="multiple" allowClear>
                {Object.values(availableTags).map((group) => (
                  <OptGroup key={group.id} label={group.title}>
                    {group.tags.map((tag) => (
                      <Option key={tag.id} value={tag.id}>
                        {tag.title}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>

            <Divider orientation="left">Endereço</Divider>
            {searching === false
              ? (
                <>
                  <Row gutter={16}>
                    <Col xs={24} sm={6}>
                      <Form.Item
                        name={['cep']}
                        label="CEP"
                        rules={[
                          { required: true, max: 8 },
                          {
                            type: 'string',
                          },
                        ]}
                        onChange={(e) => {
                          const cep_value = e.target.value.replace(/\D/g, '').substring(0, 8);
                          form.setFieldsValue({
                            cep: cep_value,
                          });
                          if (cep_value.length === 8) {
                            getAddress();
                          }
                        }}
                        onBlur={(e) => {
                          const cep_value = e.target.value.replace(/\D/g, '').substring(0, 8);
                          form.setFieldsValue({
                            cep: cep_value,
                          });
                          getAddress();
                        }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12}>
                      <Form.Item
                        name={['municipio']}
                        label="Município"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={12} sm={12}>
                      <Form.Item
                        name={['uf']}
                        label="UF"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Select disabled>
                          {states.map((state) => (
                            <Option value={state.sigla}>
                              {state.sigla}
                              {' '}
                              -
                              {state.nome}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col span={24}>
                      <Form.Item
                        name={['endereco']}
                        label="Endereço"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col sm={24}>
                      <Form.Item
                        name={['bairro']}
                        label="Bairro"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={10}>
                      <Form.Item
                        name={['numero']}
                        label="Número"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={14}>
                      <Form.Item
                        name={['complemento']}
                        label="Complemento"
                        rules={[
                          {
                            type: 'string',
                            max: 100,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )
              : <Skeleton />}
            <Divider orientation="left">Senha</Divider>
            <Button
              type="link"
              style={{ display: changePass ? 'none' : 'block' }}
              onClick={() => setChangePass(true)}
            >
              Mudar senha
            </Button>
            {changePass && (
              <>
                <Space align="baseline">
                  <Form.Item
                    name={['password']}
                    rules={[
                      {
                        type: 'string',
                        max: 100,
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ width: '200px' }}
                      placeholder="Senha"
                      iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Tooltip title="Gerar senha aleatória">
                      <Button
                        type="primary"
                        onClick={() => {
                          form.setFieldsValue({
                            password: Math.floor(
                              1000 + Math.random() * 9000,
                            ).toString(),
                          });
                        }}
                      >
                        <KeyOutlined />
                      </Button>
                    </Tooltip>
                  </Form.Item>
                </Space>
                <Form.Item name={['send_email']}>
                  Enviar senha por email?
                  {' '}
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    onChange={(checked) => {
                      form.setFieldsValue({
                        send_email: checked,
                      });
                    }}
                  />
                </Form.Item>
              </>
            )}
            <>
              <Form.Item>
                <div style={{ marginTop: 30 }}>
                  <Row justify="center" gutter={[30, 12]}>
                    <Col sm={12}>
                      <Button
                        block
                        onClick={() => {
                          setCreateCustomer(false);
                          form.resetFields();
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col sm={12}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={sending}
                      >
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form.Item>
            </>
          </Form>
        ) : (
          <Form form={form} scrollToFirstError>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  name="buyer"
                  rules={[{ required: true, message: 'Por favor, selecione um cliente!' }]}
                >
                  <SearchCustomerView handleSelectChange={handleSelectChange} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    loading={applyLoading}
                    disabled={!selectedUserId}
                    onClick={() => sendBuyerView(form.getFieldValue('buyer'))}
                    style={{ width: '100%' }}
                  >
                    Selecionar Cliente
                  </Button>
                </Form.Item>
              </Col>

              <Col span={24} style={{ textAlign: 'right', marginTop: '16px' }}>
                <Text>
                  Não encontrou o cliente?
                  <Link
                    onClick={() => setCreateCustomer(true)}
                    style={{ marginLeft: '4px' }}
                  >
                    Cadastre um novo
                  </Link>
                </Text>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>
    </>
  );
}
