import React, { useEffect, useState } from 'react';
import {
  Drawer, Result, Layout, Radio, Flex, TreeSelect, Grid, Segmented, theme
} from 'antd';
import { BarsOutlined, AppstoreOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BuyerListProduct from '../../components/BuyerListProduct';
import Cart from '../../components/Cart';
import BuyerListOrder from '../../components/BuyerListOrder';
import BuyerListBill from '../../components/BuyerListBill';
import BuyerListInvoice from '../../components/BuyerListInvoice';
import CartButton from '../../components/CartButton';
import SearchProduct from '../../components/Search';
import Navbar from '../../components/Navbar';
import OrderEditMessage from '../../components/SellerEditOrderView';
import SellerBuyerView from '../../components/SellerBuyerView';
import PagesFooter from '../../components/PagesFooter';
import { axiosClient } from '../../apiClient';

const { Content } = Layout;
const { useToken } = theme;
const { useBreakpoint } = Grid;


export default function Catalogo() {
  const dispatch = useDispatch();
  const { token } = useToken();
  const screens = useBreakpoint();

  const [visibleCart, setVisibleCart] = useState(false);
  const [visibleOrder, setVisibleOrder] = useState(false);
  const [selectedOption, setSelectedOption] = useState('order');
  const [treeData, setTreeData] = useState([]);
  const [viewType, setViewType] = useState('list');


  const logged = useSelector((state) => state.authentication.logged);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);
  const dropdownValue = useSelector((state) => state.searchState.categories);

  const { orderRebuyId } = useParams();

  useEffect(() => {
    if (logged === 'buyer') {
      setViewType('card')
    }
  }, [logged])

  useEffect(() => {
    orderRebuyId && dispatch({ type: 'SET_ORDER_REBUY', orderRebuyId });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has('meus-pedidos')) {
      setSelectedOption('order');
      setVisibleOrder(true);
    }

    if (params.has('minhas-contas')) {
      setSelectedOption('bill');
      setVisibleOrder(true);
    }
  }, []);

  const options = [
    {
      label: 'Orçamentos',
      value: 'quote',
    },
    {
      label: 'Pedidos',
      value: 'order',
    },
    {
      label: 'Contas',
      value: 'bill',
    },
    {
      label: 'Notas',
      value: 'invoice',
    },
  ];


  useEffect(() => {
    axiosClient
      .get('/products/categories/list', { withCredentials: true })
      .then((response) => {
        setTreeData(response.data);
      });
  }, []);


  return (
    (
      <Layout style={{
        backgroundColor: token.customColor2 ?? token.colorBgLayout,
        position: 'absolute', width: '100%', left: '0px', top: '0px',
      }}
      >
        {(logged === 'seller' || logged === 'sellerUser') && (orderRebuyId ? <OrderEditMessage /> : <SellerBuyerView />)}
        <Navbar handleToggle={() => setVisibleOrder(true)} />
        {!onlineStatus && syncStatus != 'synced' && (
          <Result
            style={{ marginTop: '100px' }}
            status="warning"
            title="Você está sem internet e seu aplicativo não está sincronizado"
            subTitle="Conecte seu aparelho na internet e sincronize os dados para usá-lo offline."
          />
        )}
        {(onlineStatus || (syncStatus === 'synced'))

          && (
            <Content>
              <div style={{
                backgroundColor: token.customColor1 ?? token.colorBgContainer,
                borderRadius: token.borderRadius,
                marginTop: (logged === 'seller' || logged === 'sellerUser')
                  ? (screens.md ? 112 + token.marginXXS : 112)
                  : (screens.md ? 60 + token.marginXXS : 60),
                width: '100%',
                overflow: 'hidden',
              }}>
                <Drawer
                  title="Seu Pedido"
                  placement="right"
                  width={window.innerWidth > 375 ? 375 : '100%'}
                  onClose={() => setVisibleCart(false)}
                  open={visibleCart}
                >
                  <Cart />
                </Drawer>
                <Drawer
                  title="Histórico do Cliente"
                  placement="right"
                  width={window.innerWidth > 375 ? 375 : '100%'}
                  onClose={() => setVisibleOrder(false)}
                  open={visibleOrder}
                >
                  <Flex vertical gap="middle">
                    <Radio.Group
                      block
                      options={options}
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Flex>
                  {['order', 'quote'].includes(selectedOption) && <BuyerListOrder orderType={selectedOption} />}
                  {selectedOption === 'bill' && <BuyerListBill />}
                  {selectedOption === 'invoice' && <BuyerListInvoice />}
                </Drawer>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: screens.md ? "row" : "column",
                    gap: 8,
                    padding: '16px 4px 8px 4px',
                  }}
                >
                  <Radio.Group
                    block
                    size='large'
                    onChange={(event) => event?.target?.value && dispatch({ type: 'SET_FILTER_TYPE', filterType: event?.target?.value })}
                    options={[{
                      label: 'Todos',
                      value: 'all',
                    },
                    {
                      label: 'Promoções',
                      value: 'on_sale',
                    },
                    ]}
                    defaultValue="all"
                    optionType="button"
                  />
                  <SearchProduct />
                  <TreeSelect
                    showSearch
                    treeNodeFilterProp='title'
                    treeData={treeData}
                    value={dropdownValue}
                    onChange={(categories) => dispatch({ type: 'CATEGORIES_VALUE', categories })}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    treeCheckable
                    placeholder="Filtre por categorias"
                    style={{ width: '100%' }}
                    size="large"
                    allowClear
                  />
                  {screens.md && <Segmented
                    size='large'
                    onChange={setViewType}
                    value={viewType}
                    options={[
                      {
                        value: 'list',
                        icon: <BarsOutlined />,
                      },
                      {
                        value: 'card',
                        icon: <AppstoreOutlined />,
                      },
                    ]}
                  />}
                </div>
                <BuyerListProduct viewType={viewType} />
                <CartButton handleToggle={() => setVisibleCart(!visibleCart)} />
              </div>
            </Content>
          )}
        <PagesFooter />
      </Layout >
    )
  );
}
