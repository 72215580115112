import axios from "axios";
import { openDB } from 'idb';
import store from "./store";

// Função para criar o banco de dados IndexedDB
async function createDB() {
  const db = await openDB('nupedido', 1, {
    upgrade(db, oldVersion, newVersion, transaction) {
      switch (oldVersion) {
        case 0:
          if (!db.objectStoreNames.contains('config')) {
            const configObjectStore = db.createObjectStore('config', { keyPath: 'id' });
            configObjectStore.add({ id: 1, status: 'disabled', synced: false });
          }
          if (!db.objectStoreNames.contains('buyers')) {
            db.createObjectStore('buyers', { keyPath: 'id' });
          }
          if (!db.objectStoreNames.contains('products')) {
            db.createObjectStore('products', { keyPath: 'id' });
          }
          if (!db.objectStoreNames.contains('carts')) {
            db.createObjectStore('carts', { keyPath: 'id' });
          }
          if (!db.objectStoreNames.contains('orders')) {
            db.createObjectStore('orders', { keyPath: 'id', autoIncrement: true });
          }
      }
    }
  });
  return db;
}

const dataBase = await createDB();

const originalHostname = window.location.hostname;
const hostnameParts = originalHostname.split('.');
hostnameParts.shift();

export const baseURL = /^(localhost|\d{1,3}(\.\d{1,3}){3})$/.test(originalHostname)
  ? `http://${originalHostname}:8081`
  : `https://api.${hostnameParts.join('.')}`;

// Instancia o axios com a configuração básica
export const axiosClient = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "application/json" },
});

// Função para renovar o token
async function getNewAccessToken() {
  const tokens = await dataBase.get("config", 6);
  if (!tokens || !tokens.nuRpeT) {
    throw new Error("Tokens de autenticação não encontrados.");
  }

  try {
    const tokenResponse = await axios.create({
      baseURL: baseURL,
      headers: { "Authorization": "Bearer " + tokens.nuRpeT },
    }).post("/auth/token-refresh");

    const newAccessToken = tokenResponse?.data?.access_token;
    if (newAccessToken) {
      await dataBase.put('config', { id: 6, nuApeT: newAccessToken, nuRpeT: tokens.nuRpeT, nuIpeT: tokens.nuIpeT });
      return newAccessToken;
    } else {
      throw new Error("Falha ao renovar o token.");
    }
  } catch (error) {
    throw new Error("Erro ao tentar renovar o token.");
  }
}

// Interceptador do axios para renovar o token em caso de expiração
axiosClient.interceptors.request.use(
  async function (config) {
    if (config.withCredentials) {
      const tokens = await dataBase.get("config", 6);
      if (tokens) {
        config.headers.Authorization = 'Bearer ' + tokens.nuApeT;
        config.headers.jti = tokens.nuIpeT;
      }
    }
    return config;
  }
);

axiosClient.interceptors.response.use(
  response => response,
  async error => {
    const handleLogout = async () => {
      const state = store.getState();
      await dataBase.delete('config', 6);
      if (state.authentication.logged) {
        store.dispatch({ type: "USER_LOGOUT" });
        alert("Sua sessão expirou, faça o login novamente.");
        window.location.reload();
      }
    };

    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        try {
          const newAccessToken = await getNewAccessToken();
          error.config.headers['Authorization'] = "Bearer " + newAccessToken;
          error.config.headers['Jti'] = (await dataBase.get('config', 6)).nuIpeT;

          return axiosClient.request(error.config);
        } catch {
          await handleLogout();
        }
      } else if (status === 403) {
        await handleLogout();
      }
    }

    return Promise.reject(error);
  }
);
