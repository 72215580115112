import React, { useEffect, useState, useLayoutEffect } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Select,
  Form,
  Input,
  Button,
  Row,
  Skeleton,
  Col,
  Card,
  Alert,
  Drawer,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';

const { Link } = Typography;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function SellerCreateProductVariations(props) {
  const [form] = Form.useForm();
  const [width, height] = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [productState, setProduct] = useState(null);

  // Variations
  const [variationDrawerVisible, setVariationDrawerVisible] = useState(false);
  const [variationsClass, setVariationsClass] = useState({});
  const [vartionsClasses, setVariationClasses] = useState({});
  const [variationClassInput, setVariationClassInput] = useState('');
  const [variations, setVariations] = useState(null);
  const [varProd, setVarProd] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [editVariationDrawer, setEditVariationDrawer] = useState([]);
  const [variationEditDrawerVisible, setVariationEditDrawerVisible] = useState(false);
  const [prodSKU, setProdSKU] = useState(null);
  const [prodPrices, setProdPrices] = useState(['R$ 0,00', 'R$ 0,00']);
  const [prodName, setProdName] = useState(null);
  const [editingRow, setEditingRow] = useState('');

  useEffect(() => {
    setProduct(props.value);
  }, []);

  useEffect(() => {
    setProdSKU(props.sku);
  }, [props.sku]);

  useEffect(() => {
    setProdName(props.name);
  }, [props.name]);

  useEffect(() => {
    setProdPrices(props.prodPrices);
  }, [props.prodPrices]);

  useEffect(() => {
    props.passVariationTabData(tableData);
  }, [tableData]);

  // GROUP VARIATION ARRAYS
  function iterArrays(...arrays) {
    return arrays[0].reduce(
      (prevAccumulator, currentArray) => {
        const newAccumulator = [];
        prevAccumulator.forEach((prevAccumulatorArray) => {
          currentArray.forEach((currentValue) => {
            newAccumulator.push(prevAccumulatorArray.concat(currentValue));
          });
        });
        return newAccumulator;
      },
      [[]],
    );
  }

  function updatePriceField(field, val) {
    form.setFields([
      {
        name: field,
        value: isNaN(val)
          ? 'R$ 0,00'
          : val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      },
    ]);
  }

  const onClose = () => {
    setVariationDrawerVisible(false);
    setVariationEditDrawerVisible(false);
  };

  const createVariationClass = () => {
    // setLoading(true);
    setVariationsClass({ ...variationsClass, [variationClassInput]: [] });
    setVariationDrawerVisible(false);
    setVariationClassInput('');
    // setLoading(false);
  };

  const onFinishVariationEdit = (values) => {
    const table = tableData.map((v, i) => {
      if (editingRow == i) {
        return {
          ...v,
          product: {
            ...v.product,
            gtin: values.gtin,
            product_price: values.product_price,
            product_price_sale: values.product_price_sale,
            product_stock: values.product_stock,
            product_weight: values.product_weight,
          },
        };
      } return { ...v };
    });
    setVariations(table);
    setTableData(table);
    setVariationEditDrawerVisible(false);
  };

  const onFinishFailedVariationEdit = (errorInfo) => {};

  useEffect(() => {
    if (productState) {
      if (productState.variation_childs) {
        axiosClient
          .post(
            '/products/get/list',
            { productList: productState.variation_childs },
            { withCredentials: true },
          )
          .then((response) => {
            setVariations(response.data);
          })
          .then(() => setVariationsClass({
            ...variationsClass,
            ...productState.variations,
          }))
          .then(() => setLoading(false));
      }
    }
  }, [productState]);

  useEffect(() => {
    if (variationsClass) {
      let variationsProd = [];
      const varClasses = [];
      for (var i = 0; i < Object.entries(variationsClass).length; i++) {
        Object.entries(variationsClass)[i][1].length > 0
          && varClasses.push(Object.entries(variationsClass)[i][0]);
        Object.entries(variationsClass)[i][1].length > 0
          && variationsProd.push(Object.entries(variationsClass)[i][1]);
      }
      variationsProd = iterArrays(variationsProd);
      const varClassperprod = [];
      for (var i = 0; i < variationsProd.length; i++) {
        let varClassintoprod = {};
        for (let j = 0; j < variationsProd[i].length; j++) {
          varClassintoprod = {
            ...varClassintoprod,
            [varClasses[j]]: variationsProd[i][j],
          };
        }
        varClassperprod.push(varClassintoprod);
      }
      setVariationClasses(varClassperprod);
      setVarProd(variationsProd);
      props.passVariationClasses(variationsClass);
    }
  }, [variationsClass]);

  useEffect(() => {
    const prods = varProd.map((v, i) => {
      if (v.length > 0) {
        let productInfo = {};
        if (variations) {
          for (let j = 0; j < variations.length; j++) {
            const prod_var = [];
            for (const key in variations[j].variations) {
              prod_var.push(variations[j].variations[key]);
            }
            if (prod_var.join(' - ') == v.join(' - ')) {
              productInfo = variations[j];
              break;
            }
          }
        }
        if ('product_sku' in productInfo == false) {
          let product_price = productState.product_price
            ? productState.product_price
            : prodPrices[0];
          let product_price_sale = productState.product_price_sale
            ? productState.product_price_sale
            : prodPrices[1];
          let product_stock = productState.product_stock
            ? productState.product_stock
            : 0;
          if (i < variations.length) {
            if (typeof variations[i].product === 'undefined') var var_prod_i = variations[i];
            else var var_prod_i = variations[i].product;
            product_price = (typeof var_prod_i.product_price !== 'undefined' || var_prod_i.product_price !== null) ? var_prod_i.product_price : 0;
            product_price_sale = (typeof var_prod_i.product_price_sale !== 'undefined' || var_prod_i.product_price_sale !== null) ? var_prod_i.product_price_sale : 0;
            product_stock = (typeof var_prod_i.product_stock !== 'undefined' || var_prod_i.product_stock !== null) ? var_prod_i.product_stock : 0;
          }
          productInfo = {
            ...productState,
            product_sku: prodSKU ? `${prodSKU}-${i}` : '',
            product_price,
            product_price_sale,
            product_stock,
            variations: v,
          };
        }
        return {
          product: productInfo,
          variations: v,
          product_sufix: v.join(' - '),
          key: i,
          vartions_with_class: vartionsClasses[i],
        };
      }
    });
    let addProds = true;
    for (let i = 0; i < prods.length; i++) {
      if (typeof prods[i] === 'undefined') {
        addProds = false;
        setTableData(null);
        break;
      }
    }
    if (addProds == true) {
      setVariations(prods);
      setTableData(prods);
    }
    setLoading(false);
  }, [varProd, prodSKU]);

  function handleChangeVariation(item, e) {
    setVariationsClass({ ...variationsClass, [item]: e });
  }

  function handleDeleteVariation(key, e) {
    const obj = { ...variationsClass };
    delete obj[key];
    setVariationsClass(obj);
  }

  const varitionRowClicked = (variation) => {
    setEditVariationDrawer(
      `${prodName || ''} - ${variation.product_sufix}`,
    );
    form.resetFields();
    if (variation.product) {
      form.setFieldsValue({
        ...variation.product,
      });
      setEditingRow(variation.key);
      setVariationEditDrawerVisible(true);
    }
  };

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const columnsVariation = [
    {
      title: 'Variação',
      width: 100,
      dataIndex: 'product_sufix',
      key: 'product_sufix',
      fixed: 'left',
      render: (text, record) => <Link>{record.product_sufix}</Link>,
    },
    {
      title: 'Código (SKU)',
      width: 100,
      dataIndex: 'sku',
      key: 'sku',
      fixed: 'left',
      render: (text, record) => record.product.product_sku,
    },
    {
      title: 'Estoque',
      dataIndex: 'stock',
      key: 'stock',
      width: 150,
      render: (text, record) => record.product.product_stock,
    },
    {
      title: 'Preço',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      render: (text, record) => (
        <>
          {record.product.product_price
            ? record.product.product_price.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            : 'R$ 0,00'}
        </>
      ),
    },
  ];

  return (
    <>
      <PageHeader
        extra={(
          <Button
            onClick={() => setVariationDrawerVisible(true)}
            type="primary"
          >
            Adicionar Variação
          </Button>
      )}
      />
      {loading == true ? (
        <Skeleton active />
      ) : (
        <>
          {Object.entries(variationsClass).length === 0 ? (
            <Card>
              <Alert
                message="Você não tem variações de produto cadastradas."
                type="info"
                showIcon
              />
            </Card>
          ) : (
            <>
              <Form
                form={form}
                scrollToFirstError
                {...layout}
                name="variations"
              >
                {Object.entries(variationsClass).map((
                  [key, value],
                  i,
                ) => (
                  <Form.Item name={key} label={key}>
                    <Row gutter={12}>
                      <Col xs={18} sm={18}>
                        <Select
                          mode="tags"
                          block
                          style={{ width: '100%' }}
                          open={false}
                          defaultValue={value}
                          placeholder="Digite a variação e precione Enter"
                          onChange={(e) => handleChangeVariation(key, e)}
                        />
                      </Col>
                      <Col xs={6} sm={6}>
                        <Tooltip title="Excluir variação">
                          <Button
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={(e) => handleDeleteVariation(key, e)}
                          />
                        </Tooltip>
                      </Col>
                    </Row>
                  </Form.Item>
                ))}
              </Form>
              <Table
                columns={columnsVariation}
                dataSource={loading ? [] : tableData}
                onRow={(variation, rowIndex) => ({
                  onClick: (event) => {
                    varitionRowClicked(variation);
                  },
                })}
              />
            </>
          )}
        </>
      )}
      <Drawer
        title="Título da Variação"
        placement="bottom"
        height={200}
        onClose={onClose}
        open={variationDrawerVisible}
        extra={(
          <Button
            type="primary"
            onClick={() => createVariationClass()}
            loading={loading}
          >
            Salvar
          </Button>
      )}
      >
        <Input
          value={variationClassInput}
          onChange={(value) => setVariationClassInput(value.target.value)}
          placeholder="Exemplo: Tamanho, Cor, Modelo"
          loading={loading}
          onKeyDown={(e) => {
            (e.code === 'Enter' || e.code === 'NumpadEnter')
            && createVariationClass();
          }}
        />
      </Drawer>
      <Drawer
        title={editVariationDrawer}
        placement="bottom"
        height={width <= 575 ? 600 : 450}
        onClose={onClose}
        open={variationEditDrawerVisible}
      >
        <Form
          form={form}
          name="variation_edit"
          {...layout}
          onFinish={onFinishVariationEdit}
          onFinishFailed={onFinishFailedVariationEdit}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Código SKU"
                name={['product_sku']}
                rules={[
                  { required: true, message: 'Código (SKU) é obrigatório' },
                ]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Código de barras (GTIN)" name={['gtin']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Preço"
                name={['product_price']}
                rules={[{ required: true, message: 'Preço é obrigatório' }]}
                onChange={(e) => {
                  const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                  updatePriceField('product_price', val);
                }}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Preço Promocional"
                name={['product_price_sale']}
                rules={[{ required: false }]}
                onChange={(e) => {
                  const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                  updatePriceField('product_price_sale', val);
                }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Estoque"
                name={['product_stock']}
                rules={[{ required: true, message: 'Estoque é obrigatório' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label="Peso (kg)" name={['product_weight']}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center" gutter={[30, 12]}>
            <Col xs={18} sm={8}>
              <Form.Item>
                <Button block type="primary" htmlType="submit">
                  Aplicar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
