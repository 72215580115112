import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Input, Form, Select, Typography, TreeSelect } from 'antd';
import { axiosClient } from '../../apiClient';
import { debounce } from 'lodash';
import { formatBuyer } from '../../functions/validation';

const { Option, OptGroup } = Select;
const { Text } = Typography;
const { TextArea } = Input;
const { SHOW_PARENT } = TreeSelect;

export default function ComponentProductCondition({
  form,
  label,
  showAllOption = true }) {
  const dispatch = useDispatch();
  const [availableTags, setAvailableTags] = useState([]);
  const [categories, setCategories] = useState([]);

  const [productList, setProductList] = useState({
    loading: false,
    list: [],
  });

  const condition = Form.useWatch(['productForm', 'condition'], form);

  useEffect(
    () => {
      axiosClient
        .get('/products/categories/list', { withCredentials: true })
        .then((response) => {
          setCategories(response.data);
        });
    },
    [],
  );

  useEffect(() => {
    axiosClient
      .get('/products/tag/list', { withCredentials: true })
      .then((response) => {
        setAvailableTags(response.data.tags)
      });
  }, []);

  const fetchProducts = (searchValue = '') => {
    setProductList({ loading: true, list: [] });
    axiosClient
      .post('/products/search', { search: searchValue }, { withCredentials: true })
      .then((response) => setProductList({ loading: false, list: response.data.products || [] }))
      .catch(() => setProductList({ loading: false, list: [] }));
  };

  const debouncedSearch = useCallback(debounce(fetchProducts, 500), []);

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <>
      <Form.Item
        name={['productForm', 'condition']}
        rules={[{ required: true }]}
        label={label}
      >
        <Select
          placeholder="Selecione os produtos"
          allowClear
        >
          {showAllOption && <Option value="all">Todos produtos</Option>}
          <Option value="specific">Produtos específicos</Option>
          <Option value="tags">Produtos que contém tag</Option>
          <Option value="categories">Categoria de produtos</Option>
        </Select>
      </Form.Item>

      {condition === 'categories' && (
        <>
          <Form.Item
            name={['productForm', 'categories']}
            rules={[{ required: true }]}
          >
            <TreeSelect
              treeLine
              showSearch
              treeNodeFilterProp='title'
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={categories}
              placeholder="Selecione as categorias"
              treeDefaultExpandAll
              multiple
              treeCheckable
              showCheckedStrategy={SHOW_PARENT}
            />
          </Form.Item>
        </>
      )}

      {condition === 'tags' && (
        <Form.Item
          name={['productForm', 'tags']}
          rules={[{ required: true }]}
        >
          <Select placeholder="Selecione as tags" mode="multiple" allowClear>
            {Object.values(availableTags).map((group) => (
              <OptGroup key={group.id} label={group.title}>
                {group.tags.map((tag) => (
                  <Option key={tag.id} value={tag.id}>
                    {tag.title}
                  </Option>
                ))}
              </OptGroup>
            ))}
          </Select>
        </Form.Item>
      )}

      {condition === 'specific' && (
        <Form.Item
          name={['productForm', 'specific']}
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            mode="multiple"
            autoClearSearchValue={false}
            style={{ width: '100%' }}
            placeholder="Selecione os produtos"
            allowClear
            onSearch={debouncedSearch}
            loading={productList.loading}
            filterOption={false}
            notFoundContent={productList.loading ? <Spin size="small" /> : null}
          >
            {productList.loading
              ? null
              : productList.list.map((product) => (
                <Option key={product.id} value={product.id}>
                  {product.product_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
};