import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Table, Button, Skeleton, Empty, Space, Typography, notification } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';

const { Link, Title } = Typography;

const actionLabels = {
  freeShipping: 'Frete Grátis',
  discount: 'Desconto',
  progressiveDiscount: 'Desconto Progressivo',
};

const SellerListCampaign = () => {
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .post('/rules/list', { actions: ['discount', 'freeShipping', 'progressiveDiscount'] }, { withCredentials: true })
      .then(({ data }) => {
        setCampaigns(data.rules);
        setLoading(false);
      });
  }, []);

  const renderBuyers = (record) => (
    <Space direction="vertical">
      {record.buyer_condition === 'all' && 'Todos Clientes'}
      {record.buyer_condition !== 'all' && (
        (record.buyers_tags?.length > 0 && 'Por Tags') ||
        (record.buyers?.length > 0 && 'Clientes Específicos') ||
        (
          record.ceps_data &&
          (record.ceps_data.ceps_full?.length > 0 ||
            record.ceps_data.ceps_range?.length > 0 ||
            record.ceps_data.ceps_start?.length > 0) &&
          'Por CEP'
        )
      )}
    </Space>
  );

  const renderProducts = (record) => (
    <Space direction="vertical">
      {!record.product_condition && 'N/A'}
      {record.product_condition === 'all' && 'Todos Produtos'}
      {record.product_condition !== 'all' && (
        (record.products_tags?.length > 0 && 'Por Tags') ||
        (record.products?.length > 0 && 'Produtos Específicos') ||
        (record.products_categories_ids?.length > 0 && 'Por Categorias')
      )}
    </Space>
  );


  const columns = [
    {
      title: 'Código',
      ellipsis: true,
      responsive: ['sm'],
      render: (record) => <Link>{record.name}</Link>,
    },
    {
      title: 'A quem se aplica',
      ellipsis: true,
      responsive: ['sm'],
      render: renderBuyers,
    },
    {
      title: 'Produtos',
      responsive: ['sm'],
      render: renderProducts,
    },
    {
      title: 'Ação',
      responsive: ['sm'],
      render: (record) => actionLabels[record.action],
    },
    {
      title: 'Regras',
      render: (record) => (
        <>
          <Link>
            {' '}
            {record.name}
            {' '}
          </Link>
          <br />
          <b>Clientes:</b>
          {' '}
          <Space split="e">
            {renderBuyers(record)}
          </Space>
          <br />
          <b>Produtos:</b>
          {' '}
          <Space split="e">
            {renderProducts(record)}
          </Space>
          <br />
          <b>Valor: {record?.min_order_personalized}</b>
        </>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  const openHelpNotification = () => {
    notification.open({
      duration: 20,
      placement: 'bottomRight',
      message: <Title level={4}>Ajuda</Title>,
      description:
        'Crie campanhas com descontos automáticos ou cupons, oferecendo descontos fixos, progressivos ou frete grátis.',
    });
  };

  return (
    <>
      <PageHeader
        title={
          <>
            Campanhas{' '}
            <Link onClick={openHelpNotification}>
              <QuestionCircleOutlined />
            </Link>
          </>
        }
        onBack={() => navigate('/admin')}
        extra={
          <Button
            onClick={() => navigate('/admin/marketing/campanhas/cadastro')}
            type="primary"
          >
            Criar Campanha
          </Button>
        }
      />
      <Table
        style={{ cursor: !loading ? 'pointer' : 'auto' }}
        columns={columns}
        dataSource={campaigns}
        locale={{
          emptyText: loading ? <Skeleton active /> : <Empty />,
        }}
        onRow={(record) => ({
          onClick: () => navigate(`/admin/marketing/campanhas/cadastro/${record.id}`),
        })}
      />
    </>
  );
};

export default SellerListCampaign;
