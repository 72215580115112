import React, { useState, useEffect, useCallback } from 'react';
import {
  App,
  Card,
  Form,
  Input,
  Button,
  Checkbox,
  Result,
  Typography,
  Row,
  Col,
  Space,
  theme
} from 'antd';
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import login from '../../actions/login';
import { axiosClient } from '../../apiClient';
import {
  validaCpfCnpj,
  retirarFormatacao,
  mascaraCpf,
  mascaraCnpj,
} from '../../functions/validation';
import BuyerRequestAccess from '../BuyerRequestAccess';
import LoginFooter from '../LoginFooter';

const { Title } = Typography;
const { useToken } = theme;

export default function BuyerLogin(props) {
  const { message } = App.useApp();
  const { Text, Link } = Typography;
  const { token } = useToken();
  const authentication = useSelector((state) => state.authentication);
  const sellerData = useSelector((state) => state.sellerData);
  const [sending, setSending] = useState(false);
  const [changePassData, setChangePassData] = useState(false);
  const [hasFeedback, setHasFeedback] = useState(false);

  const [screen, setScreen] = useState('login');
  const wrapperSetScreen = useCallback(
    (val) => {
      setScreen(val);
    },
    [setScreen],
  );

  const [reenviado, setReenviado] = useState(false);

  const [form] = Form.useForm();
  const [form_token] = Form.useForm();
  const [form_new_pass] = Form.useForm();
  const [loginData, setLoginData] = useState({
    document: '',
    password: '',
    tipoPessoa: '',
  });

  const [formData, setFormData] = useState({
    document: '',
    password: '',
  });

  useEffect(() => {
    formatarCampo();
  }, [formData]);

  useEffect(() => {
    changePassData != false && sendEmail();
  }, [changePassData]);

  function sendEmail() {
    axiosClient
      .post(
        '/auth/buyer/reset-password-request',
        {
          pathName: props.pathName,
          domainName: props.domainName,
          resend: changePassData.resend,
          tipo_pessoa: changePassData.tipoPessoa,
          cpf: changePassData.cpf,
          cnpj: changePassData.cnpj,
        },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.msg == 'Invalid cpf/cnpj input') {
          form.setFields([
            {
              name: 'document',
              errors: ['Usuário não encontrado'],
            },
          ]);
        } else if (response.data.msg == 'Buyer has no email') {
          setScreen('login');
          message.error(
            'Usuário sem email cadastrado. Entre em contato com o seu vendedor para resolver esse problema.',
            5,
          );
        } else if (response.data.status == 'Success') {
          setScreen('forgetCode');
        } else {
          setScreen('login');
        }
      });
  }

  function verifyCode() {
    const token = form_token.getFieldValue('token');
    axiosClient
      .post(
        '/auth/buyer/check-reset-token',
        {
          pathName: props.pathName,
          domainName: props.domainName,
          resend: changePassData.resend,
          tipo_pessoa: changePassData.tipoPessoa,
          cpf: changePassData.cpf,
          cnpj: changePassData.cnpj,
          token,
        },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.msg == 'Token invalid') {
          form_token.setFields([
            {
              name: 'token',
              errors: ['Token inválido'],
            },
          ]);
        } else if (response.data.status == 'Success') {
          setScreen('forgetNewPass');
        } else {
          setScreen('forgetCode');
        }
      });
  }

  function sendNewPass() {
    const pass1 = form_new_pass.getFieldValue('pass1');
    const pass2 = form_new_pass.getFieldValue('pass2');
    if (pass1 == pass2) {
      const token = form_token.getFieldValue('token');
      axiosClient
        .post(
          '/auth/buyer/reset-password',
          {
            pathName: props.pathName,
            domainName: props.domainName,
            resend: changePassData.resend,
            tipo_pessoa: changePassData.tipoPessoa,
            cpf: changePassData.cpf,
            cnpj: changePassData.cnpj,
            token,
            new_password: pass1,
          },
          { withCredentials: true },
        )
        .then((response) => {
          if (response.data.status == 'Success') {
            setScreen('successNewPass');
          } else {
            setScreen('errorNewPass');
          }
        });
    } else {
      form_new_pass.setFields([
        {
          name: 'pass1',
          errors: [''],
        },
        {
          name: 'pass2',
          errors: ['Senhas divergentes'],
        },
      ]);
    }
  }

  function formatarCampo() {
    const semFormatacao = retirarFormatacao(formData.document.replace(/\D/g, ''));
    if (semFormatacao.length == 11) {
      const comFormatacao = mascaraCpf(semFormatacao);
      if (validaCpfCnpj(comFormatacao)) {
        setHasFeedback(true);
        setLoginData({
          document: semFormatacao,
          password: formData.password,
          tipoPessoa: 'pf',
          cpf: semFormatacao,
          cnpj: null,
        });
        // if (formData.document != comFormatacao) {
        //   form.setFieldsValue({
        //     document: comFormatacao,
        //     cpf: comFormatacao,
        //     cnpj: null,
        //   });
        // }
        // button.disabled = false;
      }
    } else if (semFormatacao.length == 14) {
      const comFormatacao = mascaraCnpj(semFormatacao);
      if (validaCpfCnpj(comFormatacao)) {
        setHasFeedback(true);
        setLoginData({
          document: semFormatacao,
          password: formData.password,
          tipoPessoa: 'pj',
          cpf: null,
          cnpj: semFormatacao,
        });
        // if (formData.document != comFormatacao) {
        //   form.setFieldsValue({
        //     document: comFormatacao,
        //     cpf: null,
        //     cnpj: comFormatacao,
        //   });
        //   setSending(false);
        // }
        // button.disabled = false;
      }
    } else {
      setHasFeedback(false);
      // button.disabled = true;
      form.setFieldsValue({
        document: semFormatacao,
      });
      setSending(false);
    }
  }

  function onFinish() {
    setSending(true);
    setLoginData({ ...loginData, submitted: true });
  }

  useEffect(() => {
    if (loginData.submitted) {
      login(
        'buyer',
        loginData.password,
        '',
        loginData.document,
        props.pathName,
        props.domainName,
        loginData.tipoPessoa,
        props.dataBase,
      );
    }
  }, [loginData]);

  useEffect(() => {
    if (authentication.wrongPass) {
      setSending(false);
      form.setFields([
        {
          name: 'password',
          errors: ['Senha incorreta'],
        },
      ]);
    }

    if (authentication.wrongUser) {
      setSending(false);
      form.setFields([
        {
          name: 'document',
          errors: ['Usuário não encontrado'],
        },
      ]);
    }
  }, [authentication]);

  function handleChange(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  function setTokenFinish() {
    verifyCode();
  }

  function setPassFinish() {
    sendNewPass();
  }

  function Logo() {
    return (
      <img
        style={{ maxHeight: '100px', maxWidth: '100%' }}
        src={
          sellerData.logotipoUrl
            ? sellerData.logotipoUrl
            : 'https://storage.googleapis.com/nupedido-public-bucket/static/images/logo/logo.webp'
        }
      />
    );
  }


  return (
    <>
      <Card style={{ boxShadow: token.boxShadow, top: '6vh', width: '95%', maxWidth: '500px', margin: 'auto auto', textAlign: 'center' }}>
        <Space size='small' style={{ width: '100%' }} direction='vertical'>
          {screen == 'login' && (
            <>
              <Logo />
              <Title level={4}>Faça o login para continuar</Title>
              <Form
                form={form}
                size='large'
                scrollToFirstError
                name="normal_login"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onChange={handleChange}
              >
                <Form.Item
                  name="document"
                  hasFeedback={hasFeedback}
                  validateStatus="success"
                  rules={[
                    {
                      required: true,
                      message: 'CPF/CNPJ obrigatório',
                    },
                  ]}
                >
                  <Input
                    type="number"
                    pattern="\d*"
                    prefix={
                      <UserOutlined />
                    }
                    placeholder="CPF/CNPJ"
                    name="document"
                    value={formData.document}
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Senha obrigatória',
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    placeholder="Senha"
                    prefix={
                      <LockOutlined />
                    }
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox> Continuar logado</Checkbox>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => {
                          setScreen('getPassword');
                        }}
                      >
                        Recuperar senha
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item>
                  <Row justify="center" gutter={[12, 8]}>
                    <Col xs={24} sm={12}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        style={{ width: '100%' }}
                        loading={sending}
                      >
                        Entrar
                      </Button>
                    </Col>
                    <Col xs={24} sm={12}>
                      <Button
                        block
                        style={{ width: '100%' }}
                        onClick={() => {
                          setScreen('requestAccess');
                        }}
                      >
                        Solicitar acesso
                      </Button>
                    </Col>
                  </Row>
                  <br />
                  <Text>
                    É vendedor?{' '}
                    <Link href="/admin">Clique aqui</Link>
                  </Text>

                </Form.Item>
              </Form>
            </>
          )}
          {screen == 'requestAccess' && (
            <>
              <Logo />
              <BuyerRequestAccess
                parentScreen={screen}
                parentScreenSetter={wrapperSetScreen}
                sellerData={sellerData}
              />
            </>
          )}
          {screen == 'getPassword' && (
            <>
              <Logo />
              <Title level={4}>Esqueceu a senha?</Title>
              <Form
                form={form}
                name="get_pass"
                initialValues={{
                  remember: true,
                }}
                onFinish={() => setChangePassData({
                  ...loginData,
                  pathName: props.pathName,
                  domainName: props.domainName,
                  resend: true,
                })}
                onChange={handleChange}
              >
                <Form.Item
                  name="document"
                  rules={[
                    {
                      required: true,
                      message: 'CPF/CNPJ obrigatório',
                    },
                  ]}
                >
                  <Input
                    type="string"
                    prefix={
                      <UserOutlined />
                    }
                    placeholder="CPF/CNPJ"
                    name="document"
                    value={formData.document}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    Recuperar Senha
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    style={{ width: '100%' }}
                    onClick={() => setScreen('login')}
                  >
                    Voltar
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          {screen == 'forgetCode' && (
            <>
              <Logo />
              <Title level={4}>Enviamos o código para seu email</Title>
              Digite o código de 6 dígitos que enviamos para
              {' '}
              <b>{form.getFieldValue('email')}</b>
              {reenviado == false ? (
                <Button
                  type="link"
                  onClick={() => {
                    setChangePassData({
                      ...loginData,
                      pathName: props.pathName,
                      domainName: props.domainName,
                      resend: true,
                    });
                    setReenviado(true);
                  }}
                >
                  Reenviar código
                </Button>
              ) : (
                <Text type="secondary">Email Reenviado</Text>
              )}
              <br />
              <Form
                form={form_token}
                name="set_token"
                onFinish={setTokenFinish}
              >
                <Form.Item
                  name="token"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input type="string" placeholder="Código de 6 dígitos" />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          {screen == 'forgetNewPass' && (
            <>
              <Logo />
              <Title level={4}>Escolha uma nova senha</Title>
              <br />
              Escolha uma senha com pelo menos 8 caracteres
              <br />
              <Form
                form={form_new_pass}
                name="set_pass"
                initialValues={{
                  remember: true,
                }}
                onFinish={setPassFinish}
              >
                <Form.Item
                  name="pass1"
                  rules={[
                    {
                      required: true,
                      message: 'Senha obrigatória',
                    },
                  ]}
                >
                  <Input.Password type="string" placeholder="Nova senha" />
                </Form.Item>
                <Form.Item
                  name="pass2"
                  rules={[
                    {
                      required: true,
                      message: 'Senha obrigatória',
                    },
                  ]}
                >
                  <Input.Password
                    type="string"
                    placeholder="Repita a senha escolhida"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                  >
                    Enviar
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
          {screen == 'successNewPass' && (
            <>
              <Logo />
              <Result
                status="success"
                subTitle="Senha alterada com sucesso!"
                extra={[
                  <Button
                    type="primary"
                    onClick={() => {
                      setScreen('login');
                    }}
                  >
                    Fazer Login
                  </Button>,
                ]}
              />
            </>
          )}
          {screen == 'errorNewPass' && (
            <>
              <Logo />
              <Result
                status="error"
                subTitle="Ocorreu um erro ao recuperar sua senha."
                extra={[
                  <Button
                    type="primary"
                    onClick={() => {
                      setScreen('login');
                    }}
                  >
                    Voltar
                  </Button>,
                ]}
              />
            </>
          )}
        </Space>
      </Card>
      <LoginFooter />
    </>
  );
}
