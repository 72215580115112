import React, { useState, useEffect } from 'react';
import {
  Layout,
  Menu,
  Dropdown,
  Row,
  Col,
  Button,
  Space,
  Alert,
  Badge,
  Result,
  Grid,
  theme
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes, Route, Link } from 'react-router-dom';

import {
  SlidersOutlined,
  PercentageOutlined,
  GiftOutlined,
  NotificationOutlined,
  OrderedListOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  SettingOutlined,
  SkinOutlined,
  CheckCircleOutlined,
  QuestionCircleOutlined,
  FundOutlined,
  LogoutOutlined,
  TeamOutlined,
  ControlOutlined,
  DollarCircleOutlined,
  CarOutlined,
  BranchesOutlined,
  TagsOutlined,
  MenuOutlined,
  EditOutlined,
  UserAddOutlined,
  KeyOutlined,
  ToolOutlined,
  SmileOutlined,
} from '@ant-design/icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Marquee from 'react-fast-marquee';
import { axiosClient } from '../../apiClient';

import SellerStats from '../../components/SellerStats';
import SellerConfiguration from '../../components/SellerConfiguration';
import SellerListProduct from '../../components/SellerListProduct';
import SellerListCategory from '../../components/SellerListCategory';
import SellerCreateProduct from '../../components/SellerCreateProduct';
import SellerListCustomer from '../../components/SellerListCustomer';
import SellerCreateCustomer from '../../components/SellerCreateCustomer';
import SellerListOrder from '../../components/SellerListOrder';
import SellerListCart from '../../components/SellerListCart';
import SellerListPayment from '../../components/SellerListPayment';
import SellerCreatePayment from '../../components/SellerCreatePayment';
import SellerListShipping from '../../components/SellerListShipping';
import SellerCreateShipping from '../../components/SellerCreateShipping';
import SellerListProductRule from '../../components/SellerListProductRule';
import SellerListShippingRule from '../../components/SellerListShippingRule';
import SellerCreateProductRule from '../../components/SellerCreateProductRule';
import SellerCreateShippingRule from '../../components/SellerCreateShippingRule';
import SellerListPaymentRule from '../../components/SellerListPaymentRule';
import SellerCreatePaymentRule from '../../components/SellerCreatePaymentRule';
import SellerListCustomerTag from '../../components/SellerListCustomerTag';
import SellerListProductTag from '../../components/SellerListProductTag';
import SellerListOrderDetails from '../../components/SellerListOrderDetails';
import SellerListCartDetails from '../../components/SellerListCartDetails';
import SellerListCartRule from '../../components/SellerListCartRule';
import SellerCreateCartRule from '../../components/SellerCreateCartRule';
import SellerListCustomerAccess from '../../components/SellerListCustomerAccess';
import SellerListCampaign from '../../components/SellerListCampaign';
import SellerListPriceList from '../../components/SellerListPriceList';
import SellerCreateCampaign from '../../components/SellerCreateCampaign';
import SellerChangePass from '../../components/SellerChangePass';
import SellerOptions from '../../components/SellerOptions';
import PagesFooter from '../../components/PagesFooter';
import Onboarding from '../../components/Onboarding';
import logout from '../../actions/logout';


const { useToken } = theme;
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const rootSubmenuKeys = [
  'home',
  'customerMenu',
  'productMenu',
  'orderMenu',
  'configurations',
];


export default function Admin() {
  const titlePrefix = 'Nupedido - ';
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useToken();
  const screens = useBreakpoint();

  const accessRequestsQty = useSelector((state) => state.accessRequests.quantity);
  const sellerData = useSelector((state) => state.sellerData);
  const themeConfig = useSelector((state) => state.themeConfig);
  const logged = useSelector((state) => state.authentication.logged);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);

  const [openKeys, setOpenKeys] = useState(['1']);
  const [collapsedSide, setCollapsedSide] = useState(true);
  const [customer, setCustomer] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [changePassDrawer, setChangePassDrawer] = useState(false);



  useEffect(() => {
    document.getElementById('darken-background').style.display = !screens.md && !collapsedSide ? 'block' : 'none';
  }, [collapsedSide, screens.md]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    setCollapsedSide(true)
    axiosClient
      .get('/user-update-info', { withCredentials: true })
      .then((response) => {
        setCustomer(response.data.customer);
        dispatch({
          type: 'SET_ACCESS_REQUEST',
          quantity: response.data.customer.request_access,
        });
      });
  }, [location.pathname]);

  useEffect(() => {
    if (customer.cancel_at_period_end == true) {
      setAlertMessage({
        alert_message: `Sua renovação automática foi desativada. Ao final do período, dia ${customer.current_period_end.split(' - ')[0]
          }, você não será mais cobrado, mas você e seus clientes perderão acesso ao catálogo.`,
        alert_type: 'warning',
        button_text: 'Reativar Assinatura',
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == 'trialing'
      && customer.payment_method == null
    ) {
      if (customer.trial_left > 0) {
        var mensagem = `Restam ${customer.trial_left + 1
          } dias de teste. Ao final do período você e seus clientes perderão acesso ao catálogo. Cadastre um cartão de crédito para evitar que isso aconteça.`;
        var tipo = 'info';
      } else {
        var mensagem = 'Hoje é o último dia de teste. Amanhã você e seus clientes não terão mais acesso ao catálogo. Cadastre um cartão de crédito para evitar que isso aconteça.';
        var tipo = 'warning';
      }
      setAlertMessage({
        alert_message: mensagem,
        alert_type: tipo,
        button_text: 'Cadastrar',
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == false
      && customer.payment_method == null
    ) {
      setAlertMessage({
        alert_message: 'Você não está cadastrado em nenhum plano. Contate o suporte para evitar a perda de acesso ao sistema.',
        alert_type: 'warning',
        // button_text: "Verificar",
      });
      setAlertVisible(true);
    } else if (
      customer.is_customer == 'past_due'
      && customer.payment_method == null
    ) {
      setAlertMessage({
        alert_message: 'Seu plano expirou. Cadastre um cartão de crédito válido para voltar a ter acesso ao seu catálogo.',
        alert_type: 'warning',
        button_text: 'Cadastrar',
      });
      setAlertVisible(true);
    } else if (customer.is_customer == 'past_due' && customer.payment_method) {
      setAlertMessage({
        alert_message: 'Tivemos algum problema com a cobrança em seu cartão. Regularize a situação e volte a ter acesso ao seu catálogo.',
        alert_type: 'warning',
        button_text: 'Verificar',
      });
      setAlertVisible(true);
    } else {
      setAlertVisible(false);
    }
  }, [customer]);

  const items = [
    ...(!screens.md
      ? [{
        label: <Link to="/">Criar Pedido</Link>,
        key: 'sellerCatalog',
        icon: <EditOutlined />,
      }] : []),
    ...(logged === 'seller'
      ? [{
        label: <Link to="/admin/configuracoes">Configurações</Link>,
        key: 'sellerConfiguration',
        icon: <ToolOutlined />,
      }] : []),
    {
      label: 'Trocar Senha',
      key: 'sellerChangePass',
      icon: <KeyOutlined />,
      onClick: () => setChangePassDrawer(true)
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: logout
    },
  ];


  return sellerData.username === null ? (
    <Onboarding />
  ) : (
    <>
      <div
        id="darken-background"
        onClick={() => setCollapsedSide(true)}
        style={{
          position: 'fixed',
          top: '0px',
          width: '100%',
          height: '100%',
          zIndex: 8,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />
      <Layout>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            top: 0,
            padding: 0,
            margin: 0,
            backgroundColor: token.colorBgContainer,
            boxShadow: token.boxShadow,
            // zIndex: 9,
          }}
        >
          <img
            src={themeConfig.isDark ? "/logo_dark.webp" : "/logo.webp"}
            alt="Logo Nupedido"
            style={{
              maxHeight: '50px',
              maxWidth: '200px',
              margin: '5px',
              marginLeft: '30px'
            }}
            onClick={() => window.location.replace('/admin')}
          />
          <div>
            <Dropdown menu={{ items }} trigger="click">
              <Button
                style={{
                  float: 'right',
                  marginTop: '13px',
                  marginBottom: '13px',
                  marginLeft: '4px',
                  marginRight: '13px',
                }}
                icon={<SettingOutlined />}
              />
            </Dropdown>
            {screens.md ? (
              <Button
                type="primary"
                style={{
                  float: 'right',
                  marginTop: '13px',
                  marginBottom: '13px',
                  marginLeft: '4px',
                  marginRight: '4px',
                }}
                icon={<EditOutlined />}
                onClick={() => navigate('/')}
              >
                Criar Pedido
              </Button>
            ) : (<Button
              type="primary"
              style={{
                float: 'right',
                marginTop: '13px',
                marginBottom: '13px',
                marginLeft: '4px',
                marginRight: '4px',
              }}
              icon={<EditOutlined />}
              onClick={() => navigate('/')}
            />
            )}
          </div>
        </Header>
        <Layout>
          {!onlineStatus && syncStatus != 'synced' && (
            <Result
              status="warning"
              title="Você está sem internet e seu aplicativo não está sincronizado"
              subTitle="Conecte seu aparelho na internet e sincronize os dados para usá-lo offline."
            />
          )}
          {!onlineStatus && syncStatus == 'synced' && (
            <Result
              icon={<SmileOutlined />}
              title="Você está offline e sincronizado, pode gerar pedidos."
              subTitle="O painel do gestor não está disponível no modo offline. Conecte seu aparelho na internet para utilizá-lo."
              extra={[
                <Button
                  onClick={() => navigate('/')}
                  type="primary"
                  key="console"
                >
                  Criar Pedido
                </Button>,
              ]}
            />
          )}
          {onlineStatus
            && (
              <>
                <Sider
                  breakpoint="md"
                  collapsedWidth="0"
                  trigger={<Button shape="default" color="primary" variant="solid" icon={<MenuOutlined />} size="large"></Button>}
                  collapsed={screens.md ? false : collapsedSide}
                  theme="light"
                  width="256"
                  height="100%"
                  style={{
                    position: !screens.md ? 'fixed' : 'relative',
                    top: !screens.md ? 180 : 0,
                    zIndex: 10,
                  }}
                  onCollapse={(collapsed, type) => {
                    setCollapsedSide(collapsed);
                  }}
                >
                  <Menu
                    mode="inline"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    style={{ width: 256 }}
                  >
                    <Menu.Item icon={<FundOutlined />} key="home">
                      <Link to="/admin">Dashboard</Link>
                    </Menu.Item>
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.customers.length > 0)) && (
                        <SubMenu
                          key="customerMenu"
                          icon={<TeamOutlined />}
                          title="Clientes"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.customers.includes(
                                'Cadastros',
                              ))) && (
                              <Menu.Item
                                icon={<OrderedListOutlined />}
                                key="listCustomer"
                              >
                                <Link to="/admin/clientes/lista">Cadastros</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.customers.includes(
                                'Solicitações',
                              ))) && (
                              <Menu.Item
                                icon={<UserAddOutlined />}
                                key="listCustomerAccess"
                              >
                                <Link to="/admin/clientes/solicitacoes">
                                  Solicitações
                                  {' '}
                                  <Badge
                                    count={accessRequestsQty}
                                    overflowCount={99}
                                    offset={[0, -10]}
                                  />
                                </Link>

                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.customers.includes('Tags'))) && (
                              <Menu.Item icon={<TagsOutlined />} key="listCustomerTag">
                                <Link to="/admin/clientes/tags">Tags</Link>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      )}
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.products.length > 0)) && (
                        <SubMenu
                          key="productMenu"
                          icon={<SkinOutlined />}
                          title="Produtos"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.products.includes(
                                'Cadastros',
                              ))) && (
                              <Menu.Item icon={<OrderedListOutlined />} key="listProduct">
                                <Link to="/admin/produtos/lista">Cadastros</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.products.includes(
                                'Categorias',
                              ))) && (
                              <Menu.Item icon={<MenuOutlined />} key="listCategory">
                                <Link to="/admin/produtos/categorias">Categorias</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.products.includes('Tags'))) && (
                              <Menu.Item icon={<TagsOutlined />} key="listProductTag">
                                <Link to="/admin/produtos/tags">Tags</Link>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      )}
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.orders.length > 0)) && (
                        <SubMenu
                          key="orderMenu"
                          icon={<DollarOutlined />}
                          title="Vendas"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.orders.includes(
                                'Pedidos',
                              ))) && (
                              <Menu.Item icon={<CheckCircleOutlined />} key="listOrder">
                                <Link to="/admin/vendas/pedidos">Pedidos</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.orders.includes(
                                'Orçamentos',
                              ))) && (
                              <Menu.Item icon={<QuestionCircleOutlined />} key="listQuotes">
                                <Link to="/admin/vendas/orcamentos">Orçamentos</Link>
                              </Menu.Item>
                            )}
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.orders.includes('Carrinhos'))) && (
                              <Menu.Item icon={<ShoppingCartOutlined />} key="listCart">
                                <Link to="/admin/vendas/carrinhos">Carrinhos em Aberto</Link>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      )}
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.products.length > 0)) && (
                        <SubMenu
                          key="marketingMenu"
                          icon={<NotificationOutlined />}
                          title="Marketing"
                        >
                          {(logged === 'seller'
                            || (sellerData.permissions
                              && sellerData.permissions.products.includes(
                                'Campanhas',
                              ))) && (
                              <Menu.Item icon={<GiftOutlined />} key="listCampaign">
                                <Link to="/admin/marketing/campanhas">Campanhas</Link>
                              </Menu.Item>
                            )}
                        </SubMenu>
                      )}
                    {(logged === 'seller'
                      || (sellerData.permissions
                        && sellerData.permissions.rules.length > 0)) && (
                        <SubMenu
                          key="configurations"
                          icon={<ControlOutlined />}
                          title="Configurações"
                        >
                          <Menu.Item
                            icon={<PercentageOutlined />}
                            key="priceList"
                          >
                            <Link to="/admin/configuracoes/lista-de-precos/lista">Listas de Preços</Link>
                          </Menu.Item>
                          <Menu.Item
                            icon={<DollarCircleOutlined />}
                            key="listPayment"
                          >
                            <Link to="/admin/pagamento/lista">Formas de Pagamento</Link>
                          </Menu.Item>
                          <Menu.Item icon={<CarOutlined />} key="listShipping">
                            <Link to="/admin/entrega/lista">Formas de Entrega</Link>
                          </Menu.Item>
                          <Menu.Item icon={<SlidersOutlined />} key="moreOptions">
                            <Link to="/admin/configuracoes/mais-opcoes">Mais Opções</Link>
                          </Menu.Item>
                        </SubMenu>
                      )}
                  </Menu>
                </Sider>
                <Content style={{ margin: '6px 6px 0' }}>
                  <div
                    style={{ padding: 12, backgroundColor: token.colorBgBase }}
                  >
                    {alertVisible == true && (
                      <Alert
                        message={
                          !screens.lg ? (
                            <Marquee pauseOnHover gradient={false}>
                              {alertMessage.alert_message}
                              &nbsp;
                            </Marquee>
                          ) : (
                            alertMessage.alert_message
                          )
                        }
                        type={alertMessage.alert_type}
                        banner
                        action={
                          alertMessage.button_text && <Space>
                            <Button
                              ghost
                              onClick={() => navigate('/admin/configuracoes')}
                              size="small"
                              type="primary"
                              key="sellerSubscription"
                            >
                              {alertMessage.button_text}
                            </Button>
                          </Space>
                        }
                      />
                    )}
                    {logged === 'seller'
                      || (sellerData.permissions
                        && (sellerData.permissions.customers.length > 0
                          || sellerData.permissions.orders.length > 0
                          || sellerData.permissions.products.length > 0
                          || sellerData.permissions.rules.length > 0)) ? (
                      <HelmetProvider>
                        <Row justify="center">
                          <Routes>
                            <Route path="/" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Painel de Controle`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerStats />
                                </Col>
                              </>
                            } />
                            <Route path="/configuracoes/*" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Minha Conta`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerConfiguration />
                                </Col>
                              </>
                            } />
                            <Route path="/configuracoes/mais-opcoes" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Mais Opções`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerOptions />
                                </Col>
                              </>
                            } />
                            <Route path="/configuracoes/lista-de-precos/lista" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Listas de Preços`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListPriceList />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Clientes`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCustomer />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/solicitacoes" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Solicitações de acesso`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCustomerAccess />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/solicitacoes/:requestId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Solicitações de acesso`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCustomer />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/cadastro/:buyerId?" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Cliente`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCustomer />
                                </Col>
                              </>
                            } />
                            <Route path="/clientes/tags" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Tag de Clientes`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCustomerTag />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Produtos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListProduct />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/categorias" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Categorias de Produtos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCategory />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/tags" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Tag de Produtos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListProductTag />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/cadastro" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Produto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProduct />
                                </Col>
                              </>
                            } />
                            <Route path="/produtos/cadastro/:productId?" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Produto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProduct />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/pedidos" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Pedidos Realizados`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrder />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/orcamentos" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Orçamentos`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrder />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/carrinhos" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Carrinhos em Aberto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCart />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Métodos de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListPayment />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/cadastro" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Método de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePayment />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/cadastro/:paymentId?" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Cadastro de Método de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePayment />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/lista" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Métodos de Entrega`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListShipping />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Método de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShipping />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/cadastro/:shippingId" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Método de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShipping />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-produto" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Produto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListProductRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-produto/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Produto`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProductRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-produto/cadastro/:productRuleId" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Produto`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateProductRule />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/regra" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Entrega`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListShippingRule />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/regra/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShippingRule />
                                </Col>
                              </>
                            } />
                            <Route path="/entrega/regra/cadastro/:shippingRuleId?" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Entrega`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateShippingRule />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/regras" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Pagamento`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListPaymentRule />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/regras/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Pagamento`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePaymentRule />
                                </Col>
                              </>
                            } />
                            <Route path="/pagamento/regras/cadastro/:paymentRuleId?" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Pagamento`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreatePaymentRule />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/pedidos/:orderId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Pedido Realizado`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrderDetails />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/orcamentos/:orderId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Pedido Realizado`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListOrderDetails />
                                </Col>
                              </>
                            } />
                            <Route path="/vendas/carrinhos/:cartId" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Carrinho em Aberto`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCartDetails />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-carrinho" element={
                              <>
                                <Helmet>
                                  <title>{`${titlePrefix}Regras de Carrinho`}</title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCartRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-carrinho/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Carrinho`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCartRule />
                                </Col>
                              </>
                            } />
                            <Route path="/catalogo/regras-carrinho/cadastro/:cartRuleId?" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de Regras de Carrinho`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCartRule />
                                </Col>
                              </>
                            } />
                            <Route path="/marketing/campanhas" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Campanhas`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerListCampaign />
                                </Col>
                              </>
                            } />
                            <Route path="/marketing/campanhas/cadastro" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de campanhas`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCampaign />
                                </Col>
                              </>
                            } />
                            <Route path="/marketing/campanhas/cadastro/:campaignId?" element={
                              <>
                                <Helmet>
                                  <title>
                                    {`${titlePrefix}Cadastro de campanhas`}
                                  </title>
                                </Helmet>
                                <Col span={24}>
                                  <SellerCreateCampaign />
                                </Col>
                              </>
                            } />
                          </Routes>
                        </Row>
                      </HelmetProvider>
                    ) : (
                      sellerData.permissions
                      && (window.location = `/`)
                    )}
                    {changePassDrawer === true && (
                      <SellerChangePass
                        drawer={changePassDrawer}
                        drawerSetter={setChangePassDrawer}
                      />
                    )}
                  </div>
                </Content>
              </>
            )}
        </Layout>
        <PagesFooter />
      </Layout>
    </>
  );
}
