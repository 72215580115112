import React from 'react';
import { useSelector } from 'react-redux';
import { Badge, Grid } from 'antd';
import styles from './cart_button.module.css';

const { useBreakpoint } = Grid;

export default function CartButton(props) {
  const screens = useBreakpoint();
  const cartProducts = useSelector((state) => state.cart.products);

  return (
    <>
      <div style={{ bottom: screens.md ? 64 : 128 }} onClick={props.handleToggle} className={styles['dd-m-whatsapp']}>
        <Badge showZero overflowCount={10000} color="rgb(255, 0, 0)" style={{ position: 'absolute' }} count={cartProducts.length} offset={[65, -40]} />
        <span className={styles.icon} />
      </div>
    </>
  );
}
