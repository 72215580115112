import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Alert, Button, Typography, Drawer, Row, Col, Grid
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SaveOutlined, TruckOutlined, SkinOutlined, CloseOutlined } from '@ant-design/icons';
import Marquee from 'react-fast-marquee';
import { axiosClient } from '../../apiClient';
import { gold } from '@ant-design/colors';


const { Link } = Typography;
const { useBreakpoint } = Grid;

export default function OrderEditMessage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const location = useLocation();
  const orderRebuy = useSelector((state) => state.orderRebuy);
  const cart = useSelector((state) => state.cart);
  const sellerData = useSelector((state) => state.sellerData);

  const [orderTypeTitle, setOrderTypeTitle] = useState('');
  const [orderTypePath, setOrderTypePath] = useState('');

  useEffect(() => { setOrderTypePath(orderRebuy.orderType === 'quote' ? 'orcamentos' : 'pedidos'); setOrderTypeTitle(orderRebuy.orderType === 'quote' ? 'Orçamento' : 'Pedido'); }, [orderRebuy.orderType]);

  const isCheckout = location.pathname.includes('checkout'); // Verifica se 'checkout' está na URL

  const getButtonSize = () => {
    if (screens.xs) return "small"; // Extra small
    if (screens.sm) return "small"; // Small
    return "default"; // Caso nenhum breakpoint seja correspondido
  };

  return (
    <>
      <div
        style={{
          left: '0px',
          top: '0px',
          height: '50px',
          backgroundColor: 'white',
          padding: '5px',
          position: 'fixed',
          width: '100%',
          zIndex: 10,
        }}
      >
        <Alert
          style={{
            height: '100%',
            padding: '5px',
            paddingLeft: '10px',
          }}
          message={
            (
              <Marquee speed={40} gradient={false}>
                Editando&nbsp;{orderTypeTitle}&nbsp;Nº&nbsp;
                <Link style={{ maxWidth: 500 }} ellipsis>
                  {orderRebuy.orderRebuyId}
                </Link>
                &nbsp;
                {orderRebuy.buyerName && (
                  <>
                    {' '}
                    - Cliente: &nbsp;
                    <Link style={{ maxWidth: 500 }} ellipsis>
                      {orderRebuy.buyerName}
                    </Link>
                  </>
                )}
                &nbsp;
              </Marquee>
            )
          }
          type="warning"
          showIcon
        // action={(
        //   <Button type="dashed" onClick={() => setVisibleDrawer(true)} style={{ fontSize: '14px' }}>
        //     Opções
        //   </Button>
        // )}
        />
      </div>
      <Drawer
        mask={false}
        placement="bottom"
        height={60}
        closable={false}
        styles={{
          header: { display: 'none' },
          body: { backgroundColor: gold[1], overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }
        }}
        open
      >
        <Row justify="center" gutter={[8, 8]} align="middle" style={{ width: '100%' }}>
          <Col span={8}>
            <Button
              size={getButtonSize()}
              color="danger"
              variant="outlined"
              icon={<CloseOutlined />}
              style={{ width: '100%' }}
              onClick={() => dispatch({ type: 'RESET_REBUY' }) && navigate(`/admin/vendas/${orderTypePath}/${orderRebuy.orderRebuyId}`)}
            >
              {screens.md ? 'Descartar Alterações' : 'Descartar'}
            </Button>
          </Col>
          {isCheckout && (
            <Col span={8}>
              <Button
                size={getButtonSize()}
                color="primary"
                variant="outlined"
                icon={<SkinOutlined />}
                style={{ width: '100%' }}
                onClick={() => { navigate(-1); }}
              >
                {screens.md ? 'Retornar aos produtos' : 'Voltar'}
              </Button>
            </Col>
          )}
          {!isCheckout && (
            <>
              <Col span={8}>
                <Button
                  size={getButtonSize()}
                  color="primary"
                  variant="outlined"
                  icon={<SaveOutlined />}
                  style={{ width: '100%' }}
                  onClick={() => {
                    axiosClient
                      .post('/cart/order/update', { orderId: orderRebuy.orderRebuyId, cart }, { withCredentials: true })
                      .then((response) => response.data.status === 'Success' && navigate(`/admin/vendas/${orderTypePath}/${orderRebuy.orderRebuyId}`));
                  }}
                >
                  {screens.md ? 'Salvar e Sair' : 'Salvar'}
                </Button>
              </Col>
              <Col span={8}>
                <Button
                  size={getButtonSize()}
                  color="primary"
                  variant="solid"
                  icon={<TruckOutlined />}
                  style={{ width: '100%' }}
                  onClick={() => {
                    axiosClient
                      .post('/cart/order/update', { orderId: orderRebuy.orderRebuyId, cart }, { withCredentials: true })
                      .then((response) => response.data.status === 'Success' && navigate(`/checkout/${orderRebuy.orderRebuyId}`));
                  }}
                >
                  {screens.md ? 'Pagamento e Entrega' : 'Checkout'}
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Drawer>
    </>
  );
}
