import React, { useState, useEffect, useRef } from 'react';
import { App, Skeleton, Modal, Alert, Table, Form, Select, Button, Card, Input, Space, Typography, TreeSelect } from 'antd';

import { axiosClient } from '../../apiClient';
import { useNavigate } from 'react-router-dom';
import { HighlightOutlined, CheckOutlined } from '@ant-design/icons';
import ComponentBuyerCondition from '../ComponentBuyerCondition'
import ComponentProductCondition from '../ComponentProductCondition'
import { formatBuyer } from '../../functions/validation';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const { Link } = Typography;

export default function SellerHideProducts() {
  const { message } = App.useApp();
  const inputRef = useRef();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rules, setRules] = useState([]);
  const [selectedRecordId, setSelectedRecordId] = useState(null);


  useEffect(() => {
    updateRules()
  }, []);

  const columns = [
    {
      title: 'Nome da Regra',
      ellipsis: true,
      responsive: ['sm'],
      render: (record) => (
        <Link>
          {' '}
          {record.name}
          {' '}
        </Link>
      ),
    },
    {
      title: 'A quem se aplica',
      ellipsis: true,
      responsive: ['sm'],
      render: (record) => (
        <Space direction="vertical">
          {record.all_buyers && 'Todos Clientes'}
          {record.buyers_tags.length > 0 && !record.all_buyers && 'Por Tags'}
          {record.buyers.length > 0
            && !record.all_buyers
            && 'Clientes Específicos'}
          {(record?.ceps_data && (record?.ceps_data.ceps_full.length > 0
            || record?.ceps_data.ceps_range.length > 0
            || record?.ceps_data.ceps_start.length > 0)
            && !record.all_buyers)
            && 'Por CEP'}
        </Space>
      )
    },
    {
      title: 'Produtos',
      ellipsis: true,
      responsive: ['sm'],
      render: (record) => (
        <Space split="e">
          {record.all_products && 'Todos Produtos'}
          {record.products_tags?.length > 0
            && !record.all_products
            && 'Por Tags'}
          {record.products?.length > 0
            && !record.all_products
            && 'Produtos Específicos'}
          {record.products_categories?.length > 0
            && !record.all_products
            && 'Por Categorias'}
        </Space>
      )
    },
    {
      title: 'Regras',
      render: (record) => (
        <>
          <Link>
            {' '}
            {record.name}
            {' '}
          </Link>
          <br />
          <b>Clientes:</b>
          {' '}
          <Space split="e">
            {record.all_buyers && 'Todos Clientes'}
            {record.buyers_tags.length > 0 && !record.all_buyers && 'Por Tags'}
            {record.buyers.length > 0
              && !record.all_buyers
              && 'Clientes Específicos'}
            {(record?.ceps_data.ceps_full.length > 0
              || record?.ceps_data.ceps_range.length > 0
              || record?.ceps_data.ceps_start.length > 0)
              && !record.all_buyers
              && 'Por CEP'}
          </Space>
          <br />
          <b>Produtos:</b>
          {' '}
          <Space split="e">
            {record.all_products && 'Todos Produtos'}
            {record.products_tags.length > 0
              && !record.all_products
              && 'Por Tags'}
            {record.products.length > 0
              && !record.all_products
              && 'Produtos Específicos'}
            {record.products_categories_ids.length > 0
              && !record.all_products
              && 'Por Categorias'}
          </Space>
          <br />
        </>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  const handleDelete = () => {
    if (!selectedRecordId) return;
    axiosClient
      .post('/rules/delete', { ruleId: selectedRecordId }, { withCredentials: true })
      .then(() => {
        message.success('Configuração excluída com sucesso', 4)
        setModalOpen(false);
        setSelectedRecordId(null);
        updateRules()
      })
  };

  const handleSave = (values) => {
    const request = selectedRecordId
      ? axiosClient.post('/rules/update', { action: 'hideProducts', ruleId: selectedRecordId, ...values }, { withCredentials: true })
      : axiosClient.post('/rules/create', { action: 'hideProducts', ...values }, { withCredentials: true });

    request
      .then(() => {
        message.success('Operação realizada com sucesso', 4)
        setModalOpen(false);
        form.resetFields();
        setSelectedRecordId(null);
        updateRules()
      })
  };

  const updateRules = () => {
    setLoading(true)
    axiosClient
      .post('/rules/list', { actions: ['hideProducts'] }, { withCredentials: true })
      .then((response) => {
        setLoading(false)
        setRules(response.data.rules);
      })
  }

  return (
    (
      <Space style={{ width: '100%' }} direction='vertical'>
        <Alert
          message="Crie regras para ocultar produtos de clientes específicos, personalizando a visibilidade do catálogo."
          type="info"
          showIcon
        />

        <Card title='Regras para Ocultar Produtos' size='small'><Space style={{ width: '100%' }} direction='vertical'>

          <Form.Item
            name="progressiveTable"
          >
            <Space direction="vertical" style={{ width: '100%' }}>

              {rules?.length > 0 && <Table
                size='small'
                style={{ cursor: !loading ? 'pointer' : 'auto' }}
                columns={columns}
                dataSource={rules}
                pagination={false}
                onRow={(record, rowIndex) => ({
                  onClick: (event) => {
                    setSelectedRecordId(record.id);
                    setModalOpen(true);


                    let ceps_data = rule.ceps_data;
                    let ceps_start = '';
                    if (ceps_data && ceps_data.ceps_start.length > 0) {
                      ceps_start = `${ceps_data.ceps_start.join('*\r\n')}*`;
                    }
                    let ceps_full = '';
                    if (ceps_data && ceps_data.ceps_full.length > 0) {
                      ceps_full = ceps_data.ceps_full.join('\r\n');
                    }
                    let ceps_range = '';
                    if (ceps_data && ceps_data.ceps_range.length > 0) {
                      ceps_range = ceps_data.ceps_range
                        .map((item) => item.join('...'))
                        .join('\r\n');
                    }
                    ceps_data = [ceps_full, ceps_start, ceps_range]
                      .filter(Boolean)
                      .join('\r\n')

                    form.setFieldsValue({
                      name: record.name,
                      ['buyerForm']: {
                        'condition': record.buyer_condition,
                        'specific': record.buyers?.map((buyer) => ({ value: buyer.id, label: formatBuyer(buyer) })),
                        'tags': record.buyers_tags?.map((tag) => ({ value: tag.id, label: tag.title })),
                        'ceps': ceps_data
                      },
                      ['productForm']: {
                        'condition': record.product_condition,
                        'specific': record.products?.map((product) => ({ value: product.id, label: product.product_name })),
                        'tags': record.products_tags?.map((tag) => ({ value: tag.id, label: tag.title })),
                        'categories': record.products_categories?.map((category) => ({ value: category.id, label: category.title })),
                      },
                    })
                  },
                })}
              />}
              <Button type='dashed' onClick={() => {
                setSelectedRecordId(null);
                form.resetFields();
                setModalOpen(true)
              }} block>
                Criar Regra para Ocultar Produto
              </Button>
            </Space>
          </Form.Item>
        </Space>
        </Card >
        <Modal
          open={modalOpen}
          destroyOnClose
          width='90%'
          centered
          title={selectedRecordId ? 'Editar Ocultar Produtos' : 'Cadastrar Ocultar Produtos'}
          okText={selectedRecordId ? 'Salvar' : 'Cadastrar'}
          okButtonProps={{
            autoFocus: true,
            htmlType: 'submit',
          }}
          cancelText="Cancelar"
          onCancel={() => {
            form.resetFields()
            setModalOpen(false);
          }}
          footer={
            <>
              <Button onClick={() => setModalOpen(false)}>Cancelar</Button>
              {selectedRecordId && (
                <Button danger onClick={handleDelete}>
                  Excluir
                </Button>
              )}
              <Button type="primary" onClick={() => form.submit()}>
                {selectedRecordId ? 'Salvar' : 'Cadastrar'}
              </Button>
            </>
          }
          modalRender={(dom) => (
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSave}
            >
              {dom}
            </Form>
          )}
        >
          <Space direction="vertical" size="small" style={{ width: '100%' }}>
            {/* Nome da Regra */}
            <Card size='small'>
              <Form.Item
                name="name"
                layout='horizontal'
                label="Nome da Regra"
                rules={[{ required: true, message: 'Por favor, insira o nome da regra.' }]}
              >
                <Input placeholder="Ex.: Esconder revistas de farmácias" />
              </Form.Item>
            </Card>
            <Card size='small' title="Configurar Clientes" style={{ marginBottom: 16 }}>
              <ComponentBuyerCondition
                form={form}
                label="Escolha os clientes para aplicar a regra"
                showAllOption={false}
              />
            </Card>

            <Card size='small' title="Configurar Produtos">
              <ComponentProductCondition
                form={form}
                label="Escolha os produtos que devem ser ocultados"
                showAllOption={false}
              />
            </Card>
          </Space>
        </Modal >
      </Space >)
  );
}
