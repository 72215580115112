function initialState() {
  // const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isDark = false
  return {
    isDark,
    colorPrimary: isDark ? '#1677ff' : '#1677ff',
    colorTextBase: isDark ? '#ffffff' : '#000000',
    colorBgBase: isDark ? '#141414' : '#ffffff',
    colorInfo: isDark ? '#1677ff' : '#1677ff',
    salved: false,
  };
}

export function themeConfig(state = initialState(), action) {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        ...(action.isDark !== undefined && { isDark: action.isDark }),
        ...(action.colorPrimary !== undefined && { colorPrimary: action.colorPrimary }),
        ...(action.colorTextBase !== undefined && { colorTextBase: action.colorTextBase }),
        ...(action.colorBgBase !== undefined && { colorBgBase: action.colorBgBase }),
        ...(action.colorInfo !== undefined && { colorInfo: action.colorInfo }),
        ...(action.salved !== undefined && { salved: action.salved }),
      };

    default:
      return state;
  }
}
