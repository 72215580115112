function initialState() {
  return {
    page: 1,
    perPage: 24,
    totalPages: 1,
    column: null,
    order: null,
    pageUpdated: false,
    updating: false,
  };
}

export function buyerPagination(state = initialState(), action) {
  switch (action.type) {
    case 'SET_BUYER_PAGINATION':
      return {
        ...state,
        ...('totalPages' in action && { totalPages: action.totalPages }),
        ...('page' in action && { page: action.page }),
        ...('perPage' in action && { perPage: action.perPage }),
        ...('pageUpdated' in action && { pageUpdated: action.pageUpdated }),
        ...('column' in action && { column: action.column }),
        ...('order' in action && { order: action.order }),
      };
    default:
      return state;
  }
}
