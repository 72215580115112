import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Menu,
  Dropdown,
  Skeleton,
  Col,
  Divider,
  Tabs,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';
import ComponentBuyerCondition from '../ComponentBuyerCondition'
import { formatBuyer } from '../../functions/validation';


const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

export default function SellerCreateShipping() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(false);
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);

  const { shippingId } = useParams();

  useEffect(() => {
    axiosClient
      .post(
        '/shipping/available',
        { shippingId: false },
        { withCredentials: true },
      )
      .then((response) => {
        setShippingOptions(response.data.metodos);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (shippingId) {
      axiosClient
        .post(
          '/shipping/get',
          { shippingId: shippingId },
          { withCredentials: true },
        )
        .then((response) => {
          const shippingData = response.data.shippings[0]

          let ceps_data = shippingData.ceps_data;
          let ceps_start = '';
          if (ceps_data && ceps_data.ceps_start.length > 0) {
            ceps_start = `${ceps_data.ceps_start.join('*\r\n')}*`;
          }
          let ceps_full = '';
          if (ceps_data && ceps_data.ceps_full.length > 0) {
            ceps_full = ceps_data.ceps_full.join('\r\n');
          }
          let ceps_range = '';
          if (ceps_data && ceps_data.ceps_range.length > 0) {
            ceps_range = ceps_data.ceps_range
              .map((item) => item.join('...'))
              .join('\r\n');
          }
          ceps_data = [ceps_full, ceps_start, ceps_range]
            .filter(Boolean)
            .join('\r\n')

          form.setFieldsValue({
            ...shippingData,
            ['buyerForm']: {
              'condition': shippingData.buyer_condition,
              'specific': shippingData.buyers?.map((buyer) => ({ value: buyer.id, label: formatBuyer(buyer) })),
              'tags': shippingData.buyers_tags?.map((tag) => ({ value: tag.id, label: tag.title })),
              'ceps': ceps_data
            }
          });
          setSelectedOption(response.data.shippings[0].metodo);
        });
    }
  }, []);


  function deleteMethod() {
    setDeleting(true);
    axiosClient
      .post(
        '/shipping/delete',
        { shippingId: shippingId },
        { withCredentials: true },
      )
      .then((response) => {
        setDeleting(false);
        navigate('/admin/entrega/lista');
      });
  }

  function handleChange(value) {
    setSelectedOption(value);
  }

  const onFinish = (values) => {
    setSending(true);
    const data = shippingId
      ? { ...values, shippingId: shippingId }
      : values;
    if (data.valor) data.valor = parseInt(data.valor.replace(/\D/g, '')) / 100;
    axiosClient
      .post('/shipping/create', data, { withCredentials: true })
      .then((response) => navigate('/admin/entrega/lista'));
  };

  const menu = (
    <Menu onClick={() => deleteMethod()}>
      <Menu.Item key="1">Excluir Método</Menu.Item>
    </Menu>
  );

  function updatePriceField(field, val) {
    form.setFields([
      {
        name: field,
        value: isNaN(val)
          ? 'R$ 0,00'
          : val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      },
    ]);
  }

  return (
    <>
      <PageHeader
        title={shippingId ? 'Editar Método' : 'Novo Método'}
        onBack={() => navigate('/admin/entrega/lista')}
        extra={
          shippingId && (
            <Dropdown overlay={menu} trigger='click'>
              <Button icon={<DownOutlined />} loading={deleting} type="primary">Ações</Button>
            </Dropdown>
          )
        }
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          layout='vertical'
          scrollToFirstError
          onFinish={onFinish}
          initialValues={{
            emQuemAplica: 'allBuyers',
          }}
        >
          <Tabs defaultActiveKey="1" onChange={() => { }}>
            <TabPane tab="Dados Gerais" key="1">
              <Form.Item
                name={['metodo']}
                label="Método"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    max: 100,
                  },
                ]}
              >
                <Select
                  onChange={handleChange}
                >
                  {shippingOptions.map((item, i) => (
                    <Option value={item.codigo} key={item.codigo}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedOption === 'personalizado' && (
                <>
                  <Form.Item
                    name={['nome']}
                    label="Descrição"
                    rules={[
                      {
                        type: 'string',
                        required: true,
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['valor']}
                    label="Valor"
                    onChange={(e) => {
                      const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                      updatePriceField('valor', val);
                    }}
                    rules={[
                      {
                        type: 'string',
                        required: true,
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
              <Divider orientation="left">Instruções</Divider>
              <Form.Item name="instructions">
                <TextArea rows={4} maxLength={1000} />
              </Form.Item>
            </TabPane>
            <TabPane forceRender tab="Restrição por Cliente" key="2">
              <ComponentBuyerCondition
                form={form}
                defaultOption='all'
                label="Selecione os clientes para os quais essa condição estará disponível"
              />
            </TabPane>
          </Tabs>
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    onClick={() => {
                      navigate('/admin/entrega/lista');
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    loading={sending}
                  >
                    {shippingId ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
